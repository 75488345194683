import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import dayjs from 'dayjs'
import { FaBitbucket } from 'react-icons/fa'
import { HiCog, HiRefresh } from 'react-icons/hi'

import { Form, Input, Submit } from 'tui'
import Placeholder from 'Components/Placeholder'
import { getLabel } from 'utils/label'
import { usePrice } from 'ECommerce/hooks/usePrice'
import { usePaginate } from 'backend/usePaginate'

// import Badge from "tui/Components/Badge"

const endpoint = 'order/my-orders'

const Orders = () => {
  const [filtered, setFiltered] = useState(false)
  const { price } = usePrice()
  const { hasMore, items, loadMore, filter, loading } = usePaginate(endpoint, {
    limit: 100,
  })

  const onSubmit = (data) => {
    filter(data).then(() => setFiltered(true))
    console.log('data', data)
  }

  const clearFilter = () => {
    filter({}).then(() => setFiltered(false))
  }

  return (
    <div>
      <h1 className="text-3xl mb-4">Payments</h1>
      <Form onFinish={onSubmit}>
        <div className="flex justify-between items-start">
          <div className="flex space-x-4">
            <div className="">
              <Input.Text name="id" placeholder="Search by Order Id" autoComplete="off" />
            </div>
          </div>
          <div className="flex items-center">
            <Submit className="bg-primary flex items-center p-2 px-4 mr-2">
              <HiCog className="mr-2" /> Apply Filter
            </Submit>
            {filtered && (
              <button
                type="reset"
                className="flex items-center bg-input p-2 px-4"
                onClick={clearFilter}
              >
                <HiRefresh className="mr-2" />
                Clear Filter
              </button>
            )}
          </div>
        </div>
      </Form>
      <div className="mt-2">
        {/* <h2 className="text-2xl font-semibold text-gold-400">Company</h2> */}
        <div>
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={
              loading && (
                <h4 style={{ textAlign: 'center' }} key="loader">
                  Loading...
                </h4>
              )
            }
          >
            <>
              {loading === false && items?.length === 0 ? (
                <Placeholder
                  icon={FaBitbucket}
                  text={
                    filtered ? 'No result found' : 'Payments will display here'
                  }
                />
              ) : (
                items.map((item) => (
                  <Fragment key={item.id}>
                    <div className="bg-black my-4 rounded-md p-2">
                      <div className="font-semibold m-2">
                        <div className="flex justify-between space-x-4">
                          <div className="w-2/4">
                            <Link
                              className="mb-2 text-lg"
                              to={`/orders/${item.id}`}
                              state={item}
                            >
                              Order {item.id}
                            </Link>
                            <p className="mb-1 text-muted">{item.title}</p>
                            <p className=" text-sm text-gold-400">
                              Ordered on{' '}
                              {dayjs(item.createdAt).format('MMM DD, YYYY')}
                            </p>
                          </div>
                          <div className="text-right">
                            <p className="text-lg text-muted mb-2">
                              {price(item.amount, item.currency)} —{' '}
                              {getLabel(item.status)}
                            </p>
                            <p className="text-sm text-gold-400 mb-2">
                              {item.gateway}
                            </p>
                            {/* <p className="text-lg text-muted mb-2">{price(item.amount, item.currency)}</p> */}
                            {/* <Link to="/">View Details</Link> */}
                            {/* <Badge status={item.status}>{item.status}</Badge> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))
              )}
            </>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  )
}

export default Orders
