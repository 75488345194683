import React, { useEffect, useState } from 'react'
import { FaDownload, FaFileInvoiceDollar } from 'react-icons/fa'
import Placeholder from 'Components/Placeholder'
import { useCertificate } from 'backend/useCertificate'
import { LoaderBlock } from 'utils/loader'
import { paymentStatus } from 'dataSource/constants'
import { useParams } from "react-router-dom"
import { useBusiness } from "backend/useBusiness"

const Certificates = () => {
  const { id } = useParams()
  const [formData, setFormData] = useState({})

  const { getBusiness } = useBusiness()

  useEffect(() => {
    getBusiness(id).then((item) => setFormData(item))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const { certificates, getCertificateUrl } = useCertificate()

  return (
    <div>
      <div>
        <h2 className="text-2xl mb-5">Download Certificates</h2>
      </div>
      <div className="bg-black p-4 rounded-lg">
        {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
        {formData?.status !== paymentStatus.Confirmed ? (
          <div className="text-center">
            <Placeholder
              icon={FaFileInvoiceDollar}
              text="Please complete the payment to download certificates"
            />
          </div>
        ) : certificates?.status === 'loading' ? (
          <LoaderBlock />
        ) : certificates?.items?.length === 0 ? (
          <Placeholder
            icon={FaDownload}
            text="Your certificates will display here"
          />
        ) : (
          <div>
            {certificates.items
              .filter(
                (item) =>
                  item.category.includes(formData.shareholderType) &&
                  item.certificateType === formData.businessType &&
                  item.entityEnding === 'ALL'
              )
              .map((item, i) => (
                <div key={i}>
                  {item.shareholderCount === 0 ? (
                    <>
                      {item.status === 'Active' ? (
                        <a
                          href={getCertificateUrl(formData.id, item.id)}
                          className="secondary w-full my-1 block"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.title}
                        </a>
                      ) : (
                        <span>{item.title}</span>
                      )}
                    </>
                  ) : (
                    formData.shareholderCount === item.shareholderCount && (
                      <>
                        {item.status === 'Active' ? (
                          <a
                            href={getCertificateUrl(formData.id, item.id)}
                            className="secondary w-full my-1 block"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.title}
                          </a>
                        ) : (
                          <span>{item.title}</span>
                        )}
                      </>
                    )
                  )}
                </div>
              ))}
            {certificates.items
              .filter(
                (item) =>
                  item.category.includes(formData.shareholderType) &&
                  item.certificateType === formData.businessType &&
                  item.entityEnding === formData.entityEnding
              )
              .map((item, i) => (
                <div key={i}>
                  {item.shareholderCount === 0 ? (
                    <>
                      {item.status === 'Active' ? (
                        <a
                          href={getCertificateUrl(formData.id, item.id)}
                          className="secondary w-full my-1 block"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.title}
                        </a>
                      ) : (
                        <span>{item.title}</span>
                      )}
                    </>
                  ) : (
                    formData.shareholderCount === item.shareholderCount && (
                      <>
                        {item.status === 'Active' ? (
                          <a
                            href={getCertificateUrl(formData.id, item.id)}
                            className="secondary w-full my-1 block"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.title}
                          </a>
                        ) : (
                          <span>{item.title}</span>
                        )}
                      </>
                    )
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Certificates
