import { useBackend } from 'backend/useBackend'
import { useDispatch, useSelector } from 'react-redux'
import { eCommerceActions, selectECommerce } from 'features/eCommerceSlice'

const useSquareUp = () => {
  // https://developer.squareup.com/blog/online-payments-with-square-and-react/
  const { backend } = useBackend()
  const dispatch = useDispatch()
  const { squareClient } = useSelector(selectECommerce)

  const getSquareClient = () => {
    backend()
      .get('square-up/client')
      .then((data) => {
        dispatch(eCommerceActions.loadSquareDetails(data))
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const createPayment = async (cart) => {
    console.log('createPayment', cart)
    try {
      const orderData = await backend().post('square-up/payment', cart)
      console.log('orderData', orderData)
    } catch (error) {
      console.error(error)
      console.error(`Could not initiate SquareUp Checkout...<br><br>${error}`)
    }
  }

  return {
    squareClient,
    getSquareClient,
    createPayment,
  }
}

export default useSquareUp
