import React from 'react'
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'

const ContactUs = () => {
  return (
    <div>
      <h1 className="text-3xl pb-4 md:pb-0">Contact Us</h1>
      <div className="text-center">
        <h3 className="text-2xl">Netherland Office</h3>
      </div>
      <section className="grid md:grid-cols-3 gap-4 mt-4">
        <div className="flex bg-black p-4 rounded-md cursor-pointer items-center">
          <div>
            <FaEnvelope className="text-5xl text-gold-400" />
          </div>
          <div className="flex flex-col items-center mx-auto">
            <p className="ml-4 text-xl font-semibold text-gold-400">Email</p>
            <p className="ml-4 text-center text-muted">
              register@blockchaintrust.pro
            </p>
          </div>
        </div>
        <div className="flex bg-black p-4 rounded-md cursor-pointer items-center">
          <div>
            <FaPhone className="text-5xl text-gold-400 rotate-90" />
          </div>
          <div className="flex flex-col items-center mx-auto">
            <p className="ml-4 text-xl font-semibold text-gold-400">Call</p>
            <p className="ml-4 text-center text-muted">+31-97-0050-33359</p>
          </div>
        </div>
        <div className="flex bg-black p-4 rounded-md cursor-pointer items-center">
          <div>
            <FaMapMarkerAlt className="text-5xl text-gold-400" />
          </div>
          <div className="flex flex-col items-center mx-auto">
            <p className="ml-4 text-xl font-semibold text-gold-400">Address</p>
            <p className="ml-4 text-center text-muted">
              Schenkkade 50 K<br />
              The Hague - 2595 AR,
              <br />
              The Netherlands
            </p>
          </div>
        </div>
      </section>
      {/* <div className="text-center mt-8">
        <h3 className="text-2xl">Spain Office</h3>
      </div>
      <section className="grid md:grid-cols-3 gap-4 mt-4">
        <div className="flex bg-black p-4 rounded-md cursor-pointer items-center">
          <div>
            <FaEnvelope className="text-5xl text-gold-400" />
          </div>
          <div className="flex flex-col items-center mx-auto">
            <p className="ml-4 text-xl font-semibold text-gold-400">Email</p>
            <p className="ml-4 text-center text-muted">
              register@blockchainjurisdiction.com
            </p>
          </div>
        </div>
        <div className="flex bg-black p-4 rounded-md cursor-pointer items-center">
          <div>
            <FaPhone className="text-5xl text-gold-400 rotate-90" />
          </div>
          <div className="flex flex-col items-center mx-auto">
            <p className="ml-4 text-xl font-semibold text-gold-400">Call</p>
            <p className="ml-4 text-center text-muted">+34-91-829-9804 Old Number</p>
            <p className="ml-4 text-center text-muted">+34-91-829-9704</p>
          </div>
        </div>
        <div className="flex bg-black p-4 rounded-md cursor-pointer items-center">
          <div>
            <FaMapMarkerAlt className="text-5xl text-gold-400" />
          </div>
          <div className="flex flex-col items-center mx-auto">
            <p className="ml-4 text-xl font-semibold text-gold-400">Address</p>
            <p className="ml-4 text-center text-muted">
              Paseo de la Castellana 81
              <br />
              28046 Madrid, Spain
            </p>
          </div>
        </div>
      </section> */}
      <div className="text-center mt-8">
        <h3 className="text-2xl">USA Office</h3>
      </div>
      <section className="grid md:grid-cols-3 gap-4 mt-4">
        <div className="flex bg-black p-4 rounded-md cursor-pointer items-center">
          <div>
            <FaEnvelope className="text-5xl text-gold-400" />
          </div>
          <div className="flex flex-col items-center mx-auto">
            <p className="ml-4 text-xl font-semibold text-gold-400">Email</p>
            <p className="ml-4 text-center text-muted">
              executive@blockchaintrust.one
            </p>
          </div>
        </div>
        <div className="flex bg-black p-4 rounded-md cursor-pointer items-center">
          <div>
            <FaPhone className="text-5xl text-gold-400 rotate-90" />
          </div>
          <div className="flex flex-col items-center mx-auto">
            <p className="ml-4 text-xl font-semibold text-gold-400">Call</p>
            <p className="ml-4 text-center text-muted">+1-610-994-3266</p>
          </div>
        </div>
        <div className="flex bg-black p-4 rounded-md cursor-pointer items-center">
          <div>
            <FaMapMarkerAlt className="text-5xl text-gold-400" />
          </div>
          <div className="flex flex-col items-center mx-auto">
            <p className="ml-4 text-xl font-semibold text-gold-400">Address</p>
            <p className="ml-4 text-center text-muted">
              614 N Dupont Hwy - Ste 2100
              <br />
              Dover, DE 19901
            </p>
          </div>
        </div>
      </section>
      <div className="text-center mt-8">
        <h3 className="text-2xl">Canada Office</h3>
      </div>
      <section className="grid md:grid-cols-3 gap-4 mt-4">
        <div className="flex bg-black p-4 rounded-md cursor-pointer items-center">
          <div>
            <FaEnvelope className="text-5xl text-gold-400" />
          </div>
          <div className="flex flex-col items-center mx-auto">
            <p className="ml-4 text-xl font-semibold text-gold-400">Email</p>
            <p className="ml-4 text-center text-muted">
              executive@blockchaintrust.pro
            </p>
          </div>
        </div>
        <div className="flex bg-black p-4 rounded-md cursor-pointer items-center">
          <div>
            <FaPhone className="text-5xl text-gold-400 rotate-90" />
          </div>
          <div className="flex flex-col items-center mx-auto">
            <p className="ml-4 text-xl font-semibold text-gold-400">Call</p>
            {/* <p className="ml-4 text-center text-muted">+1-587-388-1018 Old Number</p> */}
            <p className="ml-4 text-center text-muted">+1-587-430-2692</p>
          </div>
        </div>
        <div className="flex bg-black p-4 rounded-md cursor-pointer items-center">
          <div>
            <FaMapMarkerAlt className="text-5xl text-gold-400" />
          </div>
          <div className="flex flex-col items-center mx-auto">
            <p className="ml-4 text-xl font-semibold text-gold-400">Address</p>
            <p className="ml-4 text-center text-muted">
              Bankers Hall, 888 3rd Street
              <br />
              Calgary, AB T2P, 5C5, Canada
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactUs
