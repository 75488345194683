import { createSlice } from "@reduxjs/toolkit";
import { cleanObject } from "utils/object"

const initialState = {
  prevPath: null,
  hasMore: false,
  skip: 0,
  limit: 0,
  total: 0,
  count: 0,
  items: [],
  lastItem: null,
  loading: false,
  loadingError: null,
  // loadingMore: false,
  loadingMoreError: null,
  endpoint: null,
  needRefresh: new Date().valueOf(),
  filters: {}, // only use for ui control
  filterBy: {},
};
const uniqueItems = (items) => {
  var lookup = [];
  var result = [];

  for (const item of items) {
    var id = item?.id;

    if (!id) {
      return;
    }
    if (!lookup.includes(id)) {
      lookup.push(id);
      result.push(item);
    }
  }
  return result;
};
export const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    pagination: (state, action) => {
      state.prevPath = action.payload.prevPath;
      state.endpoint = action.payload.endpoint;
      state.limit = action.payload.limit;
      state.skip = action.payload.skip;
      state.loading = true;
    },
    loading: (state) => {
      state.loading = true;
      // state.loadingMore = true;
    },
    loaded: (state, action) => {
      const items = action.payload.items;
      state.items = items;
      // state.endpoint = action.payload.endpoint;
      // state.limit = action.payload.limit;
      state.loading = false;
      state.loadingError = null;
      state.lastItem = state.items.lastItem;
      state.filterBy = action.payload?.filterBy ?? {};
      state.skip = state.items.length;
      state.count = state.items.length;
      state.hasMore = state.items.length < action.payload.total;
      // state.hasMore = end;
      state.total = action.payload.total;
    },
    moreLoaded: (state, action) => {
      const items = state.items.concat(action.payload.items);
      state.items = uniqueItems(items);
      state.loading = false;
      state.loadingError = null;
      // state.loadingMore = false;
      state.lastItem = action.payload.items.lastItem;
      state.skip = state.items.length;
      state.hasMore = state.items.length < state.total;
      state.count = state.items.length;
    },
    deleteById: (state, { payload }) => {
      const found = state.items.find((cur) => cur.id === payload.id);
      if (found?.id) {
        state.total = state.total - 1;
        state.count = state.count - 1;
        state.items = state.items.filter((cur) => cur.id !== payload.id);
      }
    },
    updateById: (state, action) => {
      state.items = state.items.map((cur) => {
        if (cur.id === action.payload.id) {
          return { ...cur, ...action.payload.item };
        }
        return cur;
      });
    },
    refresh: (state) => {
      state.skip = 0;
      state.total = 0;
      state.count = 0;
      state.needRefresh = new Date().valueOf();
      state.hasMore = false;
      state.loading = true;
      state.lastItem = null;
      state.items = [];
    },
    clear: (state) => {
      state.prevPath = null;
      state.hasMore = false;
      state.skip = 0;
      state.limit = 0;
      state.total = 0;
      state.count = 0;
      state.items = [];
      state.lastItem = null;
      state.loading = false;
      state.loadingError = null;
      state.loadingMore = false;
      state.loadingMoreError = null;
      state.endpoint = null;
      // state.filters = {};
    },
    failed: (state) => {
      state.loading = false;
    },
    setFilter: (state, { payload }) => {
      state.filters = cleanObject({
        ...state.filters,
        ...payload,
      });
    },
    clearFilter: (state) => {
      state.filters = {};
    },
  },
});

export const { clear, loadMore, loading, loaded, refresh, failed, deleteById } =
  paginationSlice.actions;

export const paginationAction = paginationSlice.actions;
export const selectPagination = (state) => state.pagination;
export default paginationSlice.reducer;
