import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Input, Submit } from 'tui'
import { useAuth } from 'backend/useAuth'

const Login = () => {
  const { removeToken, login } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    removeToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (data) => {
    console.log('data', data)
    login(data.email, data.password, (data.remember = false)).then((user) => {
      if(user){
        console.log('user', user)
      }
      navigate('/')
    })
  }

  return (
    <div className="md:w-1/3 bg-dark-900 mx-auto p-8 flex flex-col justify-center">
      <div className="text-center">
        <h2 className="text-4xl mb-3">Login</h2>
        <p className="text-muted text-sm">Sign In to your account.</p>
      </div>
      <Form onFinish={onSubmit}>
        <div className="">
          <div className="mb-2">
            <Input.Email
              name="email"
              label="Email"
              placeholder="Email"
              rules={{ required: 'Email is required' }}
              autoComplete="username"
              autoFocus
            />
          </div>
          <div className="mb-2">
            <Input.Password
              name="password"
              label="Password"
              placeholder="Password"
              rules={{ required: 'Password is required' }}
              autoComplete="current-password"
            />
          </div>
          <div className="flex justify-between items-center mt-5">
            <Submit className="btn-primary py-2">Login</Submit>
            <Link to="/auth/forgot">Forgot Password?</Link>
          </div>
          <div className="text-center mt-5">
            <p className="pb-1">Haven't an account?</p>
            <Link to="/auth/signup">Create an account</Link>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default Login
