import React from 'react'
import { Link } from 'react-router-dom'
import ConfettiRealistic from './ConfettiRealistic'
// import Fireworks from "./Confetti/Fireworks"

const OrderPlaced = () => {
  return (
    <div className="text-center">
      {/* <Fireworks durationUntilStop={2000} /> */}
      <ConfettiRealistic />
      <h2 className="text-5xl my-4">Congratulations</h2>
      <p className="text-2xl my-8">Your order Placed</p>
      <Link to="/" className="btn btn-primary inline-block">
        Go Back to Dashboard
      </Link>
    </div>
  )
}

export default OrderPlaced
