import React from 'react'
import { FaPlusCircle } from 'react-icons/fa'

const ShareholderSelect = ({ selectType = () => {} }) => {
  return (
    <div>
      <div>
        <h2 className="text-2xl mb-5">Shareholder Details</h2>
      </div>
      <div className="grid grid-cols-2 gap-5">
        <div className="bg-black text-sm text-muted p-4 rounded-lg">
          <div className="flex justify-center items-center p-10">
            <button
              className="m-0 p-0 text-sm text-gold-400"
              onClick={() => selectType()}
            >
              <FaPlusCircle className="text-3xl inline-block mb-1" />
              <div>Select Shareholder Type</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShareholderSelect
