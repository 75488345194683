import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { LoaderSuspense } from 'utils/loader'
import { selectAuth } from 'features/authSlice'
import { status } from 'features/status'

export default function AuthGuard({ children }) {
  const auth = useSelector(selectAuth)

  // return <Outlet />
  if (auth.logged === true && auth.status === status.fulfilled) {
    return children
  } else if (auth.status === status.loading) {
    return <LoaderSuspense />
  } else {
    return <Navigate to="/auth/login" />
  }
}
