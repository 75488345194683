import { createSlice } from "@reduxjs/toolkit";
import { status } from "./status";

const initialState = {
  certificates: {
    status: status.loading,
    items: [],
  },
};

export const certificateSlice = createSlice({
  name: "certificates",
  initialState,
  reducers: {
    loaded(state, action) {
      state.certificates = {
        items: action.payload.items || [],
        status: status.idle,
      };
    },
  },
});

export const certificateActions = certificateSlice.actions;
export const selectCertificates = (state) => state.certificates
export default certificateSlice.reducer;
