import { useDispatch, useSelector } from 'react-redux'
import {
  selectBeneficiary,
  beneficiaryActions,
} from 'features/beneficiarySlice'
import { useBackend } from './useBackend'
import { status } from 'features/status'

export function useBeneficiary() {
  const { beneficiary, beneficiaryOptions } =
    useSelector(selectBeneficiary) || {}
  const dispatch = useDispatch()
  const { backend } = useBackend()

  const createBeneficiary = (payload, config) =>
    new Promise((resolve) => {
      backend()
        .post('/beneficiary-business', payload, config)
        .then(({ item }) => {
          dispatch(
            beneficiaryActions.add({
              item: item,
              status: status.idle,
            }),
          )
          return resolve(item.id)
        })
    })

  const myBeneficiary = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: false,
        msg: false,
      }
      // const query = new URLSearchParams(payload).toString()
      const query = new URLSearchParams({ ...payload }).toString()
      dispatch(beneficiaryActions.loading())
      backend()
        .get(`/beneficiary-business/my-beneficiaries?${query}`, config)
        .then((data) => {
          dispatch(
            beneficiaryActions.loaded({
              items: data.items,
              status: status.idle,
            }),
          )
          return resolve(data)
        })
        .catch((err) => {
          console.log(err)
          dispatch(beneficiaryActions.error())
        })
    })

  const getBeneficiaryOptionsByType = (type) => {
    return beneficiary.items
      .filter((item) => item.type === type)
      .map((item) => {
        return {
          label: `${item.firstName} ${item.lastName} ${item.businessName} - ${item.email}`,
          value: item.id,
        }
      })
  }

  const onSelectBeneficiary = (id) => {
    return beneficiary.items.filter((item) => item.id === id)
  }

  return {
    beneficiary,
    beneficiaryOptions,
    createBeneficiary,
    getBeneficiaryOptionsByType,
    onSelectBeneficiary,
    myBeneficiary,
  }
}
