import { createSlice } from '@reduxjs/toolkit'
import { status } from './status'

const initialState = {
  user: {
    displayName: 'Loading...',
  },
  roles: ['user'],
  status: status.loading,
  adminUser: {},
  token: '',
  logged: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, { payload }) {
      state.user = payload.user
      state.token = payload.token
      state.roles = payload.roles
      state.logged = true
      state.status = status.fulfilled
    },
    setToken(state, { payload }) {
      state.token = payload.token
    },
    setAdmin(state, { payload }) {
      state.adminUser = payload.adminUser
    },
    updateProfile(state, { payload }) {
      state.user = {
        ...state.user,
        ...payload.updated,
      }
    },
    logout() {
      return { ...initialState }
    },
  },
})

export const authActions = authSlice.actions
export const selectAuth = (state) => state.auth
export default authSlice.reducer
