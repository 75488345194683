import { configureStore } from '@reduxjs/toolkit'

import { authSlice } from './authSlice'
import { cmsSlice } from './cmsSlice'
import { businessSlice } from './businessSlice'
import { ordersSlice } from './ordersSlice'
import { eCommerceSlice } from './eCommerceSlice'
import { certificateSlice } from './certificateSlice'
import { paginationSlice } from './pagination/paginationSlice'
import { shareholderSlice } from './shareholderSlice'
import { assetSlice } from './assetSlice'
import { beneficiarySlice } from './beneficiarySlice'
import { uiSlice } from "./uiSlice"

export const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [cmsSlice.name]: cmsSlice.reducer,
    [businessSlice.name]: businessSlice.reducer,
    [shareholderSlice.name]: shareholderSlice.reducer,
    [eCommerceSlice.name]: eCommerceSlice.reducer,
    [ordersSlice.name]: ordersSlice.reducer,
    [certificateSlice.name]: certificateSlice.reducer,
    [paginationSlice.name]: paginationSlice.reducer,
    [assetSlice.name]: assetSlice.reducer,
    [beneficiarySlice.name]: beneficiarySlice.reducer,
    [uiSlice.name]: uiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  // devTools: true,
  // devTools: false
})

const dispatch = store.dispatch

export { dispatch }

export default store
