// import { lazy } from 'react';

// project imports
import AuthGuard from './AuthGuard'
import SingleLayout from 'Layouts/SingleLayout'
import CertificateOfGoodStanding from 'Pages/GoodStanding'
// import GoodStandingPaymentSelection from 'Pages/GoodStanding/PaymentSelection'

// ==============================|| PAGES ROUTING ||============================== //

const GoodStandingsRoutes = {
  path: '/good-standing',
  element: (
    <AuthGuard>
      <SingleLayout />
    </AuthGuard>
  ),
  children: [
    {
      index: true,
      element: <CertificateOfGoodStanding />,
    },
    {
      path: ':companyId',
      element: <CertificateOfGoodStanding />,
    },
    // {
    //   path: 'payment/:companyId',
    //   element: <GoodStandingPaymentSelection />,
    // },
  ],
}
export default GoodStandingsRoutes
