import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useAuth } from 'backend/useAuth'
import { Form, Input, Submit } from 'tui'

const Reset = () => {
  const { resetPassword } = useAuth()
  const { code } = useParams()
  const navigate = useNavigate()
  const [password, setPassword] = useState('')

  const onSubmit = (data) => {
    console.log('data', data)
    resetPassword(data.password, code).then(() => {
      navigate('/auth/login')
    })
  }

  return (
    <div className="md:w-1/3 bg-dark-900 mx-auto p-8 flex flex-col justify-center">
      <div className="text-center">
        <h2 className="text-4xl mb-3">Reset Password</h2>
        {/* <p className="text-muted text-sm">Create your new account.</p> */}
      </div>
      <Form onFinish={onSubmit}>
        <div className="mb-4">
          <Input.Password
            name="password"
            label="Password"
            placeholder="Password"
            rules={{
              required: 'Password is required',
              onChange: (e) => setPassword(e.target.value),
            }}
          />
        </div>
        <div className="mb-4">
          <Input.Password
            name="password-conf"
            label="Confirm Password"
            placeholder="Confirm Password"
            rules={{
              required: 'Confirm Password is required',
              validate: (value) =>
                value === password ||
                'Password and Confirm password does not matched.',
            }}
          />
        </div>
        <div className="flex justify-between items-center mt-5">
          <Submit className="btn-primary py-2">Reset</Submit>
          <span>
            Back to <Link to="/auth/login">Login</Link>
          </span>
        </div>
      </Form>
    </div>
  )
}

export default Reset
