import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useShareholder } from 'backend/useShareholder'
import IndividualShareholder from "../Individual"
import CorporateShareholder from "../Corporate"
import FamilyShareholder from "../Family"

const StepShareEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [formData, setFormData] = useState({})
  const { updateShareholder, getShareholder } = useShareholder()

  useEffect(() => {
    getShareholder(id).then((item) => setFormData(item))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const onSubmit = (data) => {
    console.log(data)
    updateShareholder({ id: id, ...data }).then((id) => {
      navigate('/shareholders')
    })
  }

  switch (formData.type) {
    case 'Individual':
      return <IndividualShareholder data={formData} onSubmit={onSubmit} action="edit" />
    case 'Corporate':
      return <CorporateShareholder data={formData} onSubmit={onSubmit}  action="edit" />
    case 'Family':
      return <FamilyShareholder data={formData} onSubmit={onSubmit} action="edit" />
    default:
      return(<></>)
      // return <>No shareholder type selected</>
  }
}

export default StepShareEdit
