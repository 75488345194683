import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Form, Select, Submit } from 'tui'
import ReusableModal from 'Components/ReusableModal'
import { useBusiness } from 'backend/useBusiness'
import { shareholderTypes } from 'dataSource/options'
import { uiActions } from 'features/uiSlice'
import { businessTypes } from 'dataSource/constants'

const ShareholderModalSelect = ({ business, id }) => {
  const dispatch = useDispatch()
  const [selectedShareholderType, setSelectedShareholderType] = useState('')
  const { updateBusinessDetails } = useBusiness()

  const handleClose = () => {
    dispatch(uiActions.closeReUsableModal())
  }

  const onSubmit = (data) => {
    console.log(data)
    let payload = {
      id: id,
      shareholderType: data.shareholderType,
      shareholderCount: data?.shareholderCount || 1,
    }

    updateBusinessDetails(payload).then(() => {
      handleClose()
    })
  }

  const getBeneficiariesOptions = () => {
    let count = 0
    switch (business?.businessType) {
      case businessTypes.trust:
        count = 2
        break
      default:
        count = 2
    }

    return Array(count)
      .fill(1)
      .map((a, i) => ({ value: i + 1, label: String(i + 1) }))
  }

  return (
    <ReusableModal title="Select Shareholder Type">
      {/* <pre>{JSON.stringify(shareholder, null, 2)}</pre> */}
      <Form onFinish={onSubmit} initialValues={business}>
        <div className="grid grid-cols-2 gap-5 mt-8">
          <div className="w-full col-span-2">
            <Select
              required
              name="shareholderType"
              label="Shareholder Type"
              placeholder="Select Type"
              options={shareholderTypes}
              rules={{
                required: 'Shareholder Type is required',
              }}
              onSelect={(value) => setSelectedShareholderType(value)}
            />
          </div>
          {selectedShareholderType === 'Individual' && (
            <div className="w-full col-span-2">
              <Select
                required
                name="shareholderCount"
                label="Number of Beneficiaries"
                placeholder="Select Number of Beneficiaries"
                options={getBeneficiariesOptions()}
                rules={{
                  required: 'Number of Beneficiaries is required',
                }}
              />
            </div>
          )}
        </div>
        <div className="flex justify-end mt-5">
          {/* <button
            type="button"
            className="mr-2 btn btn-secondary"
            onClick={handleClose}
            ref={cancelButtonRef}
          >
            Close
          </button> */}
          <Submit className="btn btn-primary">Submit</Submit>
        </div>
      </Form>
    </ReusableModal>
  )
}

export default ShareholderModalSelect
