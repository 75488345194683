import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { config } from '../config'
const { name, description, baseUrl, walletConnectProjectId } = config

const projectId = walletConnectProjectId
const domain = baseUrl

const metadata = {
  name: name,
  description: description,
  url: domain, // origin must match your domain & subdomain
  icons: [],
}

const chains = [
  {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://cloudflare-eth.com',
  },
  {
    chainId: 137,
    name: 'Polygon',
    currency: 'MATIC',
    explorerUrl: 'https://polygonscan.com',
    rpcUrl: 'https://polygon-rpc.com',
  },
  {
    chainId: 56,
    name: 'BNB Smart Chain Mainnet',
    currency: 'BNB',
    explorerUrl: 'https://bscscan.com/',
    rpcUrl: 'https://bscrpc.com	',
  },
  {
    chainId: 43114,
    name: 'Avalanche C-Chain',
    currency: 'AVAX',
    explorerUrl: 'https://subnets.avax.network/',
    rpcUrl: 'https://avalanche.public-rpc.com/	',
  },
  {
    chainId: 250,
    name: 'Fantom Opera',
    currency: 'FTM',
    explorerUrl: 'https://rpc.ftm.tools',
    rpcUrl: 'https://avalanche.public-rpc.com/	',
  },
  {
    chainId: 80002,
    name: 'Amoy',
    currency: 'MATIC',
    explorerUrl: 'https://amoy.polygonscan.com/',
    rpcUrl: 'https://polygon-amoy-bor-rpc.publicnode.com/',
  },
]

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains,
  projectId,
})

export function Web3ModalProvider({ children }) {
  return children
}
