const isNumber = (value) => !Number.isNaN(Number(value))

const usePrice = (defaultCurrency = 'USD') => {
  const price = (price, currency = defaultCurrency, options) => {
    // let data = _.pick(options, "currency", "signDisplay", "maximumFractionDigits", "locales")
    // data = { currency: currency.iso, signDisplay: 'auto', maximumFractionDigits: 2, locales: 'en-Us', ...data }
    var locales = navigator.language || navigator.userLanguage || 'en-US'
    let data = {
      currency: currency,
      signDisplay: 'auto',
      maximumFractionDigits: 2,
      locales,
      ...options,
    }
    const formattedPrice = isNumber(price) ? parseFloat(price) : 0
    return new Intl.NumberFormat(data.locales, {
      style: 'currency',
      // currencyDisplay: 'name',
      // minimumSignificantDigits: 4,
      // maximumSignificantDigits: 4,
      // minimumFractionDigits: 5,
      signDisplay: data.signDisplay,
      maximumFractionDigits: data.maximumFractionDigits,
      currency: data.currency.toUpperCase(), // 'INR'
    }).format(formattedPrice)
  }

  return {
    price,
  }
}

export { usePrice }
