import adminLogo from 'assets/payment-admin.jpg'
// import payPalLogo from 'assets/payment-paypal.jpg'
import coinbaseLogo from 'assets/payment-coinbase.jpg'
// import squareLogo from 'assets/payment-square.jpg'
import stripeLogo from 'assets/payment-stripe.png'

export const paymentGateways = [
  {
    label: 'Free Order',
    id: 'FreeOrder',
    logo: adminLogo,
    description: 'Free order or Pay with coupon amount',
    role: 'user',
    free: true,
  },
  {
    label: 'Admin Payment',
    id: 'AdminPayment',
    logo: adminLogo,
    description: 'Pay with Admin Payment',
    role: 'adminPayment',
    free: false,
  },
  // {
  //   label: 'Paypal',
  //   id: 'Paypal',
  //   logo: payPalLogo,
  //   description: 'Pay with Credit/Debit Card',
  //   role: 'user',
  //   free: false
  // },
  {
    label: 'Stripe',
    id: 'Stripe',
    logo: stripeLogo,
    description: 'Pay with Credit/Debit Card',
    role: 'user',
    free: false,
  },
  // {
  //   label: 'Square Payment',
  //   id: 'Square',
  //   logo: squareLogo,
  //   description: 'Pay with Credit/Debit Card, Google Pay',
  //   role: 'user',
  //   free: false
  // },
  {
    label: 'Coinbase',
    id: 'Coinbase',
    logo: coinbaseLogo,
    description: 'Pay with Crypto Currencies, Like BTC ETH',
    role: 'user',
    free: false,
  },
  // {
  //   label: 'OneRamper',
  //   id: 'OneRamper',
  //   logo: '',
  //   description: 'Pay with Crypto Currencies, Like BTC ETH',
  // },
  // {
  //   label: 'ForumPay',
  //   id: 'ForumPay',
  //   logo: '',
  //   description: 'Pay with Crypto Currencies, Like BTC ETH',
  // },
]
