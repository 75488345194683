import { createSlice } from '@reduxjs/toolkit'
import { status } from './status'

const initialCheckout = {
  subTotal: 0,
  total: 0,
}

const initialCoupon = {
  title: '',
  code: '',
  id: '',
  discountAmount: 0,
  applied: false,
}

const initialState = {
  coupon: initialCoupon,
  checkout: initialCheckout,
  product: {},
  products: {
    status: status.loading,
    items: [],
  },
  paymentMethod: '',
  squareClient: {
    applicationId: '',
    locationId: '',
  },
}

export const eCommerceSlice = createSlice({
  name: 'eCommerce',
  initialState,
  reducers: {
    loaded(state, action) {
      state.products = {
        items: action.payload.items || [],
        status: status.idle,
      }
    },
    selectPaymentMethod(state, { payload }) {
      if (state.coupon.payableAmount === 0) {
        state.paymentMethod = 'FreeOrder'
      } else {
        state.paymentMethod = payload.paymentMethod
      }
      // if (payload.paymentMethod === 'AdminPayment') {
      //   state.checkout.total = 0
      //   // } else {
      //   //   if (state.product.id) {
      //   //     state.checkout.total = state.product.payableAmount
      //   //   }
      // }
    },
    clearPaymentMethod(state) {
      state.paymentMethod = ''
    },
    selectProduct(state, { payload }) {
      if (state.products?.items.length > 0) {
        const product = state.products?.items.find((p) => p.sku === payload.sku)
        state.product = product
        state.checkout.total = product.payableAmount
        state.checkout.subTotal = product.payableAmount
        state.coupon = initialCoupon
        if (product.payableAmount === 0) {
          state.paymentMethod = 'FreeOrder'
        }
      }
    },
    couponApply(state, { payload }) {
      if (state.product.id) {
        state.checkout = {
          total: payload.coupon.payableAmount,
          subTotal: payload.coupon.originalAmount,
        }
        state.coupon = {
          id: payload.coupon.id,
          applied: payload.coupon.applied,
          title: payload.coupon.title,
          code: payload.coupon.code,
          discountAmount: payload.coupon.discountAmount,
        }
        if (payload.coupon.payableAmount === 0) {
          state.paymentMethod = 'FreeOrder'
          // } else {
          //   state.paymentMethod = ''
        }
      }
    },
    couponClear(state) {
      if (state.product.id) {
        const product = state.product

        state.checkout = {
          total: product.payableAmount,
          subTotal: product.payableAmount,
        }
        state.coupon = initialCoupon
        state.paymentMethod = ''
      }
    },
    loadSquareDetails(state, { payload }) {
      state.squareClient = {
        applicationId: payload.applicationId,
        locationId: payload.locationId,
      }
    },
    clear() {
      return { ...initialState }
    },
  },
})

export const eCommerceActions = eCommerceSlice.actions
export const selectECommerce = (state) => state.eCommerce
export default eCommerceSlice.reducer
