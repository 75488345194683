import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { businessActions, selectBusiness } from 'features/businessSlice'
import { useBackend } from './useBackend'
import { status } from 'features/status'
import { registeredAs } from 'config'

export function useBusiness() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { backend } = useBackend()
  const { business } = useSelector(selectBusiness)

  /**
   * Call only first time of registration process.
   * It will create a new business or update.
   * @param {*} payload
   * @returns
   */
  const verifyOrCreateBusiness = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Checking...', success: 'Business name available.' },
      }
      backend()
        .post('/business', payload, config)
        .then(({ item }) => {
          if (payload?.id) {
            dispatch(
              businessActions.update({
                item: item,
                status: status.idle,
              })
            )
          } else {
            dispatch(
              businessActions.add({
                item: item,
                status: status.idle,
              })
            )
          }
          return resolve(item.id)
        })
    })

  const updateBusinessDetails = (
    payload,
    msg = { loading: 'Updating...', success: 'Updated.' },
    loader = true
  ) =>
    new Promise((resolve) => {
      let config = { loader, msg }
      const { id, ...body } = payload
      backend()
        .put(`/business/${id}`, body, config)
        .then(({ item }) => {
          dispatch(
            businessActions.update({
              item: item,
              status: status.idle,
            })
          )
          return resolve(item.id)
        })
    })

  // const payment = (payload) =>
  //   new Promise((resolve) => {
  //     let config = {
  //       loader: true,
  //       msg: false,
  //       // loader: true, msg: { loading: "Requesting...", success: "Payment Success." }
  //     };
  //     backend()
  //       .post(`/payment`, payload, config)
  //       .then(({ data }) => {
  //         dispatch(
  //           businessActions.update({
  //             item: data,
  //             status: "idle",
  //           })
  //         );
  //         return resolve(data);
  //       });
  //   });

  // const payment = (id) =>
  //   new Promise((resolve) => {
  //     let config = {
  //       loader: true,
  //       msg: { loading: "Requesting...", success: "Payment Success." },
  //     };
  //     backend()
  //       .put(`/business/${id}/payment`, {}, config)
  //       .then(({ business }) => {
  //         dispatch(
  //           businessActions.update({
  //             item: business,
  //             status: "idle",
  //           })
  //         );
  //         return resolve(business.id);
  //       });
  //   });

  const setBusiness = (ids) => {
    dispatch(businessActions.loadIds({ ids }))
  }

  const updateBusiness = (item) => {
    dispatch(
      businessActions.update({
        item: item,
        status: status.idle,
      })
    )
  }

  const searchBusiness = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: false,
      }
      // const query = new URLSearchParams(payload).toString()
      const query = new URLSearchParams({ ...payload, registeredAs }).toString()
      backend()
        .get(`/business/search?${query}`, config)
        .then((data) => {
          return resolve(data)
        })
        .catch((err) => {
          console.log(err)
        })
    })

  const myBusiness = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: false,
        msg: false,
      }
      // const query = new URLSearchParams(payload).toString()
      const query = new URLSearchParams({ ...payload, registeredAs }).toString()
      dispatch(businessActions.loading())
      backend()
        .get(`/business/my-business?${query}`, config)
        .then((data) => {
          dispatch(
            businessActions.loaded({
              items: data.items,
              status: status.idle,
            })
          )
          return resolve(data)
        })
        .catch((err) => {
          console.log(err)
          dispatch(businessActions.error())
        })
    })

  // const myAddress = (payload) =>
  //   new Promise((resolve) => {
  //     let config = {
  //       loader: false,
  //       msg: false,
  //     }
  //     const query = new URLSearchParams(payload).toString()
  //     dispatch(addressActions.loading())
  //     backend()
  //       .get(`/business/my-address?${query}`, config)
  //       .then((data) => {
  //         dispatch(
  //           addressActions.load({
  //             items: data.items,
  //             status: 'idle',
  //           })
  //         )
  //         return resolve(data)
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //         dispatch(addressActions.error())
  //       })
  //   })

  const updateBusinessAddress = (
    payload,
    msg = { loading: 'Updating...', success: 'Updated.' },
    loader = true
  ) =>
    new Promise((resolve) => {
      let config = { loader, msg }
      const { ids, ...body } = payload
      if (ids.length === 1) {
        backend()
          .put(`/business/address/${ids[0].id}`, body, config)
          .then(({ item }) => {
            dispatch(
              businessActions.update({
                item: item,
                status: status.idle,
              })
            )
            return resolve(item.id)
          })
      } else {
        backend()
          .put(`/business/addresses`, payload, config)
          .then(() => {
            // dispatch(
            //   businessActions.update({
            //     item: item,
            //     status: idle,
            //   })
            // );
            return resolve(ids)
          })
      }
    })

  const getBusinesses = () => {
    return business
  }

  const getBusiness = (id, redirect = true) =>
    new Promise((resolve) => {
      let config = { loader: true, msg: null }
      if (location?.state && location?.state?.id === id) {
        return resolve(location.state)
      }
      const selectedBusiness = business.items.find((item) => item.id === id)

      if (selectedBusiness?.id) {
        return resolve(selectedBusiness)
      } else {
        backend()
          .get(`/business/${id}`, config)
          .then(({ item }) => {
            // dispatch(businessActions.current({ item: item }))
            dispatch(businessActions.append({ item: item }))
            return resolve(item)
          })
          .catch((error) => {
            if (redirect) {
              navigate(`/`)
            }
          })
      }
    })

  const getBusinessWithAddress = (id) =>
    new Promise((resolve) => {
      let config = { loader: true, msg: null }
      backend()
        .get(`/business/${id}`, config)
        .then(({ item }) => {
          // dispatch(businessActions.current({ item: item }))
          dispatch(businessActions.update({ item: item, status: status.idle }))
          return resolve(item)
        })
    })

  // const selectBusiness = (id, redirect = true) => {
  //   const selectedBusiness = business.items.find((item) => item.id === id)

  //   if (selectedBusiness) {
  //     return selectedBusiness
  //   }
  //   // if business not found redirect to home.
  //   if (redirect) {
  //     navigate(`/`)
  //   }
  // }

  return {
    business,
    setBusiness,
    updateBusinessDetails,
    verifyOrCreateBusiness,
    myBusiness,
    // myAddress,
    searchBusiness,
    // payment,
    // selectBusiness,
    getBusinesses,
    getBusiness,
    updateBusiness,
    updateBusinessAddress,
    getBusinessWithAddress,
  }
}
