import { useDispatch, useSelector } from 'react-redux'
import { assetActions, selectUserAssets } from 'features/assetSlice'
import { useBackend } from './useBackend'
import { status } from 'features/status'

export function useAsset() {
  const dispatch = useDispatch()
  const { backend } = useBackend()
  const { asset } = useSelector(selectUserAssets) || {}

  /**
   * Call only first time of registration process.
   * It will create a new business or update.
   * @param {*} payload
   * @returns
   */
  const getUserAssets = (userId) =>
    new Promise((resolve) => {
      let config = { loader: true, msg: null }
      backend()
        .get(`/assets/${userId}`, config)
        .then(({ item }) => {
          dispatch(assetActions.update({ item: item, status: status.idle }))
          return resolve(item)
        })
    })

  const getSpecificAsset = (id) =>
    new Promise((resolve) => {
      let config = { loader: true, msg: null }
      backend()
        .get(`/assets/${id}`, config)
        .then(({ item }) => {
          return resolve(item)
        })
    })

  const userAsset = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: false,
        msg: false,
      }
      dispatch(assetActions.loading())
      backend()
        .get(`/assets/user/asset-details`, config)
        .then((data) => {
          dispatch(
            assetActions.setAsset({
              items: data.items,
              status: status.idle,
            }),
          )
          return resolve(data)
        })
        .catch(() => {
          dispatch(assetActions.error())
        })
    })

  const getAssets = () => {
    return asset
  }

  return {
    asset,
    getUserAssets,
    getAssets,
    getSpecificAsset,
    userAsset,
  }
}