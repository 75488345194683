import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from 'backend/useAuth'
import { Form, Input, Submit } from 'tui'

const UpdatePassword = () => {
  const { updatePassword } = useAuth()
  const navigate = useNavigate()
  const [password, setPassword] = useState('')

  const onSubmit = (data) => {
    updatePassword({
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    }).then(() => {
      navigate('/')
    })
  }

  return (
    <div className="md:w-1/3 bg-dark-900 mx-auto p-8 flex flex-col justify-center">
      <div className="text-center">
        <h2 className="text-4xl mb-3">Change Password</h2>
        {/* <p className="text-muted text-sm">Create your new account.</p> */}
      </div>
      <Form onFinish={onSubmit}>
        <div className="mb-4">
          <Input.Password
            name="oldPassword"
            label="Old Password"
            placeholder="Old Password"
            rules={{
              required: 'Old Password is required',
              onChange: (e) => setPassword(e.target.value),
            }}
          />
        </div>
        <div className="mb-4">
          <Input.Password
            name="newPassword"
            label="New Password"
            placeholder="New Password"
            rules={{
              required: 'New Password is required',
              onChange: (e) => setPassword(e.target.value),
            }}
          />
        </div>
        <div className="mb-4">
          <Input.Password
            name="conFirmPassword"
            label="Confirm Password"
            placeholder="Confirm Password"
            rules={{
              required: 'Confirm Password is required',
              validate: (value) =>
                value === password ||
                'Password and Confirm password does not matched.',
            }}
          />
        </div>
        <div className="flex justify-between items-center mt-5">
          <Submit className="btn-primary py-2">Update Password</Submit>
          <span>
            Back to <Link to="/">Dashboard</Link>
          </span>
        </div>
      </Form>
    </div>
  )
}

export default UpdatePassword
