import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  CreditCard,
  // ApplePay,
  // GooglePay,
  PaymentForm,
} from 'react-square-web-payments-sdk'
import { usePrice } from 'ECommerce/hooks/usePrice'
import { useOrder } from 'backend/useOrder'
import { selectOrders } from 'features/ordersSlice'
import { status } from 'features/status'
import { LoaderTextBlock, showLoader } from 'utils/loader'
import useSquareUp from './useSquareUp'
import { selectECommerce } from 'features/eCommerceSlice'

// http://localhost:3000/payment/square/clnagsthl000ddkbc8pgqw11x
const SquareUp = () => {
  const navigate = useNavigate()
  const { orderId } = useParams()
  const { getOrderById } = useOrder()
  const { price } = usePrice()
  const { currentOrder } = useSelector(selectOrders)
  const { getSquareClient, createPayment } = useSquareUp()
  const { squareClient } = useSelector(selectECommerce)

  useEffect(() => {
    if (currentOrder?.item?.id !== orderId) {
      getOrderById(orderId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!squareClient.applicationId) {
      getSquareClient()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squareClient.applicationId])

  const createOrder = async (token, buyer) => {
    showLoader(true)
    return await createPayment({
      currency: 'USD',
      orderId: currentOrder?.item?.id,
      items: [
        {
          id: currentOrder?.item?.id,
          amount: currentOrder?.item?.amount,
          description: currentOrder?.item?.items?.[0].description,
          name: currentOrder?.item?.items?.[0].title,
          quantity: 1,
        },
      ],
      tokenId: token.token,
      token,
      buyer,
    }).then(() => {
      showLoader(false)
      navigate('/payment/done/' + currentOrder?.item?.id)
    })
  }

  // const onApprove = async (data) => {
  //   captureOrderApi(data).then((res) => {
  //     showLoader(false)
  //     console.log('onApprove', res)
  //     navigate('/payment/done/' + currentOrder?.item?.id)
  //     // navigate('/')
  //     // alert('Transaction completed by'+ data.payer.name.given_name)
  //   })
  // }

  // const onCancel = async (data) => {
  //   console.log('onCancel', data)
  //   showLoader(false)
  //   navigate('/')
  // }

  return (
    <div className="md:w-1/3 bg-dark-900 mx-auto p-8 flex flex-col justify-center">
      <div className="text-center">
        {currentOrder?.status === status.loading ? (
          <LoaderTextBlock />
        ) : (
          <div className="mx-auto">
            <h2 className="text-4xl mb-3">Square Payment</h2>
            <p className="text-muted text-sm">Pay with Square Payment</p>
            <p className="my-6 text-xl">
              Pay <b>{price(currentOrder?.item?.amount)}</b> with square
            </p>
            {!squareClient.applicationId ? (
              <LoaderTextBlock text="Loading Square Payment" />
            ) : (
              <PaymentForm
                /**
                 * Identifies the calling form with a verified application ID generated from
                 * the Square Application Dashboard.
                 */
                applicationId={squareClient.applicationId}
                /**
                 * Identifies the location of the merchant that is taking the payment.
                 * Obtained from the Square Application Dashboard - Locations tab.
                 */
                locationId={squareClient.locationId}
                /**
                 * Invoked when payment form receives the result of a tokenize generation
                 * request. The result will be a valid credit card or wallet token, or an error.
                 */
                cardTokenizeResponseReceived={(token, buyer) => {
                  createOrder(token, buyer)
                  console.info({ token, buyer })
                }}
                /**
                 * This function enable the Strong Customer Authentication (SCA) flow
                 *
                 * We strongly recommend use this function to verify the buyer and reduce
                 * the chance of fraudulent transactions.
                 */
                // createVerificationDetails={() => ({
                //   amount: '1.00',
                //   /* collected from the buyer */
                //   billingContact: {
                //     addressLines: ['123 Main Street', 'Apartment 1'],
                //     familyName: 'Doe',
                //     givenName: 'John',
                //     countryCode: 'GB',
                //     city: 'London',
                //   },
                //   currencyCode: 'GBP',
                //   intent: 'CHARGE',
                // })}
                // createPaymentRequest={() => ({
                //   countryCode: 'US',
                //   currencyCode: currentOrder?.item?.currency || 'USD',
                //   total: {
                //     amount: currentOrder?.item?.amount * 100,
                //     label: 'Total',
                //   },
                // })}
              >
                {/* <ApplePay /> */}
                {/* <GooglePay /> */}
                <br />
                <CreditCard
                  buttonProps={{
                    css: {
                      backgroundColor: '#AE8241',
                      fontSize: '14px',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#836231',
                      },
                      '&:focus': {
                        border: '2px solid',
                        borderColor: '#DBC29C',
                      },
                    },
                  }}
                />
              </PaymentForm>
            )}
          </div>
        )}
      </div>
      {/* <pre>{JSON.stringify(currentOrder, null, 2)}</pre> */}
    </div>
  )
}

export default SquareUp
