// import { lazy } from 'react';

// project imports
import PageLayout from 'Layouts/PageLayout'
import Page404 from 'Pages/404'

// const Page404 = lazy(() => import('Pages/404'));

// ==============================|| PAGES ROUTING ||============================== //

const PageRoutes = {
  path: '/',
  element: <PageLayout />,
  children: [
    {
      path: '*',
      element: <Page404 />
    }
  ]
};

export default PageRoutes;
