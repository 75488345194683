import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useShareholder } from 'backend/useShareholder'
import IndividualShareholder from '../Individual'
import CorporateShareholder from '../Corporate'
import FamilyShareholder from '../Family'

const StepShareAdd = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({ type: 'select' })
  const { createShareholder } = useShareholder()

  const onSubmit = (data) => {
    console.log(data)
    let config = {
      loader: true,
      msg: { loading: 'Saving Shareholder...', success: 'Shareholder saved' },
    }

    createShareholder({ ...data, type: formData.type }, config).then((id) => {
      navigate('/shareholders')
    })
  }

  switch (formData.type) {
    case 'Individual':
      return (
        <IndividualShareholder
          data={formData}
          onSubmit={onSubmit}
          action="add"
        />
      )
    case 'Corporate':
      return (
        <CorporateShareholder
          data={formData}
          onSubmit={onSubmit}
          action="add"
        />
      )
    case 'Family':
      return (
        <FamilyShareholder data={formData} onSubmit={onSubmit} action="add" />
      )
    default:
      return (
        <div>
          <div className="text-center mt-5">
            <h2 className="text-2xl mb-3">Select Shareholder Type</h2>
          </div>
          <div className="w-4/12 mx-auto mt-14">
            <div className="flex flex-col gap-5 mt-5">
              <button
                onClick={() => setFormData({ type: 'Individual' })}
                className="btn-primary"
              >
                Individual Shareholder
              </button>
              <button
                onClick={() => setFormData({ type: 'Corporate' })}
                className="btn-primary"
              >
                Corporate Shareholder
              </button>
              {/* <button
                onClick={() => setFormData({ type: 'Family' })}
                className="btn-primary"
              >
                Family Shareholder
              </button> */}
            </div>
          </div>
        </div>
      )
  }
}

export default StepShareAdd
