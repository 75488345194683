// import { lazy } from 'react';

// project imports
import AuthLayout from 'Layouts/AuthLayout'
import Login from 'Pages/Auth/Login'
import Signup from 'Pages/Auth/Signup'
import Forgot from 'Pages/Auth/PassForgot'
import Reset from 'Pages/Auth/PassReset'
import Logout from 'Pages/Auth/Logout'
import VerifyEmail from 'Pages/Auth/VerifyEmail'
import AuthGuard from './AuthGuard'
import LoginAs from 'Pages/Auth/LoginAs'
import AdminLogin from 'Pages/Auth/AdminLogin'

// const Page404 = lazy(() => import('Pages/404'));

// ==============================|| AUTH ROUTING ||============================== //

const AuthRoutes = {
  path: '/',
  children: [
    {
      path: 'auth/logout',
      element: <Logout />,
    },
    {
      path: 'auth/admin-login/:tokenId',
      element: <AdminLogin />,
      children: [
        {
          path: ':customRoute',
          element: <AdminLogin />,
        },
      ],
    },
    {
      path: 'auth',
      element: <AuthLayout />,
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'login-as',
          element: <LoginAs />,
        },
        {
          path: 'signup',
          element: <Signup />,
        },
        {
          path: 'forgot',
          element: <Forgot />,
        },
        {
          path: 'reset/:code',
          element: <Reset />,
        },
      ],
    },
    {
      path: 'verify-email',
      element: <AuthLayout />,
      children: [
        {
          index: true,
          element: (
            <AuthGuard>
              <VerifyEmail />
            </AuthGuard>
          ),
        },
      ],
    },
  ],
}

export default AuthRoutes
