import React from 'react'
import { format } from 'date-fns'
import { FaEdit, FaPlusCircle } from 'react-icons/fa'
import { countryNameByIso2 } from 'dataSource/countries'

const ShareholderIndividual = ({
  shareholders = [],
  shareholderCount,
  edit = () => {},
  add = () => {},
}) => {
  let shareholdersLength = 0
  if (shareholders) {
    shareholdersLength = shareholders?.length
  }

  return (
    <div>
      <div>
        <h2 className="text-2xl mb-5">Individual Shareholder Details</h2>
      </div>
      <div className="grid grid-cols-2 gap-5">
        {shareholdersLength <= shareholderCount &&
          Array(shareholderCount - shareholders?.length)
            .fill(1)
            .map((n, i) => (
              <div
                className="col-auto bg-black text-sm text-muted p-4 rounded-lg"
                key={i}
              >
                <div className="flex justify-center items-center  h-full p-10">
                  <button
                    className="m-0 p-0 text-sm text-gold-400"
                    onClick={() => add(shareholders?.length)}
                  >
                    <FaPlusCircle className="text-3xl inline-block mb-1" />
                    <div>Add Shareholder</div>
                  </button>
                </div>
              </div>
            ))}
        {(shareholders || []).map(
          (item, i) =>
            i + 1 <= shareholderCount && (
              <div
                className="col-auto relative block text-xs bg-black rounded-lg p-4"
                key={i}
              >
                <div className="absolute right-3 top-3">
                  <FaEdit
                    className="text-gold-500 text-lg cursor-pointer"
                    onClick={() => edit(i)}
                  />
                </div>
                <div className="text-lg">
                  <div className="text-gold-400 mb-2">
                    Shareholder {i + 1} Info
                  </div>
                </div>
                <div className="mb-2">
                  <span className="text-muted">Name of Trustee: </span>
                  <b>
                    {item?.firstName} {item?.lastName}
                  </b>
                </div>
                <div className="mb-2">
                  <span className="text-muted">Date of Birth: </span>
                  <b>
                    {item?.dob && format(new Date(item?.dob), 'dd/MM/yyyy')}
                  </b>
                </div>
                <div className="mb-2">
                  <span className="text-muted">Marital Status: </span>
                  <b>{item?.maritalStatus}</b>
                </div>
                <div className="mb-2">
                  <span className="text-muted">Passport Number: </span>
                  <b>{item?.passportNumber}</b>
                </div>
                {/* <div className="mb-2">
                    <span className="text-muted">Street: </span>
                    <b>{shareholder?.street}</b>
                  </div> */}
                <div className="mb-2">
                  <span className="text-muted">City: </span>
                  <b>{item?.city}</b>
                </div>
                <div className="mb-2">
                  <span className="text-muted">State: </span>
                  <b>{item?.state}</b>
                </div>
                <div className="mb-2">
                  <span className="text-muted">Country: </span>
                  <b>{countryNameByIso2(item?.country)}</b>
                </div>
                <div className="mb-2">
                  <span className="text-muted">Zip Code: </span>
                  <b>{item?.zipCode}</b>
                </div>
                <div className="mb-2">
                  <span className="text-muted">Mobile Number: </span>
                  <b>{item?.mobile}</b>
                </div>
                <div className="mb-2">
                  <span className="text-muted">Email: </span>
                  <b>{item?.email}</b>
                </div>
                <div>
                  <span className="text-muted">Share: </span>
                  <b>{item?.share}%</b>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  )
}

export default ShareholderIndividual
