import React, { useEffect, useState } from 'react'
import {
  useLocation,
  useNavigate,
  // useParams,
} from 'react-router-dom'
import { usePrice } from 'ECommerce/hooks/usePrice'
import { camelCaseToSentence } from "utils/label"
const dayjs = require('dayjs')
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const OrderView = () => {
  const navigate = useNavigate()
  const location = useLocation()
  // const { id } = useParams()
  const [orderData, setOrderData] = useState({})
  const { price } = usePrice()

  useEffect(() => {
    setOrderData(location.state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  if (!location.state) {
    navigate('/orders')
  }

  return (
    <div className="flex flex-col justify-center">
      <div className="mb-4">
        <div className="flex justify-between">
          <h1 className="text-3xl mb-2">
            {orderData?.gateway && <span>{camelCaseToSentence(orderData?.gateway)}</span>}
          </h1>
          <div className="text-white">{orderData?.status}</div>
        </div>
        <div className="flex justify-between items-baseline">
          <div className="text-xs text-muted">
            <div>{orderData?.title}</div>
            <div className="text-white my-2">Order Id: <b>{orderData.id}</b></div>
            <small className="text-gold-400">{dayjs(orderData?.createdAt).format('LLL')}</small>
          </div>
          <div className="text-gold-400 font-bold">
            Total: {price(orderData?.amount, orderData?.currency)}
          </div>
        </div>
      </div>
      <div className="flex gap-8 mt-5">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="border px-4 py-2 text-right">#</th>
              <th className="border px-4 py-2 text-left">Order Details</th>
              <th className="border px-4 py-2 text-right">Cost</th>
              {/* <th className="px-4 py-2">Total</th> */}
            </tr>
          </thead>
          <tbody>
            {orderData?.items &&
              orderData?.items.map((item, i) => (
                <tr key={item.id}>
                  <td className="border px-4 py-2 text-right align-top">{i + 1}</td>
                  <td className="border px-4 py-2">
                    <span className="text-gold-400 block">{item.title}</span>
                    <span className="text-muted text-sm block">
                      {item.name}
                    </span>
                    {/* <small>Item Id: {item.id}</small> */}
                    <br />
                    <small>Type: {orderData?.type}</small>
                  </td>
                  <td className="border px-4 py-2 text-right align-top">
                    {price(item.amount, item.currency)}
                  </td>
                  {/* <td className="border px-4 py-2 text-right">$20</td> */}
                </tr>
              ))}
            {orderData?.coupon?.id && (
              <tr key={orderData?.coupon.id}>
                <td className="border px-4 py-2 text-right"></td>
                <td className="border px-4 py-2">
                  <span className="text-gold-400 block">Discount</span>
                  <span className="block">
                    {orderData?.coupon.code}
                  </span>
                  <span className="text-muted text-sm block">
                    {orderData?.coupon.title}
                  </span>
                </td>
                <td className="border px-4 py-2 text-right align-top">
                  {price(
                    -orderData?.coupon.discountAmount,
                    orderData?.coupon.currency,
                  )}
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="2" className="text-right font-bold px-4 py-2">
                Total
              </td>
              <td className="font-extrabold border px-4 py-2 text-right">
                {price(orderData?.amount, orderData?.currency)}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      {/* <pre>{JSON.stringify(location.state, null, 2)}</pre> */}
      <div className="flex justify-between mt-5">
        <button
          type="button"
          className="back"
          tabIndex="-1"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  )
}

export default OrderView
