import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from 'backend/useAuth'
import Loader from 'utils/loader'
import { useDispatch } from 'react-redux'
import { businessActions } from 'features/businessSlice'
import { orderActions } from 'features/ordersSlice'
import { authActions } from 'features/authSlice'
import { toast } from 'react-toastify'

const AdminLogin = () => {
  const { tokenId, customRoute } = useParams()
  const { loginGetToken, autoLogin } = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(authActions.setAdmin({}))
    dispatch(businessActions.clear())
    dispatch(orderActions.clear())
    loginGetToken(tokenId)
      .then((data) => {
        console.log(data)
        autoLogin().then(() => {
          setTimeout(() => {
            let route = '/'
            if (customRoute) route = `/${customRoute}`
            navigate(route)
          }, 1000)
        })
      })
      .catch(() => {
        toast.error('Auth token expired')
        navigate('/auth/login')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Loader />
}

export default AdminLogin
