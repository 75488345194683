import { format } from 'date-fns'

export const formatDOB = (dob) => {
  return format(new Date(dob), 'P')
}

export const updateBeneficiaries = (formData, data, pageNo) => {
  let beneficiaries = []
  if (formData?.beneficiaries) {
    beneficiaries = Array.from(formData?.beneficiaries)
    if (beneficiaries.length === 0) {
      beneficiaries.push(data)
    } else {
      beneficiaries.splice(pageNo, 1, data)
    }
  } else {
    beneficiaries.push(data)
  }
  return beneficiaries
}

/**
 * Checks if a JavaScript value is empty
 * @example
 *    isEmpty(null); // true
 *    isEmpty(undefined); // true
 *    isEmpty(''); // true
 *    isEmpty([]); // true
 *    isEmpty({}); // true
 * @param {any} value - item to test
 * @returns {boolean} true if empty, otherwise false
 */
export function isEmpty(value) {
  return (
    value === null || // check for null
    value === undefined || // check for undefined
    value === '' || // check for empty string
    (Array.isArray(value) && value.length === 0) || // check for empty array
    (typeof value === 'object' && Object.keys(value).length === 0) // check for empty object
  )
}
