import {
  Agriculture,
  ArtsAndCollectibles,
  Cars,
  DairyIndustry,
  DebtInstruments,
  DigitalAssets,
  ForestryAndNaturalResources,
  GoldAndSilver,
  Infrastructure,
  IntellectualProperty,
  JewelryAndGemstones,
  Land,
  Maritime,
  ParametricInsurance,
  Pharmaceuticals,
  PreciousMetals,
  PrivateEquity,
  RealEstate,
  RenewableEnergy,
  RetailSupplyChain,
  SpaceAssets,
  TokenBasedTicketing,
  TrademarkAndCopyright,
} from 'assets/rwa-categories'

export const ASSET_CATEGORIES = [
  {
    title: '1) Real Estate Asset',
    value: 'real-estate-asset',
    image: RealEstate,
  },
  {
    title: '2) Gold & Silver Mines',
    value: 'gold-silver-mines',
    image: GoldAndSilver,
  },
  {
    title: '3) Cars',
    value: 'cars',
    image: Cars,
  },
  {
    title: '4) Land Assets',
    value: 'land-estate',
    image: Land,
  },
  {
    title: '5) Art & Collectibles',
    value: 'art-and-collectibles',
    image: ArtsAndCollectibles,
  },
  {
    title: '6) Maritime Assets',
    value: 'maritime-assets',
    image: Maritime,
  },
  {
    title: '7) Agricultural Assets',
    value: 'agricultural-assets',
    image: Agriculture,
  },
  {
    title: '8) Precious Metals',
    value: 'precious-metals',
    image: PreciousMetals,
  },
  {
    title: '9) Intellectual Property',
    value: 'intellectual-property',
    image: IntellectualProperty,
  },
  {
    title: '10) Private Equity & Business Stakes',
    value: 'private-equity-and-business-stakes',
    image: PrivateEquity,
  },
  {
    title: '11) Debt Instruments',
    value: 'debt-instruments',
    image: DebtInstruments,
  },
  {
    title: '12) Renewable Energy',
    value: 'renewable-energy',
    image: RenewableEnergy,
  },
  {
    title: '13) Infrastructure',
    value: 'infrastructure',
    image: Infrastructure,
  },
  {
    title: '14) Forestry & Natural Resources',
    value: 'forestry-and-natural-resources',
    image: ForestryAndNaturalResources,
  },
  {
    title: '15) Digital Assets',
    value: 'digital-assets',
    image: DigitalAssets,
  },
  {
    title: '16) Space Assets',
    value: 'space-assets',
    image: SpaceAssets,
  },
  {
    title: '17) Pharmaceuticals',
    value: 'pharmaceuticals',
    image: Pharmaceuticals,
  },
  {
    title: '18) Jewelry & Gemstones',
    value: 'jewelry-and-gemstones',
    image: JewelryAndGemstones,
  },
  {
    title: '19) Dairy Industry',
    value: 'dairy-industry',
    image: DairyIndustry,
  },
  {
    title: '20) Parametric Insurance',
    value: 'parametric-insurance',
    image: ParametricInsurance,
  },
  {
    title: '21) Retail Supply Chain',
    value: 'retail-supply-chain',
    image: RetailSupplyChain,
  },
  {
    title: '22) Token-Based Ticketing',
    value: 'token-based-ticketing',
    image: TokenBasedTicketing,
  },
  {
    title: '23) Trademark & Copyright',
    value: 'trademark-and-copyright-registrations',
    image: TrademarkAndCopyright,
  },
]

export const SUB_CATEGORIES_LIST = {
  'real-estate-asset': {
    subCategories: [
      { label: 'Residential', value: 'residential' },
      { label: 'Commercial', value: 'commercial' },
      { label: 'Industrial', value: 'industrial' },
      { label: 'Retail Properties', value: 'retail-properties' },
    ],
    docs: [
      { label: 'Title deed', mandatory: true },
      { label: 'Property Insurance documents', mandatory: true },
      { label: 'Property Tax records', mandatory: true },
      { label: 'Appraisal or Valuation report', mandatory: true },
      { label: 'Compliance with Zoning and Building codes', mandatory: true },
    ],
  },
  'gold-silver-mines': {
    subCategories: [
      { label: 'Oil', value: 'oil' },
      { label: 'Gas', value: 'gas' },
      { label: 'Diamond', value: 'diamond' },
      { label: 'Rare Earth elements', value: 'rare-earth-elements' },
    ],
    docs: [
      { label: 'Mining rights documentation', mandatory: true },
      { label: 'Title Deed for the Land', mandatory: true },
      { label: 'Geological survey reports', mandatory: true },
      { label: 'Environmental compliance certificates', mandatory: true },
      { label: 'Valuation reports of the mineral reserves', mandatory: true },
    ],
  },
  cars: {
    subCategories: [
      { label: 'Classic Cars', value: 'classic-cars' },
      { label: 'Luxury vehicles', value: 'luxury-vehicles' },
      { label: 'Electric vehicles', value: 'electric-vehicles' },
      { label: 'Fleets', value: 'fleets' },
      { label: 'Commercial vehicles', value: 'commercial-vehicles' },
    ],
    docs: [
      { label: 'Vehicle title or registration document', mandatory: true },
      { label: 'Proof of insurance', mandatory: true },
      { label: 'Vehicle history report', mandatory: true },
      { label: 'Appraisal report for valuable vehicles', mandatory: true },
      { label: 'Maintenance and inspection records', mandatory: true },
    ],
  },
  'land-estate': {
    subCategories: [
      { label: 'Agricultural', value: 'agricultural-lands' },
      { label: 'developmental', value: 'developmental-lands' },
      { label: 'recreational', value: 'recreational-lands' },
      { label: 'Conservation lands', value: 'conservation-lands' },
    ],
    docs: [
      { label: 'Land title deed', mandatory: true },
      { label: 'Zoning and land use documents', mandatory: true },
      { label: 'Environmental impact assessments', mandatory: true },
      {
        label: 'Agricultural permits',
        mandatory: false,
      }, //TODO For Agricultural lands only
      { label: 'Valuation and appraisal reports', mandatory: true },
    ],
  },
  'art-and-collectibles': {
    subCategories: [
      { label: 'Fine Art', value: 'fine-art' },
      { label: 'Sculptures', value: 'sculptures' },
      { label: 'Rare Collectibles', value: 'rare-collectibles' },
      { label: 'Vintage wines', value: 'vintage-wines' },
      { label: 'Luxury watches', value: 'luxury-watches' },
    ],
    docs: [
      { label: 'Certificate of authenticity', mandatory: true },
      { label: 'Provenance documents', mandatory: true },
      { label: 'Insurance valuation', mandatory: true },
      { label: 'Appraisal reports', mandatory: true },
      {
        label: 'Conservation or restoration records',
        mandatory: false,
      },
    ],
  },
  'maritime-assets': {
    subCategories: [
      { label: 'Commercial ships', value: 'commercial-ships' },
      { label: 'Yachts', value: 'yachts' },
      { label: 'Marinas', value: 'marinas' },
      { label: 'Rights to shipping lanes', value: 'rights-to-shipping-lanes' },
      { label: 'Rights to fishing zones', value: 'rights-to-fishing-zones' },
    ],
    docs: [
      { label: 'Ownership titles for vessels', mandatory: true },
      { label: 'Registration and compliance documents', mandatory: true },
      { label: 'Insurance documents', mandatory: true },
      { label: 'Appraisal reports for valuation', mandatory: true },
      { label: 'Inspection and maintenance records', mandatory: true },
    ],
  },
  'agricultural-assets': {
    subCategories: [
      { label: 'Livestock', value: 'livestock' },
      { label: 'Crop Rights', value: 'crop-rights' },
      { label: 'Agricultural Equipment', value: 'agricultural-equipment' },
    ],
    docs: [
      { label: 'Ownership certificates for livestock', mandatory: true },
      { label: 'Land titles for agricultural land', mandatory: true },
      { label: 'Equipment ownership documents', mandatory: true },
      { label: 'Insurance documents for assets', mandatory: true },
      {
        label: 'Crop sales contracts or rights documentation',
        mandatory: true,
      },
    ],
  },
  'precious-metals': {
    subCategories: [
      { label: 'Gold', value: 'gold' },
      { label: 'Silver', value: 'silver' },
      { label: 'Platinum', value: 'platinum' },
      { label: 'Palladium', value: 'palladium' },
      { label: 'Other investable metals', value: 'other-investable-metals' },
    ],
    docs: [
      { label: 'Ownership certificates', mandatory: true },
      { label: 'Storage and custody records ', mandatory: true },
      { label: 'Insurance documents', mandatory: true },
      { label: 'Valuation and purity certificates', mandatory: true },
    ],
  },
  'intellectual-property': {
    subCategories: [
      { label: 'Patents', value: 'patents' },
      { label: 'Trademarks', value: 'trademarks' },
      { label: 'Copyrights', value: 'copyrights' },
      { label: 'Royalties', value: 'royalties' },
    ],
    docs: [
      { label: 'Registration certificates', mandatory: true },
      {
        label: 'Transfer documents proving current ownership',
        mandatory: true,
      },
      { label: 'Royalty agreements', mandatory: true },
      { label: 'Legal documents for exclusive rights', mandatory: true },
    ],
  },
  'private-equity-and-business-stakes': {
    subCategories: [
      {
        label: 'Ownership in private businesses',
        value: 'ownership-in-private-businesses',
      },
      { label: 'Ownership in startups', value: 'ownership-in-startups' },
      { label: 'Specific Projects', value: 'specific-projects' },
    ],
    docs: [
      { label: 'Share certificates', mandatory: true },
      {
        label: 'Operating agreements for LLCs or partnership businesses',
        mandatory: true,
      },
      { label: 'Buy-sell agreements', mandatory: true },
      { label: 'Valuation reports of the business', mandatory: true },
    ],
  },
  'debt-instruments': {
    subCategories: [
      { label: 'Corporate bonds', value: 'corporate-bonds' },
      { label: 'Municipal bonds', value: 'municipal-bonds' },
      {
        label: 'Tokenized loan agreements',
        value: 'tIokenized-loan-agreements',
      },
    ],
    docs: [
      { label: 'Bond certificates', mandatory: true },
      { label: 'Loan agreement documents', mandatory: true },
      { label: 'Amortization schedules', mandatory: true },
      { label: 'Credit rating reports', mandatory: false },
    ],
  },
  'renewable-energy': {
    subCategories: [
      { label: 'Solar farms', value: 'solar-farms' },
      { label: 'Wind turbines', value: 'wind-turbines' },
      { label: 'Hydroelectric plants', value: 'hydroelectric-plants' },
      { label: 'Tradable carbon credits', value: 'tradable-carbon-credits' },
    ],
    docs: [
      { label: 'Ownership documents for the equipment', mandatory: true },
      { label: 'Land lease or ownership documents', mandatory: true },
      { label: 'Power purchase agreements', mandatory: true },
      {
        label: 'Compliance and environmental impact documents',
        mandatory: true,
      },
    ],
  },
  infrastructure: {
    subCategories: [
      {
        label: 'Stakes in bridges',
        value: 'stakes-in-bridges',
      },
      { label: 'Stakes in tunnels', value: 'stakes-in-bridges' },
      { label: 'Stakes in highways', value: 'stakes-in-highways' },
      { label: 'Stakes in utilities', value: 'stakes-in-utilities' },
    ],
    docs: [
      { label: 'Ownership or stakeholding documents', mandatory: true },
      { label: 'Public or private partnership agreements', mandatory: true },
      { label: 'Maintenance and operation records', mandatory: true },
      { label: 'Compliance and safety certificates', mandatory: true },
    ],
  },
  'forestry-and-natural-resources': {
    subCategories: [
      { label: 'Woodlands', value: 'woodlands' },
      { label: 'Water rights', value: 'water-rights' },
      { label: 'Mineral rights', value: 'mineral-rights' },
    ],
    docs: [
      { label: 'Ownership deeds for the land', mandatory: true },
      { label: 'Licensing for extraction or usage rights', mandatory: true },
      { label: 'Environmental compliance certificates', mandatory: true },
      { label: 'Valuation reports for the resources', mandatory: true },
    ],
  },
  'digital-assets': {
    subCategories: [
      { label: 'Domain labels', value: 'domain-labels' },
      { label: 'Online businesses', value: 'online-businesses' },
      { label: 'Digital art', value: 'digital-art' },
    ],
    docs: [
      { label: 'Registration records for domain labels', mandatory: true },
      { label: 'Ownership documents for online businesses', mandatory: true },
      {
        label: 'Certificates of authenticity for digital art',
        mandatory: true,
      },
      {
        label: 'Transaction records for the transfer of digital assets',
        mandatory: true,
      },
    ],
  },
  'space-assets': {
    subCategories: [
      { label: 'Satellite bandwidth', value: 'satellite-bandwidth' },
      { label: 'Space station modules', value: 'space-station-modules' },
      { label: 'Lunar land claims', value: 'lunar-land-claims' },
    ],
    docs: [
      { label: 'Registration and licensing documents', mandatory: true },
      { label: 'Ownership transfer documents', mandatory: true },
      {
        label: 'Contracts for the usage or exploitation of space assets',
        mandatory: true,
      },
    ],
  },
  pharmaceuticals: {
    subCategories: [
      { label: 'Drug Development', value: 'drug-development' },
      { label: 'Manufacturing', value: 'manufacturing' },
      { label: 'Distribution', value: 'distribution' },
    ],
    docs: [
      { label: 'Patents for pharmaceuticals', mandatory: true },
      {
        label: 'Licensing and regulatory compliance documents',
        mandatory: true,
      },
      { label: 'Manufacturing and distribution agreements', mandatory: true },
    ],
  },
  'jewelry-and-gemstones': {
    subCategories: [
      { label: 'Precious Stones', value: 'precious-stones' },
      { label: 'Fine Jewelry', value: 'fine-jewelry' },
      { label: 'Rare Gems', value: 'rare-gems' },
    ],
    docs: [
      { label: 'Certificates of authenticity', mandatory: true },
      { label: 'Appraisal and valuation documents', mandatory: true },
      { label: 'Insurance documents for high-value items', mandatory: true },
    ],
  },
  'dairy-industry': {
    subCategories: [
      { label: 'Production', value: 'production' },
      { label: 'Processing', value: 'processing' },
      { label: 'Distribution', value: 'distribution' },
    ],
    docs: [
      { label: 'Ownership documents for dairy farms', mandatory: true },
      { label: 'Health and safety compliance certificates', mandatory: true },
      { label: 'Processing and distribution licenses', mandatory: true },
    ],
  },
  'parametric-insurance': {
    subCategories: [
      { label: 'Natural Disasters', value: 'natural-disasters' },
      { label: 'Agricultural', value: 'agricultural' },
      { label: 'Health', value: 'health' },
    ],
    docs: [
      { label: 'Insurance policy documents', mandatory: true },
      { label: 'Risk assessment reports', mandatory: true },
      { label: 'Claims history records', mandatory: true },
    ],
  },
  'retail-supply-chain': {
    subCategories: [
      { label: 'Product Sourcing', value: 'product-sourcing' },
      { label: 'Inventory Management', value: 'inventory-management' },
      { label: 'Consumer Sales', value: 'consumer-sales' },
    ],
    docs: [
      { label: 'Supplier agreements', mandatory: true },
      { label: 'Inventory records', mandatory: true },
      { label: 'Sales and distribution agreements', mandatory: true },
    ],
  },
  'token-based-ticketing': {
    subCategories: [
      { label: 'Events', value: 'events' },
      { label: 'Transportation', value: 'transportation' },
      { label: 'Access Rights', value: 'access-rights' },
    ],
    docs: [
      {
        label: 'Contractual agreements for events or services',
        mandatory: true,
      },
      { label: 'Ownership or rights transfer documents', mandatory: true },
      {
        label:
          'Validation of the authenticity and exclusivity of the tickets or access rights',
        mandatory: true,
      },
    ],
  },
  'trademark-and-copyright-registrations': {
    subCategories: [
      {
        label: 'Trademark and Copyright registrations',
        value: 'trademark-and-copyright-registrations',
      },
    ],
    docs: [
      {
        label: 'Trademark or Copyright document',
        mandatory: true,
      },
    ],
  },
}

export const IMG_FILES = ['.jpg', '.jpeg', '.gif', '.png', '.webp']

export const TOAST_MESSAGES = {
  uploading: 'Uploading your data',
  uploadSuccess: 'Uploaded your data successfully',
  uploadFail: 'Failed to upload your data',
}

export const ROUTES = {
  userDashboard: '/',
  assetHome: '/asset',
  assetCategories: '/asset/categories',
  assetDetails: '/asset/details',
  assetDocuments: '/asset/documents',
  assetPreview: '/asset/confirm-details',
}

export const BACKEND_ENDPOINTS = {
  createAsset: 'assets/create-asset',
  s3ImageUpload: '/assets/images-upload',
  userAssets: 'assets/user/asset-details/'
}

export const FormFields = [
  { name: 'firstName', label: 'First Name', placeholder: 'First name' },
  { name: 'lastName', label: 'Last Name', placeholder: 'Last name' },
  { name: 'email', label: 'Email', placeholder: 'Enter your email address' },
  {
    name: 'mobile',
    label: 'Mobile Number',
    placeholder: 'Enter your mobile number',
  },
  {
    name: 'passportNumber',
    label: 'Passport Number',
    placeholder: 'Enter your passport number',
  },
  { name: 'dob', label: 'Birth Date', placeholder: 'Select your birth date' },
  {
    name: 'maritalStatus',
    label: 'Marital Status',
    placeholder: 'Select your marital status',
  },
  { name: 'share', label: 'Share', placeholder: 'Enter your share' },
  { name: 'city', label: 'City', placeholder: 'Enter your city' },
  { name: 'zipCode', label: 'Zip Code', placeholder: 'Enter your zip code' },
  { name: 'state', label: 'State', placeholder: 'Enter your state' },
  { name: 'country', label: 'Country', placeholder: 'Select your country' },
] 
