import { useDispatch } from 'react-redux'
import { useBackend } from 'backend/useBackend'
import { eCommerceActions } from 'features/eCommerceSlice'
import { siteModule } from "config"

export function useECommerce() {
  const dispatch = useDispatch()
  const { backend } = useBackend()

  const getProducts = () => {
    const params = {
      limit: 500,
      siteModule: siteModule,
    }
    const query = new URLSearchParams(params).toString()
    backend()
      .get(`/products/?${query}`)
      .then(({ items }) => {
        dispatch(
          eCommerceActions.loaded({
            items: items,
          }),
        )
      }).catch((e) => {
        console.error(e.message)
      })
  }

  const selectProduct = (sku) => {
    dispatch(
      eCommerceActions.selectProduct({
        sku: sku,
      }),
    )
  }

  const setPaymentMethod = (paymentMethod) => {
    dispatch(eCommerceActions.selectPaymentMethod({ paymentMethod }))
  }

  const clearPaymentMethod = () => {
    dispatch(eCommerceActions.clearPaymentMethod())
  }

  const applyCoupon = (body) => {
    let config = {
      loader: true,
      msg: { loading: 'Applying Coupon...', success: 'Coupon Applied.' },
    }
    backend()
      .post(`/coupons/apply`, body, config)
      .then(({ coupon }) => {
        dispatch(
          eCommerceActions.couponApply({
            coupon: coupon,
          }),
        )
      }).catch((e) => {
        console.error(e.message)
      })
  }

  const clearCoupon = () => {
    dispatch(eCommerceActions.couponClear())
  }

  return {
    applyCoupon,
    clearCoupon,
    getProducts,
    selectProduct,
    setPaymentMethod,
    clearPaymentMethod,
  }
}
