import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { usePrice } from 'ECommerce/hooks/usePrice'
import { useOrder } from 'backend/useOrder'
import { orderTypes } from 'dataSource/constants'
import { selectECommerce } from 'features/eCommerceSlice'
import { useECommerce } from 'ECommerce/hooks/useECommerce'
import Checkout from 'ECommerce/PaymentPages/Checkout'
import { siteModule } from 'config'
import { useBusiness } from 'backend/useBusiness'

const PaymentOptionRegister = () => {
  const navigate = useNavigate()
  const { paymentMethod, products, product, checkout, coupon } =
    useSelector(selectECommerce)
  const { businessType, id } = useParams()
  const { selectProduct } = useECommerce()
  const { price } = usePrice()
  const [entityName, setEntityName] = useState('')
  const [formData, setFormData] = useState({})
  const { makeBusinessOrder, coinbaseCreateCharge, stripeCreateCheckoutSession } = useOrder()
  const { getBusiness } = useBusiness()

  useEffect(() => {
    if (products?.items.length > 0) {
      selectProduct(`${siteModule}-register-${businessType}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  useEffect(() => {
    if (businessType) {
      switch (businessType) {
        case 'trust':
          setEntityName('Blockchain Trust')
          break
        case 'corp':
          setEntityName('Blockchain Corporation')
          break
        case 'bank':
          setEntityName('Blockchain Bank')
          break
        case 'family':
          setEntityName('Blockchain Family')
          break
        case 'insurance':
          setEntityName('Captive Insurance')
          break
        default:
          setEntityName('')
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessType])

  useEffect(() => {
    if (id) {
      getBusiness(id).then((item) => {
        setFormData(item)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const initiateOrder = () => {
    makeBusinessOrder({
      title: product.name + ' - ' + formData?.businessFullName,
      items: [
        {
          refId: id,
          amount: product.payableAmount,
          name: formData?.businessFullName,
          title: product.name,
          description: product.description,
          quantity: 1,
        },
      ],
      type: orderTypes.Business,
      gateway: paymentMethod,
      amount: checkout.total,
      currency: 'USD',
      coupon: coupon,
    }).then((order) => {
      if (paymentMethod === 'AdminPayment') {
        console.log(order)
        navigate(`/payment/admin-payment/${order.id}`)
        return
      } else if (paymentMethod === 'Square') {
        console.log(order)
        navigate(`/payment/square/${order.id}`)
        return
        // } else if (paymentMethod === 'Paypal') {
        //   console.log(order)
        //   navigate(`/payment/paypal/${order.id}`)
        //   return
      } else if (paymentMethod === 'CoinBase') {
        coinbaseCreateCharge({
          name: formData?.businessFullName,
          description: product.description,
          items: [
            {
              amount: checkout.total,
              name: product.name,
              description: product.description,
              quantity: 1,
            },
          ],
          orderId: order.id,
          currency: 'USD',
        }).then((charge) => {
          window.location.href = charge.url
        })
      }
      // else if (paymentMethod === "OnRamper") {
      //   console.log(order)
      //   navigate(`/payment/onramper/${order.id}`)
      //   return
      // }
      else if (paymentMethod === 'Stripe') {
        stripeCreateCheckoutSession({
          title: product.name,
          items: [
            {
              amount: checkout.total,
              name: product.name,
              description: product.description,
              quantity: 1,
            },
          ],
          orderId: order.id,
          currency: 'USD',
        }).then((session) => {
          window.location.href = session.url
        })
      }
      // else if (paymentMethod === 'ForumPay') {
      //   navigate('/payment/forumpay/' + order.id)
      // }
      else {
        navigate('/payment/done/' + order.id)
      }
    })
  }

  return (
    <div>
      {/* <pre>{JSON.stringify(coupon, null, 2)}</pre> */}
      <div className="text-xs">
        <span className="text-muted">Make Payment For</span>
        <span className="dot">
          {product.name} - {formData?.businessFullName}
        </span>
      </div>
      <div className="text-center mt-5">
        <h2 className="text-xl mb-3">Payment</h2>
        <p className="text-muted mb-3 pb-4">
          After successful payment you can download immediately all registration
          certificates of your new {entityName}. You can pay with Cryptocurrency
          or with your credit card. The total amount is only{' '}
          <b>{price(product.payableAmount)}</b> for your {entityName}{' '}
          registration.
          <br />
          Any questions, feel free to send us an email:{' '}
          <a href="mailto:register@blockchaintrust.pro">
            register@blockchaintrust.pro
          </a>
        </p>
      </div>
      <div>
        <Checkout
          initiateOrder={initiateOrder}
          items={[
            {
              name: formData?.businessFullName,
            },
          ]}
        />
      </div>
      <div className="flex justify-between mt-10">
        <button
          type="button"
          className="btn-back"
          tabIndex="-1"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
        {/* {paymentMethod ? (
          <button className="btn-primary" onClick={initiateOrder}>
            Proceed with {paymentMethod}
          </button>
        ) : (
          <button className="btn-primary" disabled>
            Select Payment Method
          </button>
        )} */}
      </div>
    </div>
  )
}

export default PaymentOptionRegister
