import { lazy } from 'react'

// project imports
import AuthGuard from './AuthGuard'
import PageLayout from 'Layouts/PageLayout'
import SiteModuleGuard from './SiteModuleGuard'

// For Blockchain Trust
const Intro = lazy(() => import('Pages/Registration/Trust/Step1Intro'))
const BusinessName = lazy(() =>
  import('Pages/Registration/Trust/Step2BusinessName'),
)
const BusinessDetails = lazy(() =>
  import('Pages/Registration/Trust/Step3BusinessDetails'),
)
const ShareholderType = lazy(() =>
  import('Pages/Registration/Trust/Step4ShareholderType'),
)
const ShareHolderCorporate = lazy(() =>
  import('Pages/Registration/Trust/Step5ShareHolderCorporate'),
)
const ShareHolderIndividualOne = lazy(() =>
  import('Pages/Registration/Trust/Step5ShareHolderIndividualOne'),
)
const ShareHolderIndividualTwo = lazy(() =>
  import('Pages/Registration/Trust/Step5ShareHolderIndividualTwo'),
)
const ConfirmDetails = lazy(() =>
  import('Pages/Registration/Trust/Step6ConfirmDetails'),
)
const UploadDocument = lazy(() =>
  import('Pages/Registration/Trust/Step7UploadDocument'),
)
const BeneficiaryDetails = lazy(() =>
  import('Pages/Registration/Trust/Step4BeneficiaryDetails'),
)
const BeneficiaryListOneForm = lazy(() =>
  import('Pages/Registration/Trust/Step5BeneficiaryOne'),
)
const BeneficiaryListTwoForm = lazy(() =>
  import('Pages/Registration/Trust/Step5BeneficiaryTwo'),
)
const BeneficiaryListThreeForm = lazy(() =>
  import('Pages/Registration/Trust/Step5BeneficiaryThree'),
)
const BeneficiaryListFourForm = lazy(() =>
  import('Pages/Registration/Trust/Step5BeneficiaryFour'),
)
const BeneficiaryListFiveForm = lazy(() =>
  import('Pages/Registration/Trust/Step5BeneficiaryFive'),
)
const BeneficiaryCorporate = lazy(() =>
  import('Pages/Registration/Trust/Step5BeneficiaryCorporate'),
)

// ==============================|| PAGES ROUTING ||============================== //

const RegistrationTrustRoutes = {
  path: '',
  children: [
    {
      path: '/register',
      exact: true,
      element: (
        <AuthGuard>
          <PageLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'trust',
          element: <Intro />,
        },
      ],
    },
    {
      path: '/register',
      element: (
        <AuthGuard>
          <SiteModuleGuard>
            <PageLayout />
          </SiteModuleGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: 'trust',
          children: [
            // { index: true, element: <Intro /> },
            { path: 'name', element: <BusinessName /> },
            { path: ':id/business', element: <BusinessDetails /> },
            { path: ':id/shareholder', element: <ShareholderType /> },
            {
              path: ':id/shareholder-corporate',
              element: <ShareHolderCorporate />,
            },
            // { path: ':id/shareholder/:holder', element: <ShareHolderIndividuals /> },
            {
              path: ':id/shareholder-1',
              element: <ShareHolderIndividualOne />,
            },
            {
              path: ':id/beneficiary-corporate',
              element: <BeneficiaryCorporate />,
            },
            {
              path: ':id/shareholder-2',
              element: <ShareHolderIndividualTwo />,
            },
            // { path: ':id/confirm', element: <ConfirmDetails /> },
            // { path: ':id/upload', element: <UploadDocument /> },
            // { path: ':id/payment', element: <StepPayment /> },
            { path: ':id/:formName/confirm', element: <ConfirmDetails /> },
            { path: ':id/upload', element: <UploadDocument /> },
            { path: ':id/beneficiary', element: <BeneficiaryDetails /> },
            { path: ':id/beneficiary-1', element: <BeneficiaryListOneForm /> },
            { path: ':id/beneficiary-2', element: <BeneficiaryListTwoForm /> },
            {
              path: ':id/beneficiary-3',
              element: <BeneficiaryListThreeForm />,
            },
            { path: ':id/beneficiary-4', element: <BeneficiaryListFourForm /> },
            { path: ':id/beneficiary-5', element: <BeneficiaryListFiveForm /> },
          ],
        },
      ],
    },
  ],
}

export default RegistrationTrustRoutes
