import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom"
import ReactDOM from 'react-dom/client'
import store from './features/store'
import App from './App'
import './firebase'
import './index.css'
// import reportWebVitals from './reportWebVitals'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.Fragment>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.Fragment>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
