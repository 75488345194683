const businessTypes = {
  trust: 'TRUST',
  corp: 'CORP',
  family: 'FAMILY',
  bank: 'BANK',
  insurance: 'INSURANCE',
}

const orderTypes = {
  User: 'User',
  Business: 'Business',
  GoodStanding: 'GoodStanding',
}

const shareholderType = {
  Corporate: 'Corporate',
  Individual: 'Individual',
}

const siteModules = {
  Reseller: 'Reseller',
  Individual: 'Individual',
}

const beneficiaryType = {
  Corporate: 'Corporate',
  Individual: 'Individual',
  Family: 'Family',
}

// const registeredAsType = {
//   Reseller: 'Agent',
//   Individual: 'Individual',
// };

const paymentStatus = {
  Awaiting: 'Awaiting',
  Canceled: 'Canceled',
  Confirmed: 'Confirmed',
  Expired: 'Expired',
  Failed: 'Failed',
  Pending: 'Pending',
  Processing: 'Processing',
  Success: 'Success',
}

const paymentButtonVisibility = ['Pending', 'Expired', 'Canceled', 'Failed']

const FormFieldsName = {
  MaritalStatus: 'maritalStatus',
  Share: 'share',
  Country: 'country',
  Dob: 'dob',
}

export {
  businessTypes,
  paymentStatus,
  shareholderType,
  paymentButtonVisibility,
  orderTypes,
  siteModules,
  beneficiaryType,
  FormFieldsName
}
