// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCmT04LmLTz8b9E7JFvqHhJ7fVxTQErxbk',
  authDomain: 'blockchaintrust-pro.firebaseapp.com',
  projectId: 'blockchaintrust-pro',
  storageBucket: 'blockchaintrust-pro.appspot.com',
  messagingSenderId: '576893043541',
  appId: '1:576893043541:web:723683351b9864c63de5dc',
  measurementId: 'G-TZ3QTYZCHT',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Get a reference to the storage service, which is used to create references in your storage bucket
const storage = getStorage(app)

export { app, storage }
