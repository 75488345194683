import React from 'react'
import { useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'backend/useAuth'
import { countriesOptions } from 'dataSource/countries'
import { prefixOptions, suffixOptions } from 'dataSource/user'
import { Form, Input, Select, Submit } from 'tui'
import { selectAuth } from "features/authSlice"

const AccountAddress = () => {
  const navigate = useNavigate()
  const { user } = useSelector(selectAuth)
  const { updateUserProfile } = useAuth()

  const onSubmit = (data) => {
    // const payload = {
    //   accountAddress: data
    // }
    updateUserProfile(data).then(() => {
      navigate('/account')
    })
  }

  return (
    <div>
      <h1 className="text-3xl mb-4">Account Address</h1>
      <div className="bg-info p-4 rounded-sm mb-5">
        This address is used only for account communications unrelated to your
        companies. Changing this address <b>does not change</b> billing or
        primary contact information for your companies. Edit company addresses
        in your Address Book.
      </div>
      <div className="w-full col-span-2">
        <div className="text-lg">Edit Address</div>
      </div>
      <Form onFinish={onSubmit} initialValues={user}>
        <div className="grid grid-cols-4 gap-5 mt-5">
          <div className="w-full">
            <Select
              required
              name="prefix"
              label="Title"
              // placeholder="Select Country"
              options={prefixOptions}
              // rules={{ required: "Country is required" }}
            />
          </div>
          <div className="w-full">
            <Input.Text
              name="firstName"
              required
              label="First Name"
              placeholder="First Name"
              rules={{ required: 'First Name is required' }}
            />
          </div>
          <div className="w-full">
            <Input.Text
              name="lastName"
              required
              label="Last Name"
              placeholder="Last Name"
              rules={{ required: 'Last Name is required' }}
            />
          </div>
          <div className="w-full">
            <Select
              required
              name="suffix"
              label="Suffix"
              // placeholder="Select Country"
              options={suffixOptions}
              // rules={{ required: "Country is required" }}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 mt-5">
          <div className="w-full">
            <Input.Text
              name="addressLine1"
              required
              label="Address Line 1"
              placeholder="Address Line 1"
              rules={{ required: 'Address Line 1 is required' }}
            />
          </div>
          <div className="w-full">
            <Input.Text
              name="addressLine2"
              // required
              label="Address Line 2"
              placeholder="Apartment, Building, Suite, etc."
              // rules={{ required: "Address Line 2 is required" }}
            />
          </div>
          <div className="w-full">
            <Input.Text
              name="city"
              required
              label="City"
              placeholder="City"
              rules={{ required: 'City is required' }}
            />
          </div>
          <div className="w-full">
            <div className="grid grid-cols-2 gap-5">
              <div className="w-full">
                <Input.Text
                  name="state"
                  required
                  label="State"
                  placeholder="State"
                  rules={{ required: 'State is required' }}
                />
              </div>
              <div className="w-full">
                <Input.Text
                  name="zipCode"
                  required
                  label="Zip Code"
                  placeholder="Zip Code"
                  rules={{ required: 'Zip Code is required' }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5 mt-5">
          <div className="w-full">
            <Select
              required
              name="country"
              label="Country"
              placeholder="Select Country"
              options={countriesOptions}
              rules={{ required: 'Country is required' }}
            />
          </div>
          <div className="w-full">
            <Input.Text
              name="phone"
              required
              label="Phone Number"
              placeholder="Phone Number"
              // rules={{ required: "Phone Number is required" }}
            />
          </div>
          {/* <div className="w-full">
            <Input.Email
              name="email"
              required
              label="Email"
              placeholder="Email"
              // rules={{ required: "Email is required" }}
            />
          </div> */}
        </div>
        <div className="flex justify-end mt-5">
          <Submit className="bg-primary flex items-center p-2 px-4 mr-2">
            Submit
          </Submit>
        </div>
      </Form>
    </div>
  )
}

export default AccountAddress
