import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'

const CheckboxGroup = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext() // retrieve all hook methods
  const {
    name = '',
    label = '',
    required = false,
    placeholder = '',
    defaultValue = '',
    className = '',
    options = [],
    rules,
    icon,
    layout = 'inline',
    ...restProps
  } = props
  return (
    <div className="mb-1">
      <div
        className={classNames(
          layout === 'inline' ? 'flex justify-between' : 'relative',
        )}
      >
        <label className={classNames({ required })}>{label}</label>
        {options.length > 0 &&
          options.map((o) => (
            <div className="flex items-center gap-2 mb-2" key={o.value}>
              <input
                id={o.label}
                name={name}
                type="checkbox"
                className={classNames(className, `tw-control`, {
                  invalid: errors[name]?.message,
                })}
                placeholder={placeholder}
                defaultChecked={o.value === defaultValue}
                value={o.value}
                {...register(name, { ...rules })}
                {...restProps}
              />
              {o.label && (
                <label htmlFor={o.label} className="m-0">
                  {o.label}
                </label>
              )}
            </div>
          ))}
      </div>
      {errors[name]?.message && (
        <div className="invalid-msg">{errors[name]?.message}</div>
      )}
    </div>
  )
}

export default CheckboxGroup
