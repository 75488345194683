import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import { FaBitbucket, FaEdit, FaPlus, FaTrashAlt } from 'react-icons/fa'
import { HiCog, HiRefresh } from 'react-icons/hi'

import { Form, Input, Submit } from 'tui'
import { usePaginate } from 'backend/usePaginate'
import Placeholder from 'Components/Placeholder'
import DateTimeAgo from 'Components/DateTimeAgo'
import { countryNameByIso2 } from 'dataSource/countries'
import { useShareholder } from 'backend/useShareholder'
import ShareholderDetailsByType from 'Components/ShareholderDetailsByType'

const endpoint = 'shareholder-business/my-shareholders'

const Shareholders = () => {
  const { deleteShareholder } = useShareholder()
  const [filtered, setFiltered] = useState(false)
  const { hasMore, items, loadMore, filter, loading } = usePaginate(endpoint, {
    limit: 100,
  })

  const onSubmit = (data) => {
    filter(data).then(() => setFiltered(true))
    console.log('data', data)
  }

  const clearFilter = () => {
    filter({}).then(() => setFiltered(false))
  }

  const deleteConfirm = (id) => {
    const input = window.confirm(
      'Are you sure you want to delete this shareholder?',
    )
    console.log('data', id)
    if (input) {
      deleteShareholder(id)
    }
  }

  return (
    <div>
      <h1 className="text-3xl mb-4">Shareholders</h1>
      <Form onFinish={onSubmit}>
        <div className="flex justify-between items-start">
          <div className="flex space-x-4">
            <div className="">
              <Input.Text
                name="firstName"
                placeholder="Search by Shareholder Name/Business Name"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="flex items-center">
            <Submit className="bg-primary flex items-center p-2 px-4 mr-2">
              <HiCog className="mr-2" /> Apply Filter
            </Submit>
            {filtered && (
              <button
                type="reset"
                className="flex items-center bg-input p-2 px-4"
                onClick={clearFilter}
              >
                <HiRefresh className="mr-2" />
                Clear Filter
              </button>
            )}
          </div>
        </div>
      </Form>
      <div className="mt-2">
        <div className="flex justify-end pt-2 mx-4">
          <Link to="/shareholders/new" className="flex items-center p-2">
            <FaPlus className="mr-2" /> Add New
          </Link>
        </div>
        {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
        <div>
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={
              loading && (
                <h4 style={{ textAlign: 'center' }} key="loader">
                  Loading...
                </h4>
              )
            }
          >
            <>
              {loading === false && items?.length === 0 ? (
                <Placeholder
                  icon={FaBitbucket}
                  text={
                    filtered
                      ? 'No result found'
                      : 'Shareholders will display here'
                  }
                />
              ) : (
                items.map((item) => (
                  <Fragment key={item.id}>
                    <div className="bg-black my-4 rounded-md p-4">
                      <div className="m-2">
                        <div className="flex justify-between space-x-4">
                          <div className="sm:w-2/4">
                            <p className="text-gold-400">
                              {item.type}
                            </p>
                            <ShareholderDetailsByType shareholder={item} />
                            {item?.email && (
                              <div>
                                Email{' '}
                                <span className="text-gold-400">
                                  {item?.email}
                                </span>
                              </div>
                            )}
                            {item?.mobile && (
                              <div>
                                Mobile{' '}
                                <span className="text-gold-400">
                                  {item?.mobile}
                                </span>
                              </div>
                            )}
                            <p className="text-sm">
                              Share{' '}
                              <span className="text-gold-400">
                                {item.share}%
                              </span>
                            </p>
                            <div className="mt-2 text-muted">
                              <h5 className="text-white">Address</h5>
                              {item.address}
                              {item.city} - {item.zipCode}
                              <p>
                                {item.state}, {countryNameByIso2(item.country)}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col justify-between">
                            <div className="flex space-x-2 justify-end">
                              <Link to={`/shareholders/${item.id}`}>
                                <FaEdit title="Edit Shareholder" />
                              </Link>
                              <button
                                className="p-0 text-gold-400"
                                onClick={() => deleteConfirm(item.id)}
                              >
                                <FaTrashAlt title="Delete Shareholder" />
                              </button>
                            </div>
                            <p className="text-sm text-muted">
                              Created <DateTimeAgo date={item.createdAt} />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))
              )}
            </>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  )
}

export default Shareholders
