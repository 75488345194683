import React from 'react'
import { useSelector } from 'react-redux'
import { selectAuth } from 'features/authSlice'
import { FaSignOutAlt } from 'react-icons/fa'
import { useBackend } from "backend/useBackend"
import { config } from "config"

const AdminLoginNotice = () => {
  const {updateToken} = useBackend()
  const { user, adminUser } = useSelector(selectAuth)
  const clearAdminUser = () => {
    updateToken(null, false)
    window.location.replace(`${config.adminUrl}/users`)
  }

  return (
    adminUser?.id && (
      <div className="p-2 w-full bg-red-600 top-0 z-50 grid gap-2 grid-cols-12">
        <div className="col-span-full md:col-span-2">
          <button
            onClick={clearAdminUser}
            className="p-0 text-white hover:text-white font-bold"
          >
            <FaSignOutAlt className="inline mr-1 rotate-180" />
            Back to Admin
          </button>
        </div>
        <div className="text-center col-span-full md:col-span-8">
          {/* <pre>{JSON.stringify(adminUser, null, 2)}</pre> */}
          <p>
            You are logged in as <b>{user.email}</b> using your admin account{' '}
            <b>{adminUser.email}</b>
          </p>
        </div>
      </div>
    )
  )
}

export default AdminLoginNotice
