import { createSlice } from "@reduxjs/toolkit";
import { siteModules } from "dataSource/constants"
import { status } from "./status"

const initialState = {
  webp: true,
  services: {
    status: status.idle,
    items: [
      // {
      //   name: 'Complete Signup Payment',
      //   to: '/payment/account/new'
      // },
      // {
      //   name: 'Complete Renew Payment',
      //   to: '/account/payment/renew'
      // },
      // {
      //   name: 'Update Pass',
      //   to: '/account/password'
      // },
      // {
      //   name: 'Make Agent Fees',
      //   to: '/payment/account/new',
      //   siteModules: [siteModules.Reseller],
      // },
      {
        name: 'FORM A NEW BLOCKCHAIN TRUST',
        to: '/register/trust',
        siteModules: [siteModules.Reseller, siteModules.Individual]
      },
      {
        name: 'BULK PAY ANNUAL RENEWAL FEES',
        to: 'upcoming',
        siteModules: [siteModules.Reseller]
      },
      {
        name: 'BULK UPDATE BLOCKCHAIN TRUST BUSINESS ADDRESS',
        to: 'address',
        siteModules: [siteModules.Reseller]
      },
      {
        name: 'COMPANY NAME SEARCH',
        to: 'name-search',
        siteModules: [siteModules.Reseller, siteModules.Individual]
      },
      {
        name: 'Certificate of Good Standing',
        to: 'good-standing',
        siteModules: [siteModules.Reseller, siteModules.Individual]
      },
    ],
  },
  registerAmount: 1999,
  trustRegisterAmount: 1999,
  corpRegisterAmount: 1999,
  bankRegisterAmount: 25000,
  familyRegisterAmount: 2999,
  renewAmount: 49,
  agentSignupAmount: 2999,
  goodStandingAmount: 49,
  btEntityEndings: [
    { value: 'Blockchain Trust', label: 'Blockchain Trust' },
    { value: 'BT', label: 'BT' },
  ],
  corpEntityEndings: [
    // { value: "ALL", label: "N/A or ALL" },
    { value: 'Aktiengesellschaft', label: 'Aktiengesellschaft' },
    { value: 'AG', label: 'AG' },
    { value: 'Ltd', label: 'Ltd' },
    { value: 'IBC', label: 'IBC' },
    { value: 'Corp', label: 'Corp' },
    { value: 'Inc.', label: 'Inc.' },
    { value: 'Co.', label: 'Co.' },
    { value: 'BV', label: 'BV' },
    { value: 'NV', label: 'NV' },
    { value: 'Gmbh', label: 'Gmbh' },
    { value: 'PC', label: 'PC' },
    { value: 'LLC', label: 'LLC' },
    { value: 'PLC', label: 'PLC' },
    { value: 'PLLC', label: 'PLLC' },
    { value: 'LLP', label: 'LLP' },
    { value: 'REIT', label: 'REIT' },
    { value: 'VT', label: 'VT' },
    { value: 'Sociedad Anónima', label: 'Sociedad Anónima' },
    { value: 'SA', label: 'SA' },
    {
      value: 'Sociedad de Responsabilidad Limitada',
      label: 'Sociedad de Responsabilidad Limitada',
    },
    { value: 'SRL', label: 'SRL' },
    { value: 'Conglomerate', label: 'Conglomerate' },
    { value: 'Cooperative', label: 'Cooperative' },
    {
      value: 'Decentralized Autonomous Organization',
      label: 'Decentralized Autonomous Organization',
    },
    { value: 'DAO', label: 'DAO' },
    { value: 'DAC', label: 'DAC' },
    { value: 'Association', label: 'Association' },
    { value: 'Club', label: 'Club' },
    { value: 'Foundation', label: 'Foundation' },
    { value: 'Fund', label: 'Fund' },
    { value: 'Institute', label: 'Institute' },
    { value: 'Society', label: 'Society' },
    { value: 'Union', label: 'Union' },
    { value: 'Syndicate', label: 'Syndicate' },
    { value: 'Eingetragener Verein', label: 'Eingetragener Verein' },
    { value: 'EV', label: 'EV' },
  ],
  familyEntityEndings: [
    { value: 'BLOCKCHAIN FAMILY TRUST', label: 'BLOCKCHAIN FAMILY TRUST' },
  ],
  bankEntityEndings: [{ value: 'BLOCKCHAIN BANK', label: 'BLOCKCHAIN BANK' }],
  shareholderTypes: [
    { label: 'Corporate Shareholder', value: 'Corporate' },
    { label: 'Individual Shareholder', value: 'Individual' },
  ],
  maritalStatus: [
    { label: 'Married', value: 'Married' },
    { label: 'Single', value: 'Single' },
    { label: 'Divorced', value: 'Divorced' },
    { label: 'Widowed', value: 'Widowed' },
  ],
}

export const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    setWebp(state, { payload }) {
      state.webp = payload.supports;
    },
  },
});

export const cmsActions = cmsSlice.actions;
export const selectCms = (state) => state.cms;
export default cmsSlice.reducer;
