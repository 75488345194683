import React from 'react'
import { useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'backend/useAuth'
import { Form, Select, Submit } from 'tui'
import { selectAuth } from "features/authSlice"

const BillingPreference = () => {
  const navigate = useNavigate()
  const { user } = useSelector(selectAuth)
  const { updateUserProfile } = useAuth()

  const onSubmit = (data) => {
    updateUserProfile(data).then(() => {
      navigate('/account')
    })
  }

  return (
    <div>
      <h1 className="text-3xl mb-4">Billing Preference</h1>
      <Form onFinish={onSubmit} initialValues={user}>
        <div className="w-full">
          {/* <section className="tracking-wider mb-4">
            <h3 className="text-gold-400 font-semibold text-2xl  mb-2">
              Bill Delivery
            </h3>
            <p>Updating your billing preference here will update it for every company on your account..</p>
            <p className="text-danger">Your companies have different delivery preferences. By selecting a delivery type you will set every company billing preference.</p>
            <div className="md:flex">
              <Select
                name="billDelivery"
                // label="Entity Ending"
                // placeholder="Bill Delivery"
                options={[
                  { value: "Email", label: "Email" },
                ]}
                defaultValue={user.billDelivery}
              />
            </div>
          </section> */}
          <section className="tracking-wider">
            <h3 className="text-gold-400 font-semibold text-2xl mb-2">
              Registered Agent Notice Frequency
            </h3>
            <p>
              Updating your registered agent notice frequency here will update
              it for every company on your account.
            </p>
            <p className="text-danger">
              'Fewer Notices' results in one notice approximately 45 days before
              due date, and monthly late reminders.
            </p>
            <div className="md:flex">
              <Select
                name="noticeFrequency"
                // label="Entity Ending"
                // placeholder="Select Agent Notice Frequency"
                options={[
                  { value: 'All Notice', label: 'All Notice' },
                  { value: 'Fewer Notice', label: 'Fewer Notice' },
                ]}
                // defaultValue={user.noticeFrequency}
              />
            </div>
          </section>
          <div className="flex justify-end mt-5">
            <Submit className="bg-primary flex items-center p-2 px-4 mr-2">
              Save Changes
            </Submit>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default BillingPreference
