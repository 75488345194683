import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
// import './App.css'
// import usePayPal from 'ECommerce/PaymentGateways/PayPal/usePayPal'
import { useAuth } from 'backend/useAuth'
import { selectAuth } from 'features/authSlice'
import { status } from 'features/status'

import Loader, { LoaderSuspense } from './utils/loader'
import { ClientProvider } from './context/ClientContext'
import Routes from './Routes'
import UatHighLight from './Components/UatHighLight'
// import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import AdminLoginNotice from 'Components/AdminLoginNotice'
import useSquareUp from 'ECommerce/PaymentGateways/SquareUp/useSquareUp'

function App() {
  const auth = useSelector(selectAuth)
  const location = useLocation()
  // const { getClientId, clientId, client } = usePayPal()
  const { getSquareClient } = useSquareUp()
  const { autoLogin } = useAuth()

  useEffect(() => {
    // getClientId()
    getSquareClient()
    if (window.location.href.search('auth/') === -1) {
      if (auth.status !== status.fulfilled) {
        autoLogin()
      }
    }
    // if (!window?.paypal) {
    //   enqueueScript()
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location])

  // useEffect(() => {
  //   // document.addEventListener('visibilitychange', function () {
  //   //   // Check if the page is hidden
  //   //   if (document.hidden) {
  //   //     console.log('User opened another tab')
  //   //   } else {
  //   //     console.log('User is on this tab')
  //   //   }
  //   // })
  //   // Create a new BroadcastChannel with a unique channel name
  //   const channel = new BroadcastChannel('tab-activity')

  //   // Listen for messages on the channel
  //   channel.addEventListener('message', (event) => {
  //     if (event.data === 'open-new-tab') {
  //       console.log(window.origin, event.origin)
  //       if(window.origin == event.origin) {
  //         window.close()
  //       }
  //       console.log('Broadcast: User opened another tab', event)
  //     }
  //   })

  //   // Send a message to all other tabs when a new tab is opened
  //   document.addEventListener('visibilitychange', () => {
  //     if (document.hidden) {
  //       channel.postMessage('open-new-tab')
  //       // logout().then(() => {
  //       //   window.location.href = '/auth/login'
  //       // })
  //     }
  //   })
  //   return () => {}
  // }, [])

  return (
    <React.Suspense fallback={<LoaderSuspense />}>
      <ClientProvider>
        <AdminLoginNotice />
        {/* {clientId ? (
          <PayPalScriptProvider
            options={{
              clientId: client.clientId,
              // dataClientToken: client.dataClientToken,
              currency: 'USD',
              // debug: true,
              disableFunding: 'card',
              // components: 'hosted-fields'
            }}
          >
            <Routes />
          </PayPalScriptProvider>
        ) : (
          <Routes />
        )} */}
        <Routes />
      </ClientProvider>
      <Loader />
      <ToastContainer theme="dark" position="bottom-center" />
      <UatHighLight />
    </React.Suspense>
  )
}

export default App
