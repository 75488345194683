import React from 'react';
import TimeAgo from 'javascript-time-ago';

// English.
import en from 'javascript-time-ago/locale/en';
import dayjs from 'dayjs';
var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

TimeAgo.addDefaultLocale(en);

const DateTimeAgo = ({ date, onlyDate = false }) => {
  // Create formatter (English).
  const timeAgo = new TimeAgo('en-US');
  return date ? (
    !onlyDate ? (
      <abbr
        // title={format(new Date(date), 'PPp')}
        title={dayjs(date).format('LL LT')}
      >
        {timeAgo.format(new Date(date))}
      </abbr>
    ) : (
      dayjs(date).format('LL LT')
    )
  ) : null;
};

export default DateTimeAgo;
