import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FaPercent } from 'react-icons/fa'

import { DatePicker, Form, Input, Select, Submit } from 'tui'
import { useBusiness } from 'backend/useBusiness'
import { countriesOptions } from 'dataSource/countries'
import { maritalStatus } from 'dataSource/options'
import { businessTypes, shareholderType } from 'dataSource/constants'
import ReusableModal from 'Components/ReusableModal'
import { uiActions } from 'features/uiSlice'

const ShareholderModalEdit = ({
  business,
  id,
  index, // it is important
}) => {
  const dispatch = useDispatch()
  const [shareholder, setShareholder] = useState(null)
  const [maxShare, setMaxShare] = useState(100)
  const { updateBusinessDetails } = useBusiness()

  useEffect(() => {
    if (business) {
      if (business.shareholderType === shareholderType.Corporate) {
        setShareholder(business.corporateShareholders[index])
        // const max = business.corporateShareholders.reduce((a, b) => {
        //   return Number(a?.share || 0) + Number(b?.share || 0)
        // }, 0)
        // setMaxShare(100)
      }
      if (business.shareholderType === shareholderType.Individual) {
        setShareholder(business.shareholders[index])
        const max = (business.shareholders || []).reduce((a, b) => {
          return a + Number(b?.share)
        }, 0)

        setMaxShare(
          100 - (max - parseInt(business?.shareholders[index]?.share))
        )
      }
    }
  }, [business, index])

  const handleClose = () => {
    dispatch(uiActions.closeReUsableModal())
  }

  const onSubmit = (data) => {
    console.log(data)
    let payload = {
      id: id,
    }
    if (business.shareholderType === shareholderType.Corporate) {
      payload = {
        id: id,
        corporateShareholders: [data],
        shareholderCount: 1,
      }
    }
    if (business.shareholderType === shareholderType.Individual) {
      payload = {
        id: id,
        shareholders: business.shareholders.map((s, i) => {
          if (i === index) {
            return data
          }
          return s
        }),
      }
    }
    updateBusinessDetails(payload).then(() => {
      handleClose()
    })
  }

  return (
    <ReusableModal
      title={
        <>
          Update{' '}
          {business?.businessType === businessTypes.family ? (
            <>
              {index === 0 && 'Husband Info'}
              {index === 1 && 'Wife Info'}
            </>
          ) : business.shareholderType === shareholderType.Corporate ? (
            'Corporate Shareholder'
          ) : (
            'Individual Shareholder'
          )}
        </>
      }
    >
      {/* <pre>{JSON.stringify(shareholder, null, 2)}</pre> */}
      <Form onFinish={onSubmit} initialValues={shareholder}>
        <div className="grid grid-cols-2 gap-5 mt-8">
          {business.shareholderType === shareholderType.Corporate && (
            <>
              <div className="w-full col-span-2">
                <Input.Text
                  name="businessName"
                  required
                  label="Corporate/Business Name"
                  placeholder="Business Name"
                  rules={{ required: 'Business Name is required' }}
                />
              </div>
              <div className="w-full">
                <Input.Text
                  name="filingNumber"
                  required
                  label="Registration/Filing Number"
                  placeholder="Registration/Filing Number"
                  rules={{
                    required: 'Registration/Filing Number is required',
                  }}
                />
              </div>
              <div className="w-full">
                <DatePicker
                  name="regDate"
                  required
                  label="Corporate Date of Registration"
                  placeholder="Corporate Date of Registration"
                  rules={{
                    required: 'Corporate Date of Registration is required',
                  }}
                  pickerOptions={{
                    // minDate: subYears(new Date(), 60),
                    // maxDate: subYears(new Date(), 18),
                    dateFormat: 'dd/MM/yyyy',
                    closeOnScroll: true,
                    // showMonthYearPicker: true,
                    showMonthDropdown: true,
                    // useShortMonthInDropdown: true,
                    showYearDropdown: true,
                    // showMonthDropdown
                    // showYearDropdown
                    dropdownMode: 'select',
                    // withPortal: true
                  }}
                />
              </div>
              <div className="w-full col-span-2">
                <Input.Number
                  name="share"
                  required
                  label="Share"
                  placeholder="Share in %"
                  min="0"
                  max={maxShare}
                  icon={<FaPercent />}
                  rules={{ required: 'Share is required' }}
                />
              </div>
            </>
          )}
          {business.shareholderType === shareholderType.Individual && (
            <>
              <div className="w-full">
                <Input.Text
                  name="firstName"
                  required
                  label="First Name"
                  placeholder="First Name"
                  rules={{ required: 'First Name is required' }}
                />
              </div>
              <div className="w-full">
                <Input.Text
                  name="lastName"
                  required
                  label="Last Name"
                  placeholder="Last Name"
                  rules={{ required: 'Last Name is required' }}
                />
              </div>
              <div className="w-full">
                <Input.Email
                  name="email"
                  required
                  label="Email"
                  placeholder="Email"
                  rules={{ required: 'Email is required' }}
                />
              </div>
              <div className="w-full">
                <Input.Text
                  name="mobile"
                  required
                  label="Mobile Number"
                  placeholder="Mobile Number"
                  rules={{ required: 'Mobile Number is required' }}
                />
              </div>
              <div className="w-full">
                <Input.Text
                  name="passportNumber"
                  required
                  label="Passport Number"
                  placeholder="Passport Number"
                  rules={{ required: 'Passport Number is required' }}
                />
              </div>
              <div className="w-full">
                <DatePicker
                  name="dob"
                  required
                  label="Birth Date"
                  placeholder="Birth Date"
                  rules={{ required: 'Birth Date is required' }}
                  pickerOptions={{
                    // minDate: subYears(new Date(), 60),
                    // maxDate: subYears(new Date(), 18),
                    dateFormat: 'dd/MM/yyyy',
                    closeOnScroll: true,
                    // showMonthYearPicker: true,
                    showMonthDropdown: true,
                    // useShortMonthInDropdown: true,
                    showYearDropdown: true,
                    // showMonthDropdown
                    // showYearDropdown
                    dropdownMode: 'select',
                    // withPortal: true
                  }}
                />
              </div>
              <div className="w-full">
                <Select
                  required
                  name="maritalStatus"
                  label="Marital Status"
                  placeholder="Select Marital Status"
                  options={maritalStatus}
                  rules={{ required: 'Marital Status is required' }}
                />
              </div>
              <div className="w-full">
                <Input.Number
                  name="share"
                  required
                  label="Share"
                  placeholder="Share in %"
                  min="0"
                  max={maxShare}
                  icon={<FaPercent />}
                  rules={{ required: 'Share is required' }}
                />
              </div>
            </>
          )}
          <div className="w-full col-span-2">
            <div className="text-lg">Place of Registration</div>
          </div>
          {/* <div className="w-full">
            <Input.Text
              name="street"
              required
              label="Street"
              placeholder="Street"
              rules={{ required: 'Street is required' }}
            />
          </div> */}
          <div className="w-full">
            <Input.Text
              name="city"
              required
              label="City"
              placeholder="City"
              rules={{ required: 'City is required' }}
            />
          </div>
          <div className="w-full">
            <Input.Text
              name="zipCode"
              required
              label="Zip Code"
              placeholder="Zip Code"
              rules={{ required: 'Zip Code is required' }}
            />
          </div>
          <div className="w-full">
            <Input.Text
              name="state"
              required
              label="State"
              placeholder="State"
              rules={{ required: 'State is required' }}
            />
          </div>
          {business.shareholderType === shareholderType.Corporate && (
            <div className="w-full">
              <Select
                required
                name="country"
                label="Country of Business Residency"
                placeholder="Select Country"
                options={countriesOptions}
                rules={{ required: 'Country is required' }}
              />
            </div>
          )}
          {business.shareholderType === shareholderType.Individual && (
            <div className="w-full">
              <Select
                required
                name="country"
                label="Country of Citizenship"
                placeholder="Select Country"
                options={countriesOptions}
                rules={{ required: 'Country is required' }}
              />
            </div>
          )}
        </div>
        <div className="flex justify-end mt-5">
          {/* <button
            type="button"
            className="mr-2 btn btn-secondary"
            onClick={handleClose}
          >
            Close
          </button> */}
          <Submit className="btn btn-primary">Submit</Submit>
        </div>
      </Form>
    </ReusableModal>
  )
}

export default ShareholderModalEdit
