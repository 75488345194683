import React, { useEffect, useState } from 'react'
import { Link, NavLink, Outlet, useNavigate, useParams } from 'react-router-dom'
import {
  FaCopyright,
  FaAddressBook,
  FaEdit,
  FaHome,
  FaDownload,
  FaDollarSign,
  FaUser,
} from 'react-icons/fa'
import { useBusiness } from 'backend/useBusiness'
import { paymentButtonVisibility } from 'dataSource/constants'
import { getLinkByBusinessType } from 'utils/logic'
import { isEmpty } from 'utils/helpers'

const CompanyDetailsLayout = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [formData, setFormData] = useState({})

  const { getBusiness } = useBusiness()

  useEffect(() => {
    getBusiness(id).then((item) => setFormData(item))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div className="flex flex-col justify-center">
      <div className="mb-4">
        <h1 className="text-3xl mb-2">
          {formData?.businessFullName && (
            <span>{formData?.businessFullName}</span>
          )}
        </h1>
        {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
        <div className="text-xs">
          <div className="text-muted mb-2">{formData?.bicraFormatted}</div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-8 mt-5">
        <div className="block col-span-full md:col-span-3">
          <div className="rounded-lg text-white overflow-hidden border-gold-600 bg-black pb-2 mb-5 company-nav">
            <div className="rounded-b-none px-4 py-3 bg-gold-600">
              Company Information
            </div>
            {!isEmpty(formData) &&
              (!formData.agreed ? (
                <Link
                  to={getLinkByBusinessType(formData, 'business')}
                  className="flex items-center px-4 py-2 mt-1"
                >
                  <FaEdit />
                  <span className="ml-2">Edit</span>
                </Link>
              ) : (
                paymentButtonVisibility.includes(formData.status) && (
                  <Link
                    to={getLinkByBusinessType(formData, 'payment')}
                    className="flex items-center px-4 py-2 mt-1"
                  >
                    <FaDollarSign />
                    <span className="ml-2">Payment</span>
                  </Link>
                )
              ))}
            <NavLink
              to={`/company/${id}/`}
              className="flex items-center px-4 py-2 mt-1"
              end
            >
              <FaHome />
              <span className="ml-2">Dashboard</span>
            </NavLink>
            <NavLink
              to={`/company/${id}/contact-preferences`}
              className="flex items-center px-4 py-2 mt-1"
            >
              <FaAddressBook />
              <span className="ml-2">Address Preferences</span>
            </NavLink>
            <NavLink
              to={`/company/${id}/shareholders`}
              className="flex items-center px-4 py-2 mt-1"
            >
              <FaUser />
              <span className="ml-2">Share Holder Details</span>
            </NavLink>
            <NavLink
              to={`/company/${id}/beneficiaries`}
              className="flex items-center px-4 py-2 mt-1"
            >
              <FaUser />
              <span className="ml-2">Beneficiary Details</span>
            </NavLink>
            <NavLink
              to={`/company/${id}/certificates`}
              className="flex items-center px-4 py-2 mt-1"
            >
              <FaDownload />
              <span className="ml-2">Certificates</span>
            </NavLink>
            <NavLink
              to={`/company/${id}/payments`}
              className="flex items-center px-4 py-2 mt-1"
            >
              <FaDollarSign />
              <span className="ml-2">Payment History</span>
            </NavLink>
          </div>
          <div className="rounded-lg text-white overflow-hidden border-gold-600 bg-black pb-2">
            <div className="rounded-b-none px-4 py-3 bg-gold-600">
              Service Request
            </div>
            <Link
              to={`/good-standing?c=${formData.businessFullName}`}
              className="flex items-center px-4 py-2 mt-1"
            >
              <FaCopyright />
              <span className="ml-2">Certificate of Good Standing</span>
            </Link>
          </div>
        </div>
        <div className="block col-span-full md:col-span-9">
          <Outlet formData={formData} />
        </div>
      </div>
      <div className="flex justify-between mt-5">
        <button
          type="button"
          className="back"
          tabIndex="-1"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  )
}

export default CompanyDetailsLayout
