import { useRoutes } from 'react-router-dom'

import AuthRoutes from './AuthRoutes'
import PageRoutes from './PageRoutes'
import DashboardRoutes from './DashboardRoutes'
import CompanyRoutes from './CompanyRoutes'
import GoodStandingsRoutes from './GoodStandingsRoutes'
import PaymentRoutes from './PaymentRoutes'
import AccountRoutes from './AccountRoutes'
import AssetRoutes from './AssetRoutes'

// import RegistrationRoutes from './RegistrationRoutes'
import RegistrationTrustRoutes from './RegistrationTrustRoutes'
import RegistrationCorpRoutes from './RegistrationCorpRoutes'
import RegistrationFamilyRoutes from "./RegistrationFamilyRoutes"
import RegistrationBankRoutes from "./RegistrationBankRoutes"
import RegistrationInsuranceRoutes from "./RegistrationInsuranceRoutes"

const Routes = () => {
  return useRoutes([
    AuthRoutes,
    AccountRoutes,
    DashboardRoutes,
    AssetRoutes,
    CompanyRoutes,
    RegistrationTrustRoutes,
    RegistrationCorpRoutes,
    RegistrationFamilyRoutes,
    RegistrationBankRoutes,
    RegistrationInsuranceRoutes,
    // RegistrationRoutes,
    GoodStandingsRoutes,
    PaymentRoutes,
    PageRoutes,
  ])
}

export default Routes
