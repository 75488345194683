import { siteModules } from 'dataSource/constants'

const env = process.env.REACT_APP_ENV || 'uat'

const prodEnv = {
  websiteName: 'Blockchaintrust Pro',
  description: 'blockchaintrust.pro',
  apiUrl: process.env.REACT_APP_API_URL || 'https://api.blockchaintrust.pro',
  cdnUrl: 'https://s3.amazonaws.com/cdn.blockchaintrust.pro',
  contractExplorerUrl: 'https://polygonscan.com',
  openSeaUrl: 'https://opensea.io/assets/matic',
  businessContractAddress: '0x222bf22115bc7ecab8f7e5560bfa4369b63a8483',
  walletConnectProjectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
  baseUrl: process.env.REACT_APP_BASE_URL,
  resellerUrl:
    process.env.REACT_APP_RESELLER_URL ||
    'https://reseller.blockchaintrust.pro',
  adminUrl:
    process.env.REACT_APP_ADMIN_URL || 'https://admin.blockchaintrust.pro',
  env: 'prod',
}

const uatEnv = {
  websiteName: 'UAT Blockchaintrust Pro',
  description: 'blockchaintrust.pro',
  apiUrl: process.env.REACT_APP_API_URL || 'https://uat.blockchaintrust.pro',
  cdnUrl: 'https://s3.amazonaws.com/cdn.blockchaintrust.pro',
  contractExplorerUrl: 'https://amoy.polygonscan.com',
  openSeaUrl: 'https://testnets.opensea.io/assets/amoy',
  businessContractAddress: '0xe9839fe6548603f080d51529c273c1039a7e6c2e',
  walletConnectProjectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
  baseUrl: process.env.REACT_APP_BASE_URL,
  resellerUrl:
    process.env.REACT_APP_RESELLER_URL ||
    'https://uat-reseller.blockchaintrust.pro',
  adminUrl:
    process.env.REACT_APP_ADMIN_URL || 'https://uat-admin.blockchaintrust.pro',
  env: 'uat',
}

const siteModule = siteModules.Reseller // or Individual
const registeredAs = 'Agent' // or Individual

const config = env === 'prod' ? prodEnv : uatEnv

export { config, registeredAs, siteModule }
