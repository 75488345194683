// import { lazy } from 'react';

// project imports
import AuthGuard from './AuthGuard'
import SingleLayout from 'Layouts/SingleLayout'
import AddressBook from 'Pages/Reseller/AddressBook'
import Assets from 'Pages/Reseller/Assets'
import Companies from 'Pages/Reseller/Companies'
import CompanySearch from 'Pages/Reseller/CompanySearch'
import ContactUs from 'Pages/Reseller/ContactUs'
import Dashboard from 'Pages/Reseller/Dashboard'
import Orders from 'Pages/Reseller/Orders'
import OrderView from 'Pages/Reseller/Orders/View'
import Services from 'Pages/Reseller/Services'
import Shareholders from 'Pages/Reseller/Shareholders'
import StepShareAdd from 'Pages/Reseller/Shareholders/Add'
import ShareholdersEdit from 'Pages/Reseller/Shareholders/Edit'

import UpcomingBills from 'Pages/Reseller/UpcomingBills'

// ==============================|| PAGES ROUTING ||============================== //

const DashboardRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <SingleLayout />
    </AuthGuard>
  ),
  children: [
    {
      index: true,
      element: <Dashboard />,
    },
    {
      path: '/address',
      element: <AddressBook />,
    },
    { path: '/services', element: <Services /> },
    { path: '/contact', element: <ContactUs /> },
    { path: '/orders', element: <Orders /> },
    { path: '/orders/:orderId', element: <OrderView /> },
    { path: '/companies', element: <Companies /> },
    { path: '/shareholders', element: <Shareholders /> },
    { path: '/assets', element: <Assets /> },
    { path: '/shareholders/new', element: <StepShareAdd /> },
    { path: '/shareholders/:id', element: <ShareholdersEdit /> },

    { path: '/upcoming', element: <UpcomingBills /> },
    { path: '/name-search', element: <CompanySearch /> },
  ],
}

export default DashboardRoutes
