import React from 'react'
import { useSelector } from "react-redux"
import { Link } from 'react-router-dom'
import { FaKey, FaAddressCard, FaMoneyBill } from 'react-icons/fa'
import { selectAuth } from "features/authSlice"

const AccountSettings = () => {
  const { user } = useSelector(selectAuth)

  return (
    <div>
      <h1 className="text-3xl mb-4">Account Settings</h1>
      <section className="tracking-wider">
        <h3 className="text-gold-400 font-semibold text-2xl mb-2">
          Hey there, {user.firstName}
          {/* Hey there, Mr. John Doe */}
        </h3>
        <p>Your Account ID is {user.id}</p>
        <p>You are logged in with <b>{user.email}</b></p>
      </section>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-10">
        <Link to="password">
          <div className="flex space-x-4 bg-black p-4 rounded-md items-center h-full">
            <div className="p-4">
              <FaKey className="text-5xl text-gold-400" />
            </div>
            <div className="w-full text-center">
              <p className="text-xl font-semibold text-gold-400">
                Update Password
              </p>
              <p className="text-muted">Change your password</p>
            </div>
          </div>
        </Link>
        {/* <Link to="password">
          <div className="flex space-x-4 bg-black p-4 rounded-md items-center h-full">
            <div className="p-4">
              <FaUser className="text-5xl text-gold-400" />
            </div>
            <div className="w-full text-center">
              <p className="text-xl font-semibold text-gold-400">
                User Settings
              </p>
              <p className="text-muted">
                Manage Passwords and Login Settings
              </p>
            </div>
          </div>
        </Link> */}
        <Link to="address">
          <div className="flex space-x-4 bg-black p-4 rounded-md items-center h-full">
            <div className="p-4">
              <FaAddressCard className="text-5xl text-gold-400" />
            </div>
            <div className="w-full text-center">
              <p className="text-xl font-semibold text-gold-400">
                Account Address
              </p>
              <p className="text-muted">
                Update the contact address for this account.
              </p>
            </div>
          </div>
        </Link>
        {/* <div className="flex space-x-4 bg-black p-4 rounded-md items-center h-full">
          <div className="p-4">
            <FaDollarSign className="text-5xl text-gold-400" />
          </div>
          <div className="text-center">
            <p className="text-xl font-semibold text-gold-400">
              Payment Methods
            </p>
            <p className="text-muted">
              Add or delete credit and/or debit cards.
            </p>
          </div>
        </div> */}
        {/* <div className="flex space-x-4 bg-black p-4 rounded-md items-center h-full">
          <div className="p-4">
            <FaBlog className="text-5xl text-gold-400" />
          </div>
          <div className="text-center">
            <p className="text-xl font-semibold text-gold-400">
              Blog Preferences
            </p>
            <p className="text-muted">
              Manage your subscription to the DelawareInc blog.
            </p>
          </div>
        </div> */}
        <Link to="bill-preference">
          <div className="flex space-x-4 bg-black p-4 rounded-md">
            <div className="p-4">
              <FaMoneyBill className="text-5xl text-gold-400" />
            </div>
            <div className="w-full text-center">
              <p className="text-xl font-semibold text-gold-400">
                Billing Preferences
              </p>
              <p className="text-muted">
                Choose how bills are delivered for this account.
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default AccountSettings
