export const getBgColor = (status) => {
  switch (status) {
    case 'Success':
    case 'Confirmed':
      return 'bg-success'
    case 'Processing':
      return 'bg-info'
    case 'Pending':
      return 'bg-warning'
    case 'Failed':
    case 'Expired':
      return 'bg-danger'
    default:
      return 'bg-info'
  }
}

// export const getLabel = (label) => {
//   switch (label) {
//     case "Up to Date":
//       return "Up to Date"
//     case "Past Due":
//       return "Past Due"
//     case "Due Soon":
//       return "Due Soon"
//     default:
//       return "Up to Date"
//   }
// }

export const getBadge = (status) => {
  switch (status) {
    case 'Confirmed':
      return 'success'
    case 'Active':
      return 'success'
    case 'Success':
      return 'success'
    case 'Inactive':
      return 'secondary'
    case 'Awaiting':
      return 'warning'
    case 'Pending':
      return 'warning'
    case 'Blocked':
      return 'danger'
    case 'Failed':
      return 'danger'
    case 'Canceled':
      return 'danger'
    case 'Expired':
      return 'danger'
    case 'Disabled':
      return 'danger'
    default:
      return 'primary'
  }
}

export const getLabel = (status) => {
  switch (status) {
    case 'Awaiting':
      return 'Awaiting Payment'
    case 'Canceled':
      return 'Canceled'
    case 'Confirmed':
      return 'Confirmed'
    case 'Expired':
      return 'Expired'
    case 'Failed':
      return 'Failed'
    case 'Pending':
      return 'Pending'
    case 'Processing':
      return 'Processing'
    case 'Success':
      return 'Success'
    default:
      return 'NA'
  }
}

export function camelCaseToSentence(text) {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}
