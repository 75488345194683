import classNames from 'classnames'
import { useFormContext, Controller } from 'react-hook-form'
import ReactDatePicker from 'react-datepicker'
// import { useEffect, useState } from "react"

const DatePicker = (props) => {
  // const [defaultDate, setDefaultValue] = useState('')
  const {
    name = '',
    label = '',
    required = false,
    placeholder = '',
    defaultValue = '',
    className = '',
    rules,
    pickerOptions,
  } = props

  // useEffect(() => {
  //   setDefaultValue(defaultValue)
  //   return () => {
  //     setDefaultValue('')
  //   }
  // }, [defaultValue])
  // console.log('defaultValueDate', defaultDate)
  const {
    formState: { errors },
    control,
  } = useFormContext() // retrieve all hook methods

  return (
    <div className="mb-1">
      {label && <label className={classNames({ required })}>{label}</label>}
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({
          field: { onChange, onBlur, value, name, ref },
          // fieldState: { invalid, isTouched, isDirty, error },
          // formState,
        }) => (
          <>
            <ReactDatePicker
              {...pickerOptions}
              selected={(value && new Date(value)) || ''}
              className={classNames(className, 'tw-control', {
                invalid: errors[name]?.message,
              })}
              placeholderText={placeholder}
              onBlur={onBlur} // notify when input is touched
              onChange={onChange} // send value to hook form
              // onChange={(date, e) => onChange(date.toDateString())} // send value to hook form
              inputRef={ref}
            />
          </>
        )}
      ></Controller>
      {errors[name]?.message && (
        <div className="invalid-msg">{errors[name]?.message}</div>
      )}
    </div>
  )
}

export default DatePicker
