import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

const Form = ({ initialValues, onFinish, onFinishFailed, children, reset }) => {
  const methods = useForm()

  useEffect(() => {
    methods.reset(initialValues)

    return () => {
      methods.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, reset])

  const onValid = (data) => {
    console.log('onValid', data)
    onFinish && onFinish(data)
  }

  const onInvalid = (data) => {
    console.log('onInvalid', data)
    onFinishFailed && onFinishFailed(data)
  }
  // console.log('getv', methods.getValues())

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onValid, onInvalid)}>
        {children}
      </form>
    </FormProvider>
  )
}

export default Form
