// import { lazy } from 'react';

// project imports
import AuthGuard from './AuthGuard'
import SingleLayout from 'Layouts/SingleLayout'

import CompanyDetailsLayout from "Pages/Company/CompanyDetailsLayout"

import AddressPreference from 'Pages/Company/AddressPreference'
import CompanyDetails from "Pages/Company/CompanyDetails"
import Certificates from 'Pages/Company/Certificates'
import PaymentHistory from 'Pages/Company/CompanyPaymentHistory'
import ShareholderDetails from 'Pages/Company/ShareholderDetails'
import BeneficiaryDetails from 'Pages/Company/BeneficiaryDetails'

// ==============================|| PAGES ROUTING ||============================== //

const CompanyRoutes = {
  path: '/company',
  element: (
    <AuthGuard>
      <SingleLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: ':id',
      element: <CompanyDetailsLayout />,
      children: [
        { index: true, element: <CompanyDetails /> },
        { path: 'contact-preferences', element: <AddressPreference /> },
        { path: 'certificates', element: <Certificates /> },
        { path: 'shareholders', element: <ShareholderDetails /> },
        { path: 'beneficiaries', element: <BeneficiaryDetails /> },
        { path: 'payments', element: <PaymentHistory /> },
      ],
    },
  ],
}
export default CompanyRoutes
