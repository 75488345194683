import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FaUser } from 'react-icons/fa'

import { businessTypes, beneficiaryType } from 'dataSource/constants'
import { useBusiness } from 'backend/useBusiness'
import { selectBusiness } from 'features/businessSlice'
import { selectUi, uiActions } from 'features/uiSlice'
import { isEmpty } from 'utils/helpers'
import Placeholder from 'Components/Placeholder'

import BeneficiaryFamily from './BeneficiaryFamily'
import BeneficiaryCorporate from './BeneficiaryCorporate'
import BeneficiaryIndividual from './BeneficiaryIndividual'
import BeneficiaryModalSelect from './BeneficiaryModalSelect'
import BeneficiaryModalAdd from './BeneficiaryModalAdd'
import BeneficiaryModalEdit from './BeneficiaryModalEdit'
import BeneficiarySelect from './BeneficiarySelect'

const BeneficiaryDetails = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { reUsableModal } = useSelector(selectUi)
  const [selectedBusiness, setSelectedBusiness] = useState({})
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { business } = useSelector(selectBusiness)

  const { getBusiness } = useBusiness()

  useEffect(() => {
    getBusiness(id).then((item) => {
      let beneficiaryType = item?.beneficiaryType
      let beneficiaries =
        item?.beneficiaries === null ? [] : item?.beneficiaries
      let corporateBeneficiaries =
        item?.corporateBeneficiaries === null
          ? []
          : item?.corporateBeneficiaries
      if (
        item?.businessType === businessTypes.family &&
        item?.beneficiaryType === null
      ) {
        beneficiaryType = beneficiaryType.Individual
      }
      // beneficiaryType = null

      setSelectedBusiness({
        ...item,
        beneficiaryType,
        beneficiaries,
        corporateBeneficiaries,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, business])

  const selectType = () => {
    dispatch(uiActions.openReUsableModal({ key: 'select' }))
  }

  const add = (i) => {
    setSelectedIndex(i)
    dispatch(uiActions.openReUsableModal({ key: 'add' }))
  }

  const edit = (i) => {
    setSelectedIndex(i)
    dispatch(uiActions.openReUsableModal({ key: 'edit' }))
  }

  return (
    <div>
      {/* <pre>{JSON.stringify(selectedBusiness, null, 2)}</pre> */}
      {reUsableModal.key === 'edit' && (
        <BeneficiaryModalEdit
          business={selectedBusiness}
          id={selectedBusiness?.id}
          index={selectedIndex}
        />
      )}
      {reUsableModal.key === 'add' && (
        <BeneficiaryModalAdd
          business={selectedBusiness}
          id={selectedBusiness?.id}
          index={selectedIndex}
        />
      )}
      {reUsableModal.key === 'select' && (
        <BeneficiaryModalSelect
          business={selectedBusiness}
          id={selectedBusiness?.id}
        />
      )}
      {isEmpty(selectedBusiness) ? (
        <Placeholder
          icon={FaUser}
          text="Your Beneficiaries will display here"
        />
      ) : selectedBusiness?.businessType === businessTypes.family ? (
        <BeneficiaryFamily
          beneficiaries={selectedBusiness?.beneficiaries}
          beneficiaryCount={selectedBusiness?.beneficiaryCount}
          edit={edit}
          add={add}
        />
      ) : selectedBusiness?.beneficiaryType === beneficiaryType.Corporate ? (
        <BeneficiaryCorporate
          corporateBeneficiaries={selectedBusiness?.corporateBeneficiaries}
          edit={edit}
          add={add}
        />
      ) : selectedBusiness?.beneficiaryType === beneficiaryType.Individual ? (
        <BeneficiaryIndividual
          beneficiaries={selectedBusiness?.beneficiaries}
          beneficiaryCount={selectedBusiness?.beneficiaryCount}
          edit={edit}
          add={add}
        />
      ) : (
        <BeneficiarySelect selectType={selectType} />
      )}
    </div>
  )
}

export default BeneficiaryDetails
