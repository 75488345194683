import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FaUser } from 'react-icons/fa'

import { businessTypes, shareholderType } from 'dataSource/constants'
import { useBusiness } from 'backend/useBusiness'
import { selectBusiness } from 'features/businessSlice'
import { selectUi, uiActions } from 'features/uiSlice'
import { isEmpty } from 'utils/helpers'
import Placeholder from 'Components/Placeholder'

import ShareholderFamily from './ShareholderFamily'
import ShareholderCorporate from './ShareholderCorporate'
import ShareholderIndividual from './ShareholderIndividual'
import ShareholderModalSelect from './ShareholderModalSelect'
import ShareholderModalAdd from './ShareholderModalAdd'
import ShareholderModalEdit from './ShareholderModalEdit'
import ShareholderSelect from './ShareholderSelect'

const ShareholderDetails = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { reUsableModal } = useSelector(selectUi)
  const [selectedBusiness, setSelectedBusiness] = useState({})
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { business } = useSelector(selectBusiness)

  const { getBusiness } = useBusiness()

  useEffect(() => {
    getBusiness(id).then((item) => {
      let shareholderType = item?.shareholderType
      let shareholders = item?.shareholders === null ? [] : item?.shareholders
      let corporateShareholders =
        item?.corporateShareholders === null
          ? []
          : item?.corporateShareholders
      if (
        item?.businessType === businessTypes.family &&
        item?.shareholderType === null
      ) {
        shareholderType = shareholderType.Individual
      }
      // shareholderType = null

      setSelectedBusiness({
        ...item,
        shareholderType,
        shareholders,
        corporateShareholders,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, business])

  const selectType = () => {
    dispatch(uiActions.openReUsableModal({ key: 'select' }))
  }

  const add = (i) => {
    setSelectedIndex(i)
    dispatch(uiActions.openReUsableModal({ key: 'add' }))
  }

  const edit = (i) => {
    setSelectedIndex(i)
    dispatch(uiActions.openReUsableModal({ key: 'edit' }))
  }

  console.log('selectedBusiness', selectedBusiness)

  return (
    <div>
      {reUsableModal.key === 'edit' && (
        <ShareholderModalEdit
          business={selectedBusiness}
          id={selectedBusiness?.id}
          index={selectedIndex}
        />
      )}
      {reUsableModal.key === 'add' && (
        <ShareholderModalAdd
          business={selectedBusiness}
          id={selectedBusiness?.id}
          index={selectedIndex}
        />
      )}
      {reUsableModal.key === 'select' && (
        <ShareholderModalSelect
          business={selectedBusiness}
          id={selectedBusiness?.id}
        />
      )}
      {isEmpty(selectedBusiness) ? (
        <Placeholder
          icon={FaUser}
          text="Your Beneficiaries will display here"
        />
      ) : selectedBusiness?.businessType === businessTypes.family ? (
        <ShareholderFamily
          shareholders={selectedBusiness?.shareholders}
          shareholderCount={selectedBusiness?.shareholderCount}
          edit={edit}
          add={add}
        />
      ) : selectedBusiness?.shareholderType === shareholderType.Corporate ? (
        <ShareholderCorporate
          corporateShareholders={selectedBusiness?.corporateShareholders}
          edit={edit}
          add={add}
        />
      ) : selectedBusiness?.shareholderType === shareholderType.Individual ? (
        <ShareholderIndividual
          shareholders={selectedBusiness?.shareholders}
          shareholderCount={selectedBusiness?.shareholderCount}
          edit={edit}
          add={add}
        />
      ) : (
        <ShareholderSelect selectType={selectType} />
      )}
      {/* <pre>{JSON.stringify(selectedBusiness, null, 2)}</pre> */}
    </div>
  )
}

export default ShareholderDetails
