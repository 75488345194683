import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { FaAddressBook } from 'react-icons/fa'
import { FaSquare, FaCheckSquare } from 'react-icons/fa'
import { HiCog, HiRefresh } from 'react-icons/hi'

import { Form, Input, Select, Submit } from 'tui'
import Placeholder from 'Components/Placeholder'
import { usePaginate } from 'backend/usePaginate'
import AddressBillingModal from '../../Company/AddressBillingModal'
import AddressCommunicationModal from '../../Company/AddressCommunicationModal'
import { countryNameByIso2 } from 'dataSource/countries'
import {
  bankEntityEndings,
  btEntityEndings,
  corpEntityEndings,
  familyEntityEndings,
} from 'dataSource/options'

const endpoint = 'business/my-address'
const AddressBook = () => {
  const [filtered, setFiltered] = useState(false)
  const [addressType, setAddressType] = useState('')
  const [ids, setIds] = useState([])
  const { hasMore, items, loadMore, filter, loading } = usePaginate(endpoint, {
    limit: 100,
  })

  // useEffect(() => {
  //   if (addressType === '') {
  //     myAddress({ limit: 50 })
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [addressType])

  const onSubmit = (data) => {
    filter(data).then(() => setFiltered(true))
    console.log('data', data)
  }

  const clearFilter = () => {
    filter({}).then(() => setFiltered(false))
  }

  const closeModal = () => {
    filter({}).then(() => setFiltered(false))
    setAddressType('')
  }

  const toggleAddress = (id, type) => {
    if (ids.length > 0 && ids.find((i) => i.type !== type)) {
      alert('You can only select one type of address per company')
      return
    }
    if (ids.find((i) => i.id === id)) {
      setIds(ids.filter((i) => i.id !== id))
    } else {
      setIds([...ids, { id, type }])
    }
    // setAddress(address)
  }
  // console.log('ids', ids)

  return (
    <div>
      <h1 className="text-3xl mb-4">Address Book</h1>
      {/* <p>Tool for changing addresses in bulk. Opening this tool in multiple tabs or windows will cause undesirable results.</p> */}
      <Form onFinish={onSubmit}>
        <div className="md:flex justify-between items-start">
          <div className="flex space-x-4">
            <div>
              <Input.Text
                name="businessName"
                placeholder="Search by Company Name"
              />
            </div>
            <div>
              <Select
                name="entityEnding"
                // label="Entity Ending"
                placeholder="Select Entity Ending"
                options={[].concat(
                  btEntityEndings,
                  corpEntityEndings,
                  familyEntityEndings,
                  bankEntityEndings,
                )}
              />
            </div>
          </div>
          <div className="flex items-center">
            <Submit className="bg-primary flex items-center p-2 px-4 mr-2">
              <HiCog className="mr-2" />
              <span>Apply Filter</span>
            </Submit>
            {filtered && (
              <button
                type="reset"
                className="flex items-center bg-input p-2 px-4"
                onClick={clearFilter}
              >
                <HiRefresh className="mr-2" />
                Clear Filter
              </button>
            )}
          </div>
        </div>
      </Form>
      <section className="mt-8">
        <div className="flex justify-end">
          {/* {ids.length === 0 ?
            <button
              type="button"
              className="bg-input p-2 px-4 mr-2"
            >
              Select Address
            </button>
            :
            <button
              className="bg-primary p-2 px-4"
              disabled={!ids.length}
            >
              Edit {ids.length > 0 && ids[0].type} Address
            </button>
          } */}
          {ids.length > 0 && (
            <button
              className="btn btn-primary"
              onClick={() => setAddressType(ids[0].type)}
            >
              Edit {ids.length} {ids[0].type} Address
            </button>
          )}
        </div>
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            loading && (
              <h4 style={{ textAlign: 'center' }} key="loader">
                Loading...
              </h4>
            )
          }
        >
          <>
            {loading === false && items?.length === 0 ? (
              <Placeholder icon={FaAddressBook} text="No address found" />
            ) : (
              items.map((item) => (
                <div
                  className="bg-black my-4 rounded-md overflow-hidden"
                  key={item.id}
                >
                  <div className="bg-gold-600 p-4">
                    <div className="text-white">{item.businessFullName}</div>
                  </div>
                  <div className="grid md:grid-cols-2 gap-5">
                    <div className="bg-black text-sm text-muted p-4 rounded-lg">
                      <div className="flex justify-between mb-4">
                        <div className="text-lg">Communications Contact</div>
                        {item?.communicationAddress?.addressType && (
                          <button
                            className="m-0 p-0 text-sm text-gold-400 flex items-center"
                            onClick={() =>
                              toggleAddress(item.id, 'Communications')
                            }
                          >
                            {ids.find(
                              (i) =>
                                i.id === item.id && i.type === 'Communications',
                            ) ? (
                              <FaCheckSquare className="mr-1 inline-block" />
                            ) : (
                              <FaSquare className="mr-1 inline-block" />
                            )}
                          </button>
                        )}
                      </div>
                      {item?.communicationAddress?.addressType ? (
                        <>
                          <div className="mb-2">
                            <div className="mr-1">
                              {item?.communicationAddress?.prefix && (
                                <span className="pr-1">
                                  {item?.communicationAddress?.prefix}
                                </span>
                              )}
                              {item?.communicationAddress?.firstName && (
                                <span className="pr-1">
                                  {item?.communicationAddress?.firstName}
                                </span>
                              )}
                              {item?.communicationAddress?.lastName && (
                                <span className="pr-1">
                                  {item?.communicationAddress?.lastName}
                                </span>
                              )}
                              {item?.communicationAddress?.suffix && (
                                <span className="pr-1">
                                  {item?.communicationAddress?.suffix}
                                </span>
                              )}
                            </div>
                            <div className="mr-1">
                              {item?.communicationAddress?.addressLine1}
                            </div>
                            <div className="mr-1">
                              {item?.communicationAddress?.addressLine2}
                            </div>
                            <div className="mr-1">
                              {item?.communicationAddress?.city}
                            </div>
                            <span className="mr-1">
                              {item?.communicationAddress?.state}
                            </span>
                            <span className="mr-1">
                              {item?.communicationAddress?.zipCode}
                            </span>
                            <div className="mr-1">
                              {countryNameByIso2(
                                item?.communicationAddress?.country,
                              )}
                            </div>
                          </div>
                          {item?.communicationAddress?.email && (
                            <div className="my-3">
                              {/* <span className="text-muted">Phone Number: </span> */}
                              <b>{item?.communicationAddress?.phone}</b>
                            </div>
                          )}
                          {item?.communicationAddress?.email && (
                            <div className="my-3">
                              {/* <span className="text-muted">Email: </span> */}
                              <b>{item?.communicationAddress?.email}</b>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="flex justify-center items-center p-10">
                          <div>No Address</div>
                        </div>
                      )}
                    </div>

                    <div className="bg-black text-sm text-muted p-4 rounded-lg">
                      <div className="flex justify-between mb-4">
                        <div className="text-lg">
                          Annual Billing Information
                        </div>
                        {item?.billingAddress?.addressType && (
                          // <button className="m-0 p-0 text-sm text-gold-400 flex items-center" onClick={() => toggleAddress('Billing')}>
                          //   <FaEdit className="mr-1 inline-block" /> Edit
                          // </button>
                          <button
                            className="m-0 p-0 text-sm text-gold-400 flex items-center"
                            onClick={() => toggleAddress(item.id, 'Billing')}
                          >
                            {ids.find(
                              (i) => i.id === item.id && i.type === 'Billing',
                            ) ? (
                              <FaCheckSquare className="mr-1 inline-block" />
                            ) : (
                              <FaSquare className="mr-1 inline-block" />
                            )}
                          </button>
                        )}
                      </div>
                      {item?.billingAddress?.addressType ? (
                        <>
                          <div className="mb-2">
                            <div className="mr-1">
                              {item?.billingAddress?.prefix && (
                                <span className="pr-1">
                                  {item?.billingAddress?.prefix}
                                </span>
                              )}
                              {item?.billingAddress?.firstName && (
                                <span className="pr-1">
                                  {item?.billingAddress?.firstName}
                                </span>
                              )}
                              {item?.billingAddress?.lastName && (
                                <span className="pr-1">
                                  {item?.billingAddress?.lastName}
                                </span>
                              )}
                              {item?.billingAddress?.suffix && (
                                <span className="pr-1">
                                  {item?.billingAddress?.suffix}
                                </span>
                              )}
                            </div>
                            <div className="mr-1">
                              {item?.billingAddress?.addressLine1}
                            </div>
                            <div className="mr-1">
                              {item?.billingAddress?.addressLine2}
                            </div>
                            <div className="mr-1">
                              {item?.billingAddress?.city}
                            </div>
                            <span className="mr-1">
                              {item?.billingAddress?.state}
                            </span>
                            <span className="mr-1">
                              {item?.billingAddress?.zipCode}
                            </span>
                            <div className="mr-1">
                              {countryNameByIso2(item?.billingAddress?.country)}
                            </div>
                          </div>
                          {item?.billingAddress?.email && (
                            <div className="my-3">
                              <b>{item?.billingAddress?.phone}</b>
                            </div>
                          )}
                          {item?.billingAddress?.email && (
                            <div className="my-3">
                              <b>{item?.billingAddress?.email}</b>
                            </div>
                          )}
                          {item?.billingAddress?.email2 && (
                            <div className="my-3">
                              <b>{item?.billingAddress?.email2}</b>
                            </div>
                          )}
                          {/* {item?.billingAddress?.email3 &&
                          <div className="my-3">
                            <b>{item?.billingAddress?.email3}</b>
                          </div>
                        } */}
                          {item?.billingAddress?.noticeFrequency && (
                            <div className="my-3">
                              <div className="font-semibold">
                                Registered Agent Notification Frequency:
                              </div>
                              <b>{item?.billingAddress?.noticeFrequency}</b>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="flex justify-center items-center p-10">
                          <div>No Address</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <p className="text-muted">
                  Update 30 Companies Using These Address
                </p> */}
                </div>
              ))
            )}
          </>
        </InfiniteScroll>
      </section>
      <AddressCommunicationModal
        isOpen={addressType === 'Communications'}
        closeModal={closeModal}
        addressType="Communications"
        // address={formData?.communicationAddress}
        ids={ids.map((i) => i.id)}
      />
      <AddressBillingModal
        isOpen={addressType === 'Billing'}
        closeModal={closeModal}
        addressType="Billing"
        // address={formData?.billingAddress}
        ids={ids.map((i) => i.id)}
      />
    </div>
  )
}

export default AddressBook
