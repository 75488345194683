import { createSlice } from '@reduxjs/toolkit'
import { status } from './status'

const initialState = {
  beneficiary: {
    status: status.loading,
    items: [],
  },
}

const uniqueItems = (items) => {
  var lookup = []
  var result = []

  for (const item of items) {
    var id = item?.id

    if (!id) {
      return
    }
    if (!lookup.includes(id)) {
      lookup.push(id)
      result.push(item)
    }
  }
  return result
}

export const beneficiarySlice = createSlice({
  name: 'beneficiary',
  initialState,
  reducers: {
    loading(state) {
      state.beneficiary.status = status.loading
    },
    error(state) {
      state.beneficiary.status = status.idle
    },
    loadIds(state, action) {
      state.beneficiary.ids = action.payload.ids || []
    },
    loaded(state, action) {
      state.beneficiary.items = action.payload.items
      state.beneficiary.status = action.payload.status
    },
    add(state, action) {
      state.beneficiary.items.push(action.payload.item)
    },
    append(state, action) {
      const items = state.beneficiary.items.concat(action.payload.item)
      state.beneficiary.items = uniqueItems(items)
    },
    update(state, action) {
      state.beneficiary.items = state.beneficiary.items.map((item) => {
        if (item.id === action.payload.item.id) {
          return { ...item, ...action.payload.item }
        }
        return item
      })
    },
    deleteById: (state, { payload }) => {
      state.beneficiary.items = state.beneficiary.items.filter(
        (cur) => cur.id !== payload.id,
      )
    },
    clear() {
      return { ...initialState }
    },
  },
})

export const beneficiaryActions = beneficiarySlice.actions
export default beneficiarySlice.reducer

export const selectBeneficiary = (state) => state.beneficiary
