export const countries = [
  { name: 'Afghanistan', iso2: 'AF', citizenship: '' },
  { name: 'Åland Islands', iso2: 'AX', citizenship: '' },
  { name: 'Albania', iso2: 'AL', citizenship: '' },
  { name: 'Algeria', iso2: 'DZ', citizenship: '' },
  { name: 'American Samoa', iso2: 'AS', citizenship: '' },
  { name: 'Andorra', iso2: 'AD', citizenship: '' },
  { name: 'Angola', iso2: 'AO', citizenship: '' },
  { name: 'Anguilla', iso2: 'AI', citizenship: '' },
  { name: 'Antarctica', iso2: 'AQ', citizenship: '' },
  { name: 'Antigua and Barbuda', iso2: 'AG', citizenship: '' },
  { name: 'Argentina', iso2: 'AR', citizenship: '' },
  { name: 'Armenia', iso2: 'AM', citizenship: '' },
  { name: 'Aruba', iso2: 'AW', citizenship: '' },
  { name: 'Australia', iso2: 'AU', citizenship: '' },
  { name: 'Austria', iso2: 'AT', citizenship: '' },
  { name: 'Azerbaijan', iso2: 'AZ', citizenship: '' },
  { name: 'Bahamas', iso2: 'BS', citizenship: '' },
  { name: 'Bahrain', iso2: 'BH', citizenship: '' },
  { name: 'Bangladesh', iso2: 'BD', citizenship: '' },
  { name: 'Barbados', iso2: 'BB', citizenship: '' },
  { name: 'Belarus', iso2: 'BY', citizenship: '' },
  { name: 'Belgium', iso2: 'BE', citizenship: '' },
  { name: 'Belize', iso2: 'BZ', citizenship: '' },
  { name: 'Benin', iso2: 'BJ', citizenship: '' },
  { name: 'Bermuda', iso2: 'BM', citizenship: '' },
  { name: 'Bhutan', iso2: 'BT', citizenship: '' },
  { name: 'Bolivia', iso2: 'BO', citizenship: '' },
  { name: 'Bosnia and Herzegovina', iso2: 'BA', citizenship: '' },
  { name: 'Botswana', iso2: 'BW', citizenship: '' },
  { name: 'Bouvet Island', iso2: 'BV', citizenship: '' },
  { name: 'Brazil', iso2: 'BR', citizenship: '' },
  { name: 'British Indian Ocean Territory', iso2: 'IO', citizenship: '' },
  { name: 'Brunei Darussalam', iso2: 'BN', citizenship: '' },
  { name: 'Bulgaria', iso2: 'BG', citizenship: '' },
  { name: 'Burkina Faso', iso2: 'BF', citizenship: '' },
  { name: 'Burundi', iso2: 'BI', citizenship: '' },
  { name: 'Cambodia', iso2: 'KH', citizenship: '' },
  { name: 'Cameroon', iso2: 'CM', citizenship: '' },
  { name: 'Canada', iso2: 'CA', citizenship: '' },
  { name: 'Cape Verde', iso2: 'CV', citizenship: '' },
  { name: 'Cayman Islands', iso2: 'KY', citizenship: '' },
  { name: 'Central African Republic', iso2: 'CF', citizenship: '' },
  { name: 'Chad', iso2: 'TD', citizenship: '' },
  { name: 'Chile', iso2: 'CL', citizenship: '' },
  { name: 'China', iso2: 'CN', citizenship: '' },
  { name: 'Christmas Island', iso2: 'CX', citizenship: '' },
  { name: 'Cocos (Keeling) Islands', iso2: 'CC', citizenship: '' },
  { name: 'Colombia', iso2: 'CO', citizenship: '' },
  { name: 'Comoros', iso2: 'KM', citizenship: '' },
  { name: 'Congo', iso2: 'CG', citizenship: '' },
  {
    name: 'Congo, The Democratic Republic of the',
    iso2: 'CD',
    citizenship: '',
  },
  { name: 'Cook Islands', iso2: 'CK', citizenship: '' },
  { name: 'Costa Rica', iso2: 'CR', citizenship: '' },
  { name: "Cote D'Ivoire", iso2: 'CI', citizenship: '' },
  { name: 'Croatia', iso2: 'HR', citizenship: '' },
  { name: 'Cuba', iso2: 'CU', citizenship: '' },
  { name: 'Cyprus', iso2: 'CY', citizenship: '' },
  { name: 'Czech Republic', iso2: 'CZ', citizenship: '' },
  { name: 'Denmark', iso2: 'DK', citizenship: '' },
  { name: 'Djibouti', iso2: 'DJ', citizenship: '' },
  { name: 'Dominica', iso2: 'DM', citizenship: '' },
  { name: 'Dominican Republic', iso2: 'DO', citizenship: '' },
  { name: 'Ecuador', iso2: 'EC', citizenship: '' },
  { name: 'Egypt', iso2: 'EG', citizenship: '' },
  { name: 'El Salvador', iso2: 'SV', citizenship: '' },
  { name: 'Equatorial Guinea', iso2: 'GQ', citizenship: '' },
  { name: 'Eritrea', iso2: 'ER', citizenship: '' },
  { name: 'Estonia', iso2: 'EE', citizenship: '' },
  { name: 'Ethiopia', iso2: 'ET', citizenship: '' },
  { name: 'Falkland Islands (Malvinas)', iso2: 'FK', citizenship: '' },
  { name: 'Faroe Islands', iso2: 'FO', citizenship: '' },
  { name: 'Fiji', iso2: 'FJ', citizenship: '' },
  { name: 'Finland', iso2: 'FI', citizenship: '' },
  { name: 'France', iso2: 'FR', citizenship: '' },
  { name: 'French Guiana', iso2: 'GF', citizenship: '' },
  { name: 'French Polynesia', iso2: 'PF', citizenship: '' },
  { name: 'French Southern Territories', iso2: 'TF', citizenship: '' },
  { name: 'Gabon', iso2: 'GA', citizenship: '' },
  { name: 'Gambia', iso2: 'GM', citizenship: '' },
  { name: 'Georgia', iso2: 'GE', citizenship: '' },
  { name: 'Germany', iso2: 'DE', citizenship: '' },
  { name: 'Ghana', iso2: 'GH', citizenship: '' },
  { name: 'Gibraltar', iso2: 'GI', citizenship: '' },
  { name: 'Greece', iso2: 'GR', citizenship: '' },
  { name: 'Greenland', iso2: 'GL', citizenship: '' },
  { name: 'Grenada', iso2: 'GD', citizenship: '' },
  { name: 'Guadeloupe', iso2: 'GP', citizenship: '' },
  { name: 'Guam', iso2: 'GU', citizenship: '' },
  { name: 'Guatemala', iso2: 'GT', citizenship: '' },
  { name: 'Guernsey', iso2: 'GG', citizenship: '' },
  { name: 'Guinea', iso2: 'GN', citizenship: '' },
  { name: 'Guinea-Bissau', iso2: 'GW', citizenship: '' },
  { name: 'Guyana', iso2: 'GY', citizenship: '' },
  { name: 'Haiti', iso2: 'HT', citizenship: '' },
  { name: 'Heard Island and Mcdonald Islands', iso2: 'HM', citizenship: '' },
  { name: 'Holy See (Vatican City State)', iso2: 'VA', citizenship: '' },
  { name: 'Honduras', iso2: 'HN', citizenship: '' },
  { name: 'Hong Kong', iso2: 'HK', citizenship: '' },
  { name: 'Hungary', iso2: 'HU', citizenship: '' },
  { name: 'Iceland', iso2: 'IS', citizenship: '' },
  { name: 'India', iso2: 'IN', citizenship: 'Indian' },
  { name: 'Indonesia', iso2: 'ID', citizenship: '' },
  { name: 'Iran, Islamic Republic Of', iso2: 'IR', citizenship: '' },
  { name: 'Iraq', iso2: 'IQ', citizenship: '' },
  { name: 'Ireland', iso2: 'IE', citizenship: '' },
  { name: 'Isle of Man', iso2: 'IM', citizenship: '' },
  { name: 'Israel', iso2: 'IL', citizenship: '' },
  { name: 'Italy', iso2: 'IT', citizenship: '' },
  { name: 'Jamaica', iso2: 'JM', citizenship: '' },
  { name: 'Japan', iso2: 'JP', citizenship: '' },
  { name: 'Jersey', iso2: 'JE', citizenship: '' },
  { name: 'Jordan', iso2: 'JO', citizenship: '' },
  { name: 'Kazakhstan', iso2: 'KZ', citizenship: '' },
  { name: 'Kenya', iso2: 'KE', citizenship: '' },
  { name: 'Kiribati', iso2: 'KI', citizenship: '' },
  {
    name: "Korea, Democratic People'S Republic of",
    iso2: 'KP',
    citizenship: '',
  },
  { name: 'Korea, Republic of', iso2: 'KR', citizenship: '' },
  { name: 'Kuwait', iso2: 'KW', citizenship: '' },
  { name: 'Kyrgyzstan', iso2: 'KG', citizenship: '' },
  { name: "Lao People'S Democratic Republic", iso2: 'LA', citizenship: '' },
  { name: 'Latvia', iso2: 'LV', citizenship: '' },
  { name: 'Lebanon', iso2: 'LB', citizenship: '' },
  { name: 'Lesotho', iso2: 'LS', citizenship: '' },
  { name: 'Liberia', iso2: 'LR', citizenship: '' },
  { name: 'Libyan Arab Jamahiriya', iso2: 'LY', citizenship: '' },
  { name: 'Liechtenstein', iso2: 'LI', citizenship: '' },
  { name: 'Lithuania', iso2: 'LT', citizenship: '' },
  { name: 'Luxembourg', iso2: 'LU', citizenship: '' },
  { name: 'Macao', iso2: 'MO', citizenship: '' },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    iso2: 'MK',
    citizenship: '',
  },
  { name: 'Madagascar', iso2: 'MG', citizenship: '' },
  { name: 'Malawi', iso2: 'MW', citizenship: '' },
  { name: 'Malaysia', iso2: 'MY', citizenship: '' },
  { name: 'Maldives', iso2: 'MV', citizenship: '' },
  { name: 'Mali', iso2: 'ML', citizenship: '' },
  { name: 'Malta', iso2: 'MT', citizenship: '' },
  { name: 'Marshall Islands', iso2: 'MH', citizenship: '' },
  { name: 'Martinique', iso2: 'MQ', citizenship: '' },
  { name: 'Mauritania', iso2: 'MR', citizenship: '' },
  { name: 'Mauritius', iso2: 'MU', citizenship: '' },
  { name: 'Mayotte', iso2: 'YT', citizenship: '' },
  { name: 'Mexico', iso2: 'MX', citizenship: '' },
  { name: 'Micronesia, Federated States of', iso2: 'FM', citizenship: '' },
  { name: 'Moldova, Republic of', iso2: 'MD', citizenship: '' },
  { name: 'Monaco', iso2: 'MC', citizenship: '' },
  { name: 'Mongolia', iso2: 'MN', citizenship: '' },
  { name: 'Montserrat', iso2: 'MS', citizenship: '' },
  { name: 'Morocco', iso2: 'MA', citizenship: '' },
  { name: 'Mozambique', iso2: 'MZ', citizenship: '' },
  { name: 'Myanmar', iso2: 'MM', citizenship: '' },
  { name: 'Namibia', iso2: 'NA', citizenship: '' },
  { name: 'Nauru', iso2: 'NR', citizenship: '' },
  { name: 'Nepal', iso2: 'NP', citizenship: '' },
  { name: 'Netherlands', iso2: 'NL', citizenship: '' },
  { name: 'Netherlands Antilles', iso2: 'AN', citizenship: '' },
  { name: 'New Caledonia', iso2: 'NC', citizenship: '' },
  { name: 'New Zealand', iso2: 'NZ', citizenship: '' },
  { name: 'Nicaragua', iso2: 'NI', citizenship: '' },
  { name: 'Niger', iso2: 'NE', citizenship: '' },
  { name: 'Nigeria', iso2: 'NG', citizenship: '' },
  { name: 'Niue', iso2: 'NU', citizenship: '' },
  { name: 'Norfolk Island', iso2: 'NF', citizenship: '' },
  { name: 'Northern Mariana Islands', iso2: 'MP', citizenship: '' },
  { name: 'Norway', iso2: 'NO', citizenship: '' },
  { name: 'Oman', iso2: 'OM', citizenship: '' },
  { name: 'Pakistan', iso2: 'PK', citizenship: '' },
  { name: 'Palau', iso2: 'PW', citizenship: '' },
  { name: 'Palestinian Territory, Occupied', iso2: 'PS', citizenship: '' },
  { name: 'Panama', iso2: 'PA', citizenship: '' },
  { name: 'Papua New Guinea', iso2: 'PG', citizenship: '' },
  { name: 'Paraguay', iso2: 'PY', citizenship: '' },
  { name: 'Peru', iso2: 'PE', citizenship: '' },
  { name: 'Philippines', iso2: 'PH', citizenship: '' },
  { name: 'Pitcairn', iso2: 'PN', citizenship: '' },
  { name: 'Poland', iso2: 'PL', citizenship: '' },
  { name: 'Portugal', iso2: 'PT', citizenship: '' },
  { name: 'Puerto Rico', iso2: 'PR', citizenship: '' },
  { name: 'Qatar', iso2: 'QA', citizenship: '' },
  { name: 'Reunion', iso2: 'RE', citizenship: '' },
  { name: 'Romania', iso2: 'RO', citizenship: '' },
  { name: 'Russian Federation', iso2: 'RU', citizenship: '' },
  { name: 'RWANDA', iso2: 'RW', citizenship: '' },
  { name: 'Saint Helena', iso2: 'SH', citizenship: '' },
  { name: 'Saint Kitts and Nevis', iso2: 'KN', citizenship: '' },
  { name: 'Saint Lucia', iso2: 'LC', citizenship: '' },
  { name: 'Saint Pierre and Miquelon', iso2: 'PM', citizenship: '' },
  { name: 'Saint Vincent and the Grenadines', iso2: 'VC', citizenship: '' },
  { name: 'Samoa', iso2: 'WS', citizenship: '' },
  { name: 'San Marino', iso2: 'SM', citizenship: '' },
  { name: 'Sao Tome and Principe', iso2: 'ST', citizenship: '' },
  { name: 'Saudi Arabia', iso2: 'SA', citizenship: '' },
  { name: 'Senegal', iso2: 'SN', citizenship: '' },
  { name: 'Serbia and Montenegro', iso2: 'CS', citizenship: '' },
  { name: 'Seychelles', iso2: 'SC', citizenship: '' },
  { name: 'Sierra Leone', iso2: 'SL', citizenship: '' },
  { name: 'Singapore', iso2: 'SG', citizenship: '' },
  { name: 'Slovakia', iso2: 'SK', citizenship: '' },
  { name: 'Slovenia', iso2: 'SI', citizenship: '' },
  { name: 'Solomon Islands', iso2: 'SB', citizenship: '' },
  { name: 'Somalia', iso2: 'SO', citizenship: '' },
  { name: 'South Africa', iso2: 'ZA', citizenship: '' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    iso2: 'GS',
    citizenship: '',
  },
  { name: 'Spain', iso2: 'ES', citizenship: '' },
  { name: 'Sri Lanka', iso2: 'LK', citizenship: '' },
  { name: 'Sudan', iso2: 'SD', citizenship: '' },
  { name: 'Suriname', iso2: 'SR', citizenship: '' },
  { name: 'Svalbard and Jan Mayen', iso2: 'SJ', citizenship: '' },
  { name: 'Swaziland', iso2: 'SZ', citizenship: '' },
  { name: 'Sweden', iso2: 'SE', citizenship: '' },
  { name: 'Switzerland', iso2: 'CH', citizenship: '' },
  { name: 'Syrian Arab Republic', iso2: 'SY', citizenship: '' },
  { name: 'Taiwan, Province of China', iso2: 'TW', citizenship: '' },
  { name: 'Tajikistan', iso2: 'TJ', citizenship: '' },
  { name: 'Tanzania, United Republic of', iso2: 'TZ', citizenship: '' },
  { name: 'Thailand', iso2: 'TH', citizenship: '' },
  { name: 'Timor-Leste', iso2: 'TL', citizenship: '' },
  { name: 'Togo', iso2: 'TG', citizenship: '' },
  { name: 'Tokelau', iso2: 'TK', citizenship: '' },
  { name: 'Tonga', iso2: 'TO', citizenship: '' },
  { name: 'Trinidad and Tobago', iso2: 'TT', citizenship: '' },
  { name: 'Tunisia', iso2: 'TN', citizenship: '' },
  { name: 'Turkey', iso2: 'TR', citizenship: '' },
  { name: 'Turkmenistan', iso2: 'TM', citizenship: '' },
  { name: 'Turks and Caicos Islands', iso2: 'TC', citizenship: '' },
  { name: 'Tuvalu', iso2: 'TV', citizenship: '' },
  { name: 'Uganda', iso2: 'UG', citizenship: '' },
  { name: 'Ukraine', iso2: 'UA', citizenship: '' },
  { name: 'United Arab Emirates', iso2: 'AE', citizenship: '' },
  { name: 'United Kingdom', iso2: 'GB', citizenship: '' },
  { name: 'United States', iso2: 'US', citizenship: '' },
  { name: 'United States Minor Outlying Islands', iso2: 'UM', citizenship: '' },
  { name: 'Uruguay', iso2: 'UY', citizenship: '' },
  { name: 'Uzbekistan', iso2: 'UZ', citizenship: '' },
  { name: 'Vanuatu', iso2: 'VU', citizenship: '' },
  { name: 'Venezuela', iso2: 'VE', citizenship: '' },
  { name: 'Viet Nam', iso2: 'VN', citizenship: '' },
  { name: 'Virgin Islands, British', iso2: 'VG', citizenship: '' },
  { name: 'Virgin Islands, U.S.', iso2: 'VI', citizenship: '' },
  { name: 'Wallis and Futuna', iso2: 'WF', citizenship: '' },
  { name: 'Western Sahara', iso2: 'EH', citizenship: '' },
  { name: 'Yemen', iso2: 'YE', citizenship: '' },
  { name: 'Zambia', iso2: 'ZM', citizenship: '' },
  { name: 'Zimbabwe', iso2: 'ZW' },
]

export const countriesOptions = countries.map((country) => ({
  label: country.name,
  value: country.iso2,
}))
// console.log(countriesOptions)

export const countryNameByIso2 = (iso2) => {
  return iso2 ? countries.find((country) => country.iso2 === iso2).name : ''
}
