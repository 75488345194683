import React from 'react'
import { Outlet } from 'react-router-dom'
import Nav from './Nav'

const SingleLayout = () => {
  return (
    <div className="min-h-[100vh]">
      <Nav />
      <div className="md:w-3/4 bg-dark-900 md:mt-10 mx-auto p-8 flex flex-col justify-center md:mb-24">
        <Outlet />
      </div>
    </div>
  )
}

export default SingleLayout
