import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Submit } from 'tui'
import { useAuth } from 'backend/useAuth'
import { useSearchParam } from 'react-use'
import { useDispatch } from 'react-redux'
import { businessActions } from 'features/businessSlice'
import { orderActions } from 'features/ordersSlice'
import { shareholderActions } from 'features/shareholderSlice'
import { eCommerceActions } from 'features/eCommerceSlice'
import { authActions } from "features/authSlice"

// http://localhost:3000/auth/login-as?email=uat-admin@blockchaintrust.pro&user-email=vicky.agravat@gmail.com
const LoginAs = () => {
  const dispatch = useDispatch()
  const { removeToken, loginAs } = useAuth()
  const navigate = useNavigate()
  const email = useSearchParam('email')
  const userEmail = useSearchParam('user-email')

  useEffect(() => {
    dispatch(authActions.logout())
    dispatch(businessActions.clear())
    dispatch(orderActions.clear())
    dispatch(shareholderActions.clear())
    dispatch(eCommerceActions.clear())
    removeToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (data) => {
    console.log('data', data)
    const userMail = data.loginAs || userEmail
    loginAs(data.email, data.password, userMail).then((user) => {
      if (user) {
        console.log('user', user)
      }
      navigate('/')
    })
  }

  return (
    <div className="md:w-1/3 bg-dark-900 mx-auto p-8 flex flex-col justify-center">
      <div className="text-center">
        <h2 className="text-4xl mb-3">Login as User</h2>
        <p className="text-sm">
          You will login as <b>{userEmail}</b>
        </p>
      </div>
      <Form
        onFinish={onSubmit}
        initialValues={{
          email: email,
        }}
      >
        <div className="mt-8">
          <div className="mb-2">
            <Input.Email
              name="email"
              label="Admin Email"
              placeholder="Email"
              rules={{ required: 'Email is required' }}
            />
          </div>
          <div className="mb-2">
            <Input.Password
              name="password"
              label="Admin Password"
              placeholder="Reverify with your admin Password"
              rules={{ required: 'Password is required' }}
              autoComplete="current-password"
              autoFocus
            />
          </div>
          <div className="flex justify-between items-center mt-8">
            <Submit className="btn-primary py-2 w-full">Login As User</Submit>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default LoginAs
