import { createSlice } from '@reduxjs/toolkit'
import { status } from './status'

const uniqueItems = (items) => {
  var lookup = []
  var result = []

  for (const item of items) {
    var id = item?.id

    if (!id) {
      return
    }
    if (!lookup.includes(id)) {
      lookup.push(id)
      result.push(item)
    }
  }
  return result
}

const initialState = {
  formData: {
    category: '',
    details: {
      walletAddress: '',
      name: '',
      price: '',
      quantity: 1,
      description: '',
      assetImage: null,
      subCategory: '',
    },
    documents: [],
  },
  userAssets: {
    items: [],
    status: status.loading,
  },
}

export const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    loading(state) {
      state.userAssets.status = status.loading
    },
    error(state) {
      state.userAssets.status = status.idle
    },
    clearFormData(state) {},
    addFormData(state, { payload }) {
      state.formData = { ...state.formData, ...payload }
    },
    clear() {
      return { ...initialState }
    },
    append(state, { payload }) {
      const items = state.userAssets.items.push(payload.item)
      state.userAssets.items = uniqueItems(items)

    },
    setAsset(state, { payload }) {
      console.log(payload)
      state.userAssets.items = payload.items
      state.userAssets.status = payload.status
    },
  },
})

export const assetActions = assetSlice.actions
export default assetSlice.reducer

export const selectAssetDetails = (state) => state.asset.formData.details
export const selectAssetCategory = (state) => state.asset.formData.category
export const selectAssetDocuments = (state) => state.asset.formData.documents

export const selectAssetFormData = (state) => state.asset.formData

export const selectUserAssets = (state) => {
  return state.asset.userAssets
}
