import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { LoaderSuspense } from 'utils/loader'
import { selectAuth } from 'features/authSlice'
import { status } from 'features/status'
import { paymentStatus, siteModules } from 'dataSource/constants'
import { siteModule } from 'config'

export default function SiteModuleGuard({ children }) {
  const auth = useSelector(selectAuth)

  // return <Outlet />
  if (siteModule === siteModules.Reseller) {
    if (!auth.logged && auth.status === status.loading) {
      return <LoaderSuspense />
    } else if (
      auth.logged &&
      auth.user.agentStatus === paymentStatus.Confirmed
    ) {
      return children
    } else {
      return <Navigate to="/payment/account/new" />
    }
  } else {
    return children
  }
}
