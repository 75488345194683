import React from 'react'
import { config } from '../config'

const UatHighLight = () => {
  return (
    config.env === 'uat' && (
      <div className="h-5 w-full bg-yellow-400 sticky bottom-0 bg-uat bg-left bg-repeat-x"></div>
    )
  )
}

export default UatHighLight
