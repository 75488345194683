import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { usePrice } from 'ECommerce/hooks/usePrice'
import { useOrder } from 'backend/useOrder'
import { orderTypes } from 'dataSource/constants'
import { selectAuth } from 'features/authSlice'
import { selectECommerce } from 'features/eCommerceSlice'
import { useECommerce } from 'ECommerce/hooks/useECommerce'
import Checkout from 'ECommerce/PaymentPages/Checkout'
import { siteModule } from 'config'
import { registerAmount } from 'dataSource/options'

const PaymentOptionAgent = () => {
  const navigate = useNavigate()
  const { user } = useSelector(selectAuth)
  const { paymentMethod, products, product, checkout, coupon } =
    useSelector(selectECommerce)
  const { sub } = useParams()
  const { selectProduct } = useECommerce()
  const { price } = usePrice()

  useEffect(() => {
    if (products?.items.length > 0) {
      const productName =
        sub === 'new' ? 'agent-signup-fees' : 'agent-renew-fees'
      selectProduct(`${siteModule}-${productName}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  const { stripeCreateCheckoutSession, makeAgentOrder, coinbaseCreateCharge } =
    useOrder()

  const initiateOrder = () => {
    makeAgentOrder({
      title: product.name,
      items: [
        {
          refId: user.id,
          name: product.name,
          title: product.name,
          description: product.description,
          amount: product.payableAmount,
        },
      ],
      type: orderTypes.User,
      gateway: paymentMethod,
      amount: checkout.total,
      currency: 'USD',
      subscription: sub === 'new' ? 'new' : 'renew',
      coupon: coupon,
    }).then((order) => {
      if (paymentMethod === 'AdminPayment') {
        console.log(order)
        navigate(`/payment/admin-payment/${order.id}`)
        return
      } else if (paymentMethod === 'Square') {
        console.log(order)
        navigate(`/payment/square/${order.id}`)
        return
        // } else if (paymentMethod === 'Paypal') {
        //   console.log(order)
        //   navigate(`/payment/paypal/${order.id}`)
        //   return
      } else if (paymentMethod === 'CoinBase') {
        coinbaseCreateCharge({
          name: product.name,
          description: product.name,
          items: [
            {
              name: product.name,
              description: product.name,
              quantity: 1,
              amount: checkout.total,
            },
          ],
          orderId: order.id,
          currency: 'USD',
        }).then((charge) => {
          window.location.href = charge.url
        })
      }
      // else if (paymentMethod === "OnRamper") {
      //   console.log(order)
      //   navigate(`/payment/onramper/${order.id}`)
      //   return
      // }
      else if (paymentMethod === 'Stripe') {
        stripeCreateCheckoutSession({
          title: product.name,
          items: [
            {
              name: product.name,
              description: product.name,
              quantity: 1,
              amount: checkout.total,
            },
          ],
          orderId: order.id,
          currency: 'USD',
        }).then((session) => {
          window.location.href = session.url
        })
      } else {
        navigate('/payment/done/' + order.id)
        // navigate('/')
      }
    })
  }

  return (
    <div>
      {/* <pre>{JSON.stringify(coupon, null, 2)}</pre> */}
      <div className="text-xs">
        <span className="text-muted">
          {sub === 'new'
            ? 'Your Unified Account Registration'
            : 'Renew Unified Account Registration'}
        </span>
      </div>
      <div className="text-center mt-5">
        <h2 className="text-xl mb-5">
          {sub === 'new' ? 'Signup Payment' : 'Renew Payment'}
        </h2>
        {sub === 'new' ? (
          <div className="mb-3 pb-4 text-left">
            <p className="font-bold mb-3">
              Embark on a journey of innovation and asset protection by
              establishing your Blockchain Corporation, LLC, or Trust today.
            </p>
            <p className="mb-3">
              Begin by opening an account with us for a nominal fee of{' '}
              <b>{price(product.payableAmount)}</b>, which unlocks the
              flexibility to manage your assets and expand your business
              horizons.
            </p>
            <div
              dangerouslySetInnerHTML={{ __html: product.description }}
            ></div>
            <p className="mb-3">
              Once your account is active, embrace the opportunity to register
              an unlimited number of blockchain corporations for a flat fee of
              just $299.00. Instantly download the registration certificates for
              each new business you establish, streamlining the process and
              ensuring your ventures are recognized and protected promptly.
              Choose the convenience of paying with either Cryptocurrency or
              your credit card.
            </p>
            <p className="mb-3">
              Secure your digital future now and unlock a realm of endless
              possibilities with our tailored blockchain corporate and trust
              solutions,
            </p>
            <p>
              Any questions, feel free to send us an email:{' '}
              <a href="mailto:register@blockchaintrust.pro">
                register@blockchaintrust.pro
              </a>
            </p>
          </div>
        ) : (
          <p className="text-muted mb-3 pb-4">
            After successful renewal payment of{' '}
            <b>{price(product.payableAmount)}</b> you can continue register as
            much as businesses as you want, at just{' '}
            <b>{price(registerAmount)}</b> and download immediately all
            registration certificates of your new Blockchain Trust. You can pay
            with Cryptocurrency or with your credit card. Any questions, feel
            free to send us an email:{' '}
            <a href="mailto:register@blockchaintrust.pro">
              register@blockchaintrust.pro
            </a>
          </p>
        )}
      </div>
      <div>
        <Checkout initiateOrder={initiateOrder} />
      </div>
      <div className="flex justify-between mt-10">
        <button
          type="button"
          className="btn-back"
          tabIndex="-1"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  )
}

export default PaymentOptionAgent
