import { createSlice } from '@reduxjs/toolkit'
import { status } from './status'

const initialState = {
  orders: {
    status: status.loading,
    items: [],
  },
  ordersByBusiness: {
    status: status.loading,
    items: [],
  },
  upcomingBills: {
    status: status.loading,
    items: [],
  },
  pendingOrders: {},
  goodStanding: {},
  currentOrder: {
    item: {},
    status: status.loading,
  },
}

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialState,
  reducers: {
    orders(state, { payload }) {
      state.orders = {
        items: payload.items,
        status: status.fulfilled,
      }
    },
    ordersAdd(state, { payload }) {
      state.orders.items.push(payload.item)
      state.orders.status = status.fulfilled
      // const items = Array.from(state.orders.items)
      // state.orders = {
      //   items: items.push(payload.item),
      //   status: status.fulfilled,
      // }
      // it is needed for next page payment
      state.currentOrder = {
        item: payload.item,
        status: status.fulfilled,
      }

    },
    orderUpdate(state, { payload }) {
      let items = state.orders.items.map((item) => {
        if (payload.orderId === item.id) {
          return { ...item, status: payload.status }
        }
        return item
      })
      state.orders = {
        items: items,
        status: status.fulfilled,
      }
    },
    ordersLoading(state) {
      state.orders.status = status.loading
    },
    ordersError(state) {
      state.orders = {
        items: state.orders.items || [],
        status: status.rejected,
      }
    },
    currentOrder(state, { payload }) {
      state.currentOrder = {
        item: payload.item,
        status: status.fulfilled,
      }
    },
    currentOrderLoading(state) {
      state.currentOrder.status = status.loading
    },
    currentOrderError(state) {
      state.currentOrder = {
        item: state.currentOrder.item || {},
        status: status.rejected,
      }
    },
    ordersByBusiness(state, { payload }) {
      state.ordersByBusiness = {
        items: payload.items,
        status: status.fulfilled,
      }
    },
    ordersByBusinessLoading(state) {
      state.ordersByBusiness.status = status.loading
    },
    ordersByBusinessError(state) {
      state.ordersByBusiness = {
        items: state.ordersByBusiness.items || [],
        status: status.rejected,
      }
    },
    upcomingBills(state, { payload }) {
      state.upcomingBills = {
        items: payload.items,
        status: status.fulfilled,
      }
    },
    upcomingBillsLoading(state) {
      state.upcomingBills.status = status.loading
    },
    upcomingBillsError(state) {
      state.upcomingBills = {
        items: state.upcomingBills.items || [],
        status: status.rejected,
      }
    },
    pendingOrders(state, { payload }) {
      state.pendingOrders = payload
    },
    goodStanding(state, { payload }) {
      state.goodStanding = payload.item
    },
    clear() {
      return { ...initialState }
    }
  },
})

export const orderActions = ordersSlice.actions
export const selectOrders = (state) => state.orders

export default ordersSlice.reducer
