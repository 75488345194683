import React from 'react'
import { Wave } from 'react-animated-text'
import loader from '../assets/loader.svg'

const Loader = () => (
  <div
    id="loader"
    className="loader-container loader-overlay loader-fixed"
    style={{ display: 'none' }}
  >
    <img src={loader} className="loader" alt="Loading..." />
  </div>
)

export default Loader

export const LoaderSuspense = () => (
  <div className="loader-container loader-overlay loader-fixed">
    <img src={loader} className="loader" alt="Loading..." />
  </div>
)

export const LoaderBlock = () => (
  <div className="loader-block">
    <img src={loader} className="loader" alt="Loading..." />
  </div>
)

// export const LoaderDotBlock = () => {
//   const ref = useRef()

//   useEffect(() => {
//     const interval = setInterval(() => {
//       // ref.current.classList.toggle('loader-dot-block--active')  // eslint-disable-LoaderInline
//       var wait = ref.current
//       if (wait.innerHTML.length >= 3)
//         wait.innerHTML = ""
//       else
//         wait.innerHTML += "."
//     }, 500)

//     return () => clearInterval(interval)
//   }, [])

//   // var dots = window.setInterval(function () {
//   //   var wait = ref.current
//   //   if (wait.innerHTML.length > 3)
//   //     wait.innerHTML = ""
//   //   else
//   //     wait.innerHTML += "."
//   // }, 100)

//   return (
//     <div className='loader-block text-left'>
//       <div className="text-center">
//         <img src={loader} className="loader" alt="Loading..." />
//         <p className="text-muted">Loading<span ref={ref} className="pr-2">.</span></p>
//       </div>
//     </div>
//   )
// }

export const LoaderTextBlock = ({ text = 'Loading' }) => {
  return (
    <div className="loader-block">
      <div>
        <img src={loader} className="mx-auto loader" alt="Loading..." />
        <div className="text-muted flex items-center justify-center">
          {text}
          <Wave text="..." effect="fadeOut" delay={1} />
        </div>
      </div>
    </div>
  )
}

export const LoaderTextOnly = () => {
  return (
    <div className="loader-block h-52">
      <div className="text-center">
        {/* <img src={loader} className="loader" alt="Loading..." /> */}
        <div className="text-muted flex items-center justify-center">
          Loading
          <Wave text="..." effect="fadeOut" delay={1} />
        </div>
      </div>
    </div>
  )
}

export const LoaderInline = () => (
  <img src={loader} className="loader" alt="Loading..." />
)

export const showLoader = (loading) => {
  let loader = document.getElementById('loader')
  if (loader) {
    if (loader && loading === true) {
      loader.style.display = 'flex'
    } else {
      loader.style.display = 'none'
    }
  }
}
