import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import io from 'socket.io-client'
import { config } from '../config'
import { useCertificate } from '../backend/useCertificate'
import { useECommerce } from 'ECommerce/hooks/useECommerce'
import { authActions, selectAuth } from 'features/authSlice'
import { status } from 'features/status'
import { businessActions } from 'features/businessSlice'
import { selectCms } from 'features/cmsSlice'
import { orderActions, selectOrders } from 'features/ordersSlice'
import { useShareholder } from 'backend/useShareholder'
import { useBeneficiary } from 'backend/useBeneficiary'

const ClientContext = React.createContext()

export function useClient() {
  return useContext(ClientContext)
}

export function ClientProvider({ children }) {
  const dispatch = useDispatch()
  const { orders } = useSelector(selectOrders)
  const auth = useSelector(selectAuth)
  const { webp } = useSelector(selectCms)
  const [socket, setSocket] = useState(null)
  const { getCertificates } = useCertificate()
  const { myShareholders } = useShareholder()
  const { myBeneficiary } = useBeneficiary()
  const { getProducts } = useECommerce()

  useEffect(() => {
    getProducts()
    getCertificates()
    if (auth.status === status.fulfilled && auth.logged) {
      myShareholders()
      myBeneficiary() 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.status, auth.logged])

  useEffect(() => {
    const newSocket = io(config.apiUrl)
    newSocket.on('connect', (id) => {
      // newSocket.emit('uid', { uid: newSocket.id })
      // newSocket.emit('events', { test: 'test' })
      // newSocket.emit('identity', 0, response =>
      //   console.log('Identity:', response),
      // )
      console.log('connected', newSocket.id)
    })
    newSocket.on('disconnect', () => {
      console.log('Disconnected')
    })
    setSocket(newSocket)
    return () => newSocket.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (socket?.id && orders?.items?.length > 0) {
      socket.on('orderStatus', (data) => {
        console.log('orderStatus', data)
        let order = orders.items.find((order) => order.id === data.orderId)
        console.log('order', order)
        if (order) {
          dispatch(
            orderActions.orderUpdate({
              orderId: data.orderId,
              status: data.status,
            }),
          )
          if (order.type === 'Business') {
            dispatch(
              businessActions.businessStatus({
                ids: order.items.map((item) => item.refId),
                status: data.status,
              }),
            )
          }
          if (order.type === 'GoodStanding') {
            toast.success('Certificate of Good Standing purchased')
          }
        }
      })
    }
    if (socket?.id) {
      socket.on('userUpdate', (data) => {
        console.log('userUpdate', data)
        if (data.id === auth.user.id) {
          dispatch(
            authActions.updateProfile({
              updated: data,
            }),
          )
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, orders])

  useEffect(() => {
    // const imgs = (supports) => {
    //   console.log('webp supports', supports)
    //   dispatch(
    //     cmsActions.setWebp({
    //       supports: supports,
    //     }),
    //   )
    // }
    // function check_webp(callback, feature = 'alpha') {
    //   var kTestImages = {
    //     lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
    //     lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
    //     alpha:
    //       'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
    //     animation:
    //       'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
    //   }
    //   var img = new Image()
    //   img.onload = function () {
    //     var result = img.width > 0 && img.height > 0
    //     callback(result)
    //   }
    //   img.onerror = function () {
    //     callback(false)
    //   }
    //   img.src = 'data:image/webp;base64,' + kTestImages[feature]
    // }
    // check_webp(imgs)
    // axios.get("http://ip-api.com/json")
    // axios.get("https://api.ipify.org?format=json")
    //   .then(res => {
    //     console.log(res.data)
    //   }).catch(error => {
    //     console.log(error)
    //   })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const preferWebp = (url, fallback = 'png') => {
    return webp ? url + '.webp' : url + '.' + fallback
  }

  // console.log("ClientContext", state)

  return (
    <ClientContext.Provider
      value={{
        preferWebp,
        socket,
      }}
    >
      {children}
    </ClientContext.Provider>
  )
}
