import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Form, Input, Select, Submit } from 'tui'
import { useBusiness } from 'backend/useBusiness'
import { prefixOptions, suffixOptions } from 'dataSource/user'
import { countriesOptions } from 'dataSource/countries'

const AddressBillingModal = ({
  isOpen,
  closeModal,
  addressType,
  address,
  ids,
}) => {
  const [open, setOpen] = useState(isOpen)
  const { updateBusinessAddress } = useBusiness()

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const handleClose = () => {
    closeModal()
    setOpen(false)
  }

  const onSubmit = (data) => {
    const payload = {
      ids: ids,
      ...data,
      addressType,
    }
    updateBusinessAddress(payload).then(() => {
      handleClose()
    })
  }

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-4xl sm:w-full">
                <div className="bg-black px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left mb-4">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium"
                      >
                        Update Annual Billing Information
                      </Dialog.Title>
                      {/* <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to deactivate your account? All of your data will be permanently
                          removed. This action cannot be undone.
                        </p>
                      </div> */}
                      <Form onFinish={onSubmit} initialValues={address}>
                        <div className="grid grid-cols-4 gap-5 mt-5">
                          <div className="w-full">
                            <Select
                              required
                              name="prefix"
                              label="Title"
                              // placeholder="Select Country"
                              options={prefixOptions}
                              // rules={{ required: "Country is required" }}
                            />
                          </div>
                          <div className="w-full">
                            <Input.Text
                              name="firstName"
                              required
                              label="First Name"
                              placeholder="First Name"
                              rules={{ required: 'First Name is required' }}
                            />
                          </div>
                          <div className="w-full">
                            <Input.Text
                              name="lastName"
                              required
                              label="Last Name"
                              placeholder="Last Name"
                              rules={{ required: 'Last Name is required' }}
                            />
                          </div>
                          <div className="w-full">
                            <Select
                              required
                              name="suffix"
                              label="Suffix"
                              // placeholder="Select Country"
                              options={suffixOptions}
                              // rules={{ required: "Country is required" }}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-5 mt-5">
                          <div className="w-full">
                            <Input.Text
                              name="addressLine1"
                              required
                              label="Address Line 1"
                              placeholder="Address Line 1"
                              rules={{ required: 'Address Line 1 is required' }}
                            />
                          </div>
                          <div className="w-full">
                            <Input.Text
                              name="addressLine2"
                              // required
                              label="Address Line 2"
                              placeholder="Apartment, Building, Suite, etc."
                              // rules={{ required: "Address Line 2 is required" }}
                            />
                          </div>
                          <div className="w-full">
                            <Input.Text
                              name="city"
                              required
                              label="City"
                              placeholder="City"
                              rules={{ required: 'City is required' }}
                            />
                          </div>
                          <div className="w-full">
                            <div className="grid grid-cols-2 gap-5">
                              <div className="w-full">
                                <Input.Text
                                  name="state"
                                  required
                                  label="State"
                                  placeholder="State"
                                  rules={{ required: 'State is required' }}
                                />
                              </div>
                              <div className="w-full">
                                <Input.Text
                                  name="zipCode"
                                  required
                                  label="Zip Code"
                                  placeholder="Zip Code"
                                  rules={{ required: 'Zip Code is required' }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-1 gap-5 mt-5">
                          <div className="w-full">
                            <Select
                              required
                              name="country"
                              label="Country"
                              placeholder="Select Country"
                              options={countriesOptions}
                              rules={{ required: 'Country is required' }}
                            />
                          </div>
                          <div className="w-full">
                            <Input.Text
                              name="phone"
                              required
                              label="Phone Number"
                              placeholder="Phone Number"
                              // rules={{ required: "Phone Number is required" }}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-5 mt-5">
                          <div className="space-y-5">
                            <div className="w-full text-xl">
                              Email Addresses
                            </div>
                            <div className="w-full">
                              <Input.Email
                                name="email"
                                // required
                                // label="Email"
                                placeholder="Email"
                                // rules={{ required: "Email is required" }}
                              />
                            </div>
                            <div className="w-full">
                              <Input.Email
                                name="email2"
                                // required
                                // label="Email"
                                placeholder="Email"
                                // rules={{ required: "Email is required" }}
                              />
                            </div>
                          </div>
                          <div className="space-y-5">
                            <div className="w-full text-xl">
                              Registered Agent Notice Frequency
                            </div>
                            <Select
                              name="noticeFrequency"
                              // label="Notice Frequency"
                              // placeholder="Select Agent Notice Frequency"
                              options={[
                                { value: 'All Notice', label: 'All Notice' },
                                {
                                  value: 'Fewer Notice',
                                  label: 'Fewer Notice',
                                },
                              ]}
                              // defaultValue={user.noticeFrequency}
                            />
                          </div>
                        </div>
                        <div className="flex justify-end mt-5">
                          <button
                            type="button"
                            className="mr-2 btn btn-secondary"
                            onClick={handleClose}
                            ref={cancelButtonRef}
                          >
                            Close
                          </button>
                          <Submit className="btn btn-primary">
                            Submit
                          </Submit>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AddressBillingModal
