import cx from 'classnames'
import React from 'react'

const getBgColor = (status) => {
  switch (status) {
    case 'Success':
    case 'Confirmed':
      return 'bg-success'
    case 'Processing':
      return 'bg-info'
    case 'Pending':
      return 'bg-warning'
    case 'Failed':
    case 'Expired':
      return 'bg-danger'
    default:
      return 'bg-info'
  }
}
const Badge = ({ status, className = '', children }) => {
  return (
    <span
      className={cx(
        'text-xs inline-block py-2 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold rounded',
        className,
        getBgColor(status)
      )}
    >
      {children}
    </span>
  )
}

export default Badge
