const prefixOptions = [
  { value: '', label: '' },
  { value: 'Capt.', label: 'Capt.' },
  { value: 'Dr.', label: 'Dr.' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Mr.', label: 'Mr.' },
  { value: 'Mrs.', label: 'Mrs.' },
  { value: 'Mr. and Mrs.', label: 'Mr. and Mrs.' },
  { value: 'Ms.', label: 'Ms.' },
  { value: 'Prof.', label: 'Prof.' },
  { value: 'Rev.', label: 'Rev.' },
  // { value: "Hon.", label: "Hon." },
]

const suffixOptions = [
  { value: '', label: '' },
  { value: 'Jr.', label: 'Jr.' },
  { value: 'Sr.', label: 'Sr.' },
  { value: 'I', label: 'I' },
  { value: 'II', label: 'II' },
  { value: 'III', label: 'III' },
  { value: 'IV', label: 'IV' },
  { value: 'CLU', label: 'CLU' },
  { value: 'CO', label: 'CO' },
  { value: 'CPA', label: 'CPA' },
  { value: 'DDS', label: 'DDS' },
  { value: 'ESQ', label: 'ESQ' },
  { value: 'MD', label: 'MD' },
  { value: 'RN', label: 'RN' },
  { value: 'PHD', label: 'PHD' },
]

export { prefixOptions, suffixOptions }
