import React from 'react'
import {
  FaBuilding,
  FaAddressCard,
  FaDollarSign,
  FaSearch,
  FaUserFriends,
} from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Services = () => {
  return (
    <div>
      <h1 className="text-3xl mb-4">Services</h1>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-10">
        <Link to="/name-search">
          <div className="flex space-x-4 bg-black p-4 rounded-md items-center h-full">
            <div className="p-4">
              <FaSearch className="text-5xl text-gold-400" />
            </div>
            <div className="w-full text-center">
              <p className="text-xl font-semibold text-gold-400">
                Company Name Search
              </p>
              <p className="text-muted">
                Check if the Blockchain Trust name you want is available
              </p>
            </div>
          </div>
        </Link>
        <Link to="/register/trust">
          <div className="flex space-x-4 bg-black p-4 rounded-md items-center h-full">
            <div className="p-4">
              <FaBuilding className="text-5xl text-gold-400" />
            </div>
            <div className="w-full text-center">
              <p className="text-xl font-semibold text-gold-400">
                Register a Trust
              </p>
              <p className="text-muted">Form a new Blockchain trust</p>
            </div>
          </div>
        </Link>
        <Link to="/register/corporate">
          <div className="flex space-x-4 bg-black p-4 rounded-md items-center h-full">
            <div className="p-4">
              <FaBuilding className="text-5xl text-gold-400" />
            </div>
            <div className="w-full text-center">
              <p className="text-xl font-semibold text-gold-400">
                Register a Corporate
              </p>
              <p className="text-muted">Form a new Blockchain corporate</p>
            </div>
          </div>
        </Link>
        <Link to="/register/family">
          <div className="flex space-x-4 bg-black p-4 rounded-md items-center h-full">
            <div className="p-4">
              <FaBuilding className="text-5xl text-gold-400" />
            </div>
            <div className="w-full text-center">
              <p className="text-xl font-semibold text-gold-400">
                Register a Family
              </p>
              <p className="text-muted">Form a new Blockchain family trust</p>
            </div>
          </div>
        </Link>
        <Link to="/register/bank">
          <div className="flex space-x-4 bg-black p-4 rounded-md items-center h-full">
            <div className="p-4">
              <FaBuilding className="text-5xl text-gold-400" />
            </div>
            <div className="w-full text-center">
              <p className="text-xl font-semibold text-gold-400">
                Register a Bank
              </p>
              <p className="text-muted">Form a new Blockchain bank</p>
            </div>
          </div>
        </Link>
        <Link to="/upcoming">
          <div className="flex space-x-4 bg-black p-4 rounded-md items-center h-full">
            <div className="p-4">
              <FaDollarSign className="text-5xl text-gold-400" />
            </div>
            <div className="w-full text-center">
              <p className="text-xl font-semibold text-gold-400">
                Bulk Payment
              </p>
              <p className="text-muted">Bulk pay Annual Renewal Fees</p>
            </div>
          </div>
        </Link>
        <Link to="/address">
          <div className="flex space-x-4 bg-black p-4 rounded-md items-center h-full">
            <div className="p-4">
              <FaAddressCard className="text-5xl text-gold-400" />
            </div>
            <div className="w-full text-center">
              <p className="text-xl font-semibold text-gold-400">
                Address Book
              </p>
              <p className="text-muted">
                Bulk update Blockchain trust Business Address
              </p>
            </div>
          </div>
        </Link>
        <Link to="/shareholders">
          <div className="flex space-x-4 bg-black p-4 rounded-md items-center h-full">
            <div className="p-4">
              <FaUserFriends className="text-5xl text-gold-400" />
            </div>
            <div className="w-full text-center">
              <p className="text-xl font-semibold text-gold-400">
                Shareholders
              </p>
              <p className="text-muted">
                Shareholder can re use in Registration Steps
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default Services
