export const registerAmount = 1999
export const trustRegisterAmount = 1999
export const corpRegisterAmount = 1999
export const bankRegisterAmount = 25000
export const familyRegisterAmount = 2999
export const renewAmount = 49
export const agentSignupAmount = 2999
export const goodStandingAmount = 49
export const btEntityEndings = [
  { value: 'Blockchain Trust', label: 'Blockchain Trust' },
  { value: 'BT', label: 'BT' },
]
export const insuranceEntityEndings = [
  {
    value: 'Captive Insurance Blockchain Trust',
    label: 'Captive Insurance Blockchain Trust',
  },
  {
    value: 'Captive Insurance Corporation',
    label: 'Captive Insurance Corporation',
  },
  { value: 'Captive Insurance Limited', label: 'Captive Insurance Limited' },
  { value: 'Captive Insurance LLP', label: 'Captive Insurance LLP' },
  { value: 'Captive Insurance LLC', label: 'Captive Insurance LLC' },
  {
    value: 'Captive Insurance Cooperative',
    label: 'Captive Insurance Cooperative',
  },
  {
    value: 'Captive Insurance Consortium',
    label: 'Captive Insurance Consortium',
  },
  { value: 'Captive Insurance Group', label: 'Captive Insurance Group' },
]
export const corpEntityEndings = [
  // { value: "ALL", label: "N/A or ALL" },
  { value: 'Aktiengesellschaft', label: 'Aktiengesellschaft' },
  { value: 'AG', label: 'AG' },
  { value: 'Ltd', label: 'Ltd' },
  { value: 'IBC', label: 'IBC' },
  { value: 'Corp', label: 'Corp' },
  { value: 'Inc.', label: 'Inc.' },
  { value: 'Co.', label: 'Co.' },
  { value: 'BV', label: 'BV' },
  { value: 'NV', label: 'NV' },
  { value: 'Gmbh', label: 'Gmbh' },
  { value: 'PC', label: 'PC' },
  { value: 'LLC', label: 'LLC' },
  { value: 'PLC', label: 'PLC' },
  { value: 'PLLC', label: 'PLLC' },
  { value: 'LLP', label: 'LLP' },
  { value: 'REIT', label: 'REIT' },
  { value: 'VT', label: 'VT' },
  { value: 'Sociedad Anónima', label: 'Sociedad Anónima' },
  { value: 'SA', label: 'SA' },
  {
    value: 'Sociedad de Responsabilidad Limitada',
    label: 'Sociedad de Responsabilidad Limitada',
  },
  { value: 'SRL', label: 'SRL' },
  { value: 'Conglomerate', label: 'Conglomerate' },
  { value: 'Cooperative', label: 'Cooperative' },
  {
    value: 'Decentralized Autonomous Organization',
    label: 'Decentralized Autonomous Organization',
  },
  { value: 'DAO', label: 'DAO' },
  { value: 'DAC', label: 'DAC' },
  { value: 'Association', label: 'Association' },
  { value: 'Club', label: 'Club' },
  { value: 'Foundation', label: 'Foundation' },
  { value: 'Fund', label: 'Fund' },
  { value: 'Institute', label: 'Institute' },
  { value: 'Society', label: 'Society' },
  { value: 'Union', label: 'Union' },
  { value: 'Syndicate', label: 'Syndicate' },
  { value: 'Eingetragener Verein', label: 'Eingetragener Verein' },
  { value: 'EV', label: 'EV' },
]
export const familyEntityEndings = [
  { value: 'BLOCKCHAIN FAMILY TRUST', label: 'BLOCKCHAIN FAMILY TRUST' },
]
export const bankEntityEndings = [
  { value: 'BLOCKCHAIN BANK', label: 'BLOCKCHAIN BANK' },
]
export const shareholderTypes = [
  { label: 'Corporate Shareholder', value: 'Corporate' },
  { label: 'Individual Shareholder', value: 'Individual' },
]
export const maritalStatus = [
  { label: 'Married', value: 'Married' },
  { label: 'Single', value: 'Single' },
  { label: 'Divorced', value: 'Divorced' },
  { label: 'Widowed', value: 'Widowed' },
]

export const BeneficiaryTypes = [
  { label: 'Individual Beneficiary', value: 'Individual' },
  { label: 'Corporate Beneficiary', value: 'Corporate' },
]
